import axios from "axios";
import { setAlert } from "./alert";
import {
  REGISTER_SUCCESS,
  REGISTER_FAILED,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT,
  CLEAR_PROFILE,
  PW_CHANGE_SUCCESS,
  PW_CHANGE_FAILED,
  VERIFY_USR,
} from "./types";
import setAuthToken from "../utils/setAuthToken";

axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }

  try {
    const res = await axios.get("/api/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const registerUser =
  ({ name, email, password }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ name, email, password });

    try {
      const res = await axios.post("/api/users", body, config);
      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
    } catch (e) {
      const errors = e.response.data.errors;
      if (errors) {
        errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
      }
      dispatch({
        type: REGISTER_FAILED,
      });
    }
    window.scrollTo(0, 0);
  };

// Login User
export const loginUser = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post("/api/auth", body, config);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (e) {
    const errors = e?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: LOGIN_FAILED,
    });
  }
  window.scrollTo(0, 0);
};



// Login User
export const updatePassword = ( currentPassword, newPassword) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  const body = JSON.stringify({ currentPassword, newPassword });

  try {
    const res = await axios.put("/api/auth/password", body, config);
    dispatch({
      type: PW_CHANGE_SUCCESS,
      payload: res.data,
    });
    dispatch(setAlert("Password updated!", "success"));
    dispatch(loadUser());
  } catch (e) {
    const errors = e?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: PW_CHANGE_FAILED,
    });
  }
  window.scrollTo(0, 0);
};




//VERIFY USER
export const verifyUser = ( _id, verified, rejectionComment) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      'x-auth-token': localStorage.getItem('token'),
    },
  };

  const body = JSON.stringify({ verified, rejectionComment });

  try {
    console.log("sending what?",verified);
    const res = await axios.put(`/api/users/update/${_id}`, body, config);
    dispatch({
      type: VERIFY_USR,
      payload: res.data,
    });
    
    dispatch(setAlert( verified === "accepted" ? "User Accepted!" : "User Rejected!", "success"));
    
  } catch (e) {
    const errors = e?.response?.data?.errors;
    if (errors) {
      errors.forEach((error) => dispatch(setAlert(error.msg, "danger")));
    }
    dispatch({
      type: VERIFY_USR,
    });
  }
  window.scrollTo(0, 0);
};




//Logout User / Clear Profile
export const logoutUser = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};
