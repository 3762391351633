import axios from "axios";
import { setAlert } from "./alert";
import {
  GENERATE_GPT,
  GENERATE_ERROR,
} from "./types";


axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;

// Generate AI
// export const generateAi = (input) => async (dispatch) => {
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };
//     try {
//       await axios.put(`/api/generate`, JSON.stringify({ animal: input }), config);
  
//       dispatch({
//         type: GENERATE_GPT,

//       });
  
//       dispatch(setAlert("Post successfully edited", "success"));
//     } catch (e) {
//       dispatch({
//         type: GENERATE_ERROR,
//         payload: { msg: e.response.statusText, status: e.response.status },
//       });
//     }
//   };



  // Generate AI
export const generateAi = (animalInput) => async (dispatch) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/generate`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ animal: animalInput }),
        });
  
        const data = await response.json();
        if (response.status !== 200) {
          throw data.error || new Error(`Request failed with status ${response.status}`);
        }
  
        alert(data.result);
              dispatch({
        type: GENERATE_GPT,
        payload: data.result ,
      });
        // setAnimalInput("");
      } catch(error) {
        // Consider implementing your own error handling logic here
        console.error(error);
        alert(error.message);
        dispatch({
            type: GENERATE_ERROR,
            payload: error.message ,
          });
      }
  };