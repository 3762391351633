import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom'
import {
  Container,
  Card,
  Row,
  Col,
  Image,
  Form,
  Button,
} from "react-bootstrap";
import Popup from "./Popup";
import { Link } from "react-router-dom";
import { addPost, getPost } from "../../../actions/post";
import { editPost } from "../../../actions/post";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// img
import image1 from "../../../assets/images/page-img/img-success.png";
import teethNumbers from "../../../assets/images/deethNumbers.png";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Icon } from "@iconify/react";


import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


const FormWizard = ({ getPost, addPost, editPost, handleCloseCaseModal, postInfo, editPostMode, _id, handleCloseEditPost }) => {

  const groupId = localStorage.getItem('currentGroupId');
  console.log("groupdId",groupId);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45em",
    bgcolor: "background.paper",
    textAlign: "center",
    boxShadow: 24,
    p: 4,
  };





  //state for form data
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
   if(editPostMode){
    setFormData(postInfo)
   }

  }, [postInfo, editPostMode]);

  const [formData, setFormData] = useState(editPostMode ? {
    patientReference: postInfo?.patientReference,
    dateOfBirth: postInfo?.dateOfBirth,
    reasonConsultation: postInfo?.reasonConsultation,
    gender: postInfo?.gender,
    medicalHistory: postInfo?.medicalHistory,
    dailyMedications: postInfo?.dailyMedications,
    bloodPressure: postInfo?.bloodPressure,
    pulse: postInfo?.pulse,
    respiration: postInfo?.respiration,
    dentalHistory: postInfo?.dentalHistory,
    extraoralExamination: postInfo?.extraoralExamination,
    intraoralExamination: postInfo?.intraoralExamination,
    examenExoBuccal: postInfo?.examenExoBuccal,
    dermato: postInfo?.dermato,
    symetrie: postInfo?.symetrie,
    symetrieExplanation: postInfo?.symetrieExplanation,
    examenAtmNormal: postInfo?.examenAtmNormal,
    examenAtmDouleur: postInfo?.examenAtmDouleur,
    examenAtmClaquement: postInfo?.examenAtmClaquement,
    examenAtmAutre: postInfo?.examenAtmAutre,
    examenAtmAutreExplanation: postInfo?.examenAtmAutreExplanation,
    concernedTeeth: postInfo?.concernedTeeth,
    respirationType: postInfo?.respirationType,
    detailsRespiration: postInfo?.detailsRespiration,
    mastication: postInfo?.mastication,
    detailsMastication: postInfo?.detailsMastication,
    deglutition: postInfo?.deglutition,
    detailsDeglutition: postInfo?.detailsDeglutition,
    title: postInfo?.title,
    description: postInfo?.description,
    visibility: postInfo?.visibility,
    participants: postInfo?.participants,
    casediagnostics: postInfo?.casediagnostics,
    treatmentplan: postInfo?.treatmentplan,
    radiopanoramicbefore: postInfo?.postImages?.radiopanoramicbefore,
    radiopanoramicafter: postInfo?.postImages?.radiopanoramicafter,
    conebeambefore: postInfo?.postImages?.conebeambefore,
    conebeamafter: postInfo?.postImages?.conebeamafter,
    endobuccalebefore: postInfo?.postImages?.endobuccalebefore,
    endobuccaleafter: postInfo?.postImages?.endobuccaleafter,
    vuefacebefore: postInfo?.postImages?.vuefacebefore,
    vuefaceafter: postInfo?.postImages?.vuefaceafter,
    vueprofilbefore: postInfo?.postImages?.vueprofilbefore,
    vueprofilafter: postInfo?.postImages?.vueprofilafter,
    teleradioprofilbefore: postInfo?.postImages?.teleradioprofilbefore,
    teleradioprofilafter: postInfo?.postImages?.teleradioprofilafter,
    groupId: postInfo?.groupId,
  } : 
  
  {
    patientReference: "",
    dateOfBirth: "",
    reasonConsultation: "",
    gender: "",
    medicalHistory: "",
    dailyMedications: "",
    bloodPressure: "",
    pulse: "",
    respiration: "",
    dentalHistory: "",
    extraoralExamination: "",
    intraoralExamination: "",
    examenExoBuccal: "",
    dermato: "",
    symetrie: "",
    symetrieExplanation: "",
    examenAtmNormal: "",
    examenAtmDouleur: "",
    examenAtmClaquement: "",
    examenAtmAutre: "",
    examenAtmAutreExplanation: "",
    concernedTeeth: "",
    respirationType: "",
    detailsRespiration: "",
    mastication: "",
    detailsMastication: "",
    deglutition: "",
    detailsDeglutition: "",
    title: "",
    description: "",
    visibility: "",
    participants: "",
    casediagnostics: "",
    treatmentplan: "",
    radiopanoramicbefore: null,
    radiopanoramicafter: null,
    conebeambefore: null,
    conebeamafter: null,
    endobuccalebefore: null,
    endobuccaleafter: null,
    vuefacebefore: null,
    vuefaceafter: null,
    vueprofilbefore: null,
    vueprofilafter: null,
    teleradioprofilbefore: null,
    teleradioprofilafter: null,
    groupId: groupId,
  }
  );
  const handleInputData = (input, index) => (e) => {
    // input value from the form
    const { name, value } = e.target;
    
  
    if (name === 'treatmentplan') {
      
      const list = [...sessions];
      list[index][name] = value;
      setSessions(list);
      console.log(sessions);
      setFormData(prevState => ({
        ...prevState,
        sessions: sessions
      }));
    }
    else{
     
      setFormData((prevState) => ({
        ...prevState,
        [input]: value,
      }));
    }
    //updating for data state taking previous state and then adding new value to create new object
  console.log("formData??", formData);
  
  };

  const [symetrie, setSymetrie] = useState(editPostMode ? { getSymetrie: postInfo?.symetrie }  : { getSymetrie: "" });

  const [isAtmNormalChecked, setIsAtmNormalChecked] = useState(editPostMode ? postInfo?.examenAtmNormal : false);
  const handleOnIsAtmNormalChange = () => {
    setIsAtmNormalChecked(!isAtmNormalChecked);
    setFormData((prevState) => ({
      ...prevState,
      ["examenAtmNormal"]: !isAtmNormalChecked,
    }));
    console.log("isAtmNormalChecked?", !isAtmNormalChecked);
  };

  const [isAtmDouleurChecked, setIsAtmDouleurChecked] = useState(editPostMode ? postInfo?.examenAtmDouleur : false);
  const handleOnIsAtmDouleurChange = () => {
    setIsAtmDouleurChecked(!isAtmDouleurChecked);
    setFormData((prevState) => ({
      ...prevState,
      ["examenAtmDouleur"]: !isAtmDouleurChecked,
    }));
    console.log("isAtmDouleurChecked?", !isAtmDouleurChecked);
  };

  const [isAtmClaquementChecked, setIsAtmClaquementChecked] = useState(editPostMode ? postInfo?.examenAtmClaquement : false);
  const handleOnIsAtmClaquementChange = () => {
    setIsAtmClaquementChecked(!isAtmClaquementChecked);
    setFormData((prevState) => ({
      ...prevState,
      ["examenAtmClaquement"]: !isAtmClaquementChecked,
    }));
    console.log("isAtmClaquementChecked?", !isAtmClaquementChecked);
  };

  const [isAtmAutreChecked, setIsAtmAutreChecked] = useState(editPostMode ? postInfo?.examenAtmAutre : false);
  const handleOnIsAtmAutreChange = () => {
    setIsAtmAutreChecked(!isAtmAutreChecked);
    setFormData((prevState) => ({
      ...prevState,
      ["examenAtmAutre"]: !isAtmAutreChecked,
    }));
    console.log("isAtmAutreChecked?", !isAtmAutreChecked);
  };
  // after form submit validating the form data using validator
  const { getSymetrie } = symetrie;
  const handleChangeSymetrie = (e) => {
    e.persist();
    console.log(e?.target?.value);

    setFormData((prevState) => ({
      ...prevState,
      ["symetrie"]: e?.target?.value,
    }));

    setSymetrie((prevState) => ({
      ...prevState,
      getSymetrie: e?.target?.value,
    }));
  };

  const [respirationType, setRespirationType] = useState(editPostMode ? {
    getRespirationType: postInfo?.respirationType,
  }  : {
    getRespirationType: "",
  });
  const { getRespirationType } = respirationType;
  const handleChangeRespirationType = (e) => {
    e.persist();
    console.log(e?.target?.value);

    setFormData((prevState) => ({
      ...prevState,
      ["respirationType"]: e?.target?.value,
    }));

    setRespirationType((prevState) => ({
      ...prevState,
      getRespirationType: e?.target?.value,
    }));

  };

  const [mastication, setMastication] = useState(editPostMode ? { getMastication: postInfo?.mastication }   : { getMastication: "" });
  const { getMastication } = mastication;
  const handleChangeMastication = (e) => {
    e.persist();
    console.log(e?.target?.value);

    setFormData((prevState) => ({
      ...prevState,
      ["mastication"]: e?.target?.value,
    }));

    setMastication((prevState) => ({
      ...prevState,
      getMastication: e?.target?.value,
    }));

  };

  const [deglutition, setDeglutition] = useState(editPostMode ? { getDeglutition: postInfo?.deglutition }   : { getDeglutition: "" });
  const { getDeglutition } = deglutition;
  const handleChangeDeglutition = (e) => {
    e.persist();
    console.log(e?.target?.value);

    setFormData((prevState) => ({
      ...prevState,
      ["deglutition"]: e?.target?.value,
    }));

    setDeglutition((prevState) => ({
      ...prevState,
      getDeglutition: e?.target?.value,
    }));
    console.log("deglutition?", getDeglutition);
  };

  const [error, setError] = useState(false);
  const [gender, setGender] = useState(editPostMode ? { getGender: postInfo?.gender }   : { getGender: "" });

  // after form submit validating the form data using validator
  const { getGender } = gender;
  const handleChange = (e) => {
    e.persist();
    console.log(e?.target?.value);

    setFormData((prevState) => ({
      ...prevState,
      ["gender"]: e?.target?.value,
    }));

    setGender((prevState) => ({
      ...prevState,
      getGender: e?.target?.value,
    }));
  };


  const [progressBar, setProgressBar] = useState(33);


  const [visibility, setvisibility] = useState(editPostMode ? { getVisibility: postInfo?.visibility } : { getVisibility: "" });

  // after form submit validating the form data using validator
  const { getVisibility } = visibility;
  const handleVisibilityChange = (e) => {
    e.persist();
    console.log(e?.target?.value);

    setFormData((prevState) => ({
      ...prevState,
      ["visibility"]: e?.target?.value,
    }));

    setvisibility((prevState) => ({
      ...prevState,
      getVisibility: e?.target?.value,
    }));
  };



  //crop image start
  const [cropOpen, setCropOpen] = React.useState(false);
  const [radiopanoramicbeforeimage, setRadiopanoramicbeforeimage] = React.useState(
    (editPostMode && postInfo?.postImages?.radiopanoramicbefore) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.radiopanoramicbefore }` : null
  );


  const [radiopanoramicafterimage, setRadiopanormaicafterimage] = React.useState(
    (editPostMode && postInfo?.postImages?.radiopanoramicafter) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.radiopanoramicafter }` : null
  );

  const [conebeamafterimage, setConebeamafterimage] = React.useState(
    (editPostMode && postInfo?.postImages?.conebeamafter) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.conebeamafter }` : null
  );

  const [conebeambeforeimage, setConebeambeforeimage] = React.useState(
    (editPostMode && postInfo?.postImages?.conebeambefore)  ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.conebeambefore }` : null
  );

  const [endobuccaleafterimage, setEndobuccaleafterimage] = React.useState(
    (editPostMode && postInfo?.postImages?.endobuccaleafter) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.endobuccaleafter }` : null
  );

  const [endobuccalebeforeimage, setEndobuccalebeforeimage] = React.useState(
    (editPostMode && postInfo?.postImages?.endobuccalebefore) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.endobuccalebefore }` : null
  );

  const [teleradioprofilafterimage, setTeleradioprofilafterimage] = React.useState(
    (editPostMode && postInfo?.postImages?.teleradioprofilafter) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.teleradioprofilafter }` : null
  );
  const [teleradioprofilbeforeimage, setTeleradioprofilbeforeimage] = React.useState(
    (editPostMode && postInfo?.postImages?.teleradioprofilbefore) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.teleradioprofilbefore }` : null
  );

  const [vuefaceafterimage, setVuefaceafterimage] = React.useState(
    (editPostMode && postInfo?.postImages?.vuefaceafter) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.vuefaceafter }` : null
  );

  const [vuefacebeforeimage, setVuefacebeforeimage] = React.useState(
    (editPostMode && postInfo?.postImages?.vuefacebefore) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.vuefacebefore }` : null
  );

  const [vueprofilafterimage, setVueprofilafterimage] = React.useState(
    (editPostMode && postInfo?.postImages?.vueprofilafter) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.vueprofilafter }` : null
  );

  const [vueprofilbeforeimage, setVueprofilbeforeimage] = React.useState(
    (editPostMode && postInfo?.postImages?.vueprofilbefore) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      postInfo?.postImages?.vueprofilbefore }` : null
  );


  const [fieldName, setFieldName] = useState(null);
  const [imageToCrop, setImageToCrop] = useState(null);
// console.log("fieldName", fieldName)

  const handleCropClose = () => {
    setCropOpen(false);
  };

  const getUploadedFile = (image, newfieldName) => {
    setCropOpen(true);
    setImageToCrop(image);
    cropPicturesHandler(image, newfieldName);
  };


  // const cropImageReturnHandler = () => {
  
  //   console.log("what the actual", "conebeamafter"===fieldName);
  //   let returnedImage;
  //   switch(fieldName) {
  //     case 'conebeamafter':
  //         console.log("it is true!!!");
  //         returnedImage = conebeamafterimage;
  //         break;
  //     case 'conebeambefore':
  //       returnedImage = conebeambeforeimage;
  //         break;


  //         case 'endobuccaleafter':
          
  //         returnedImage = endobuccaleafterimage;
  //         break;
  //     case 'endobuccalebefore':
  //       returnedImage = endobuccalebeforeimage;
  //         break;
  //         case 'radiopanoramicafter':
          
  //         returnedImage = radiopanoramicafterimage;
  //         break;
  //     case 'radiopanoramicbefore':
  //       returnedImage = radiopanoramicbeforeimage;
  //         break;
  //         case 'teleradioprofilafter':
          
  //         returnedImage = teleradioprofilafterimage;
  //         break;
  //     case 'teleradioprofilbefore':
  //       returnedImage = teleradioprofilbeforeimage;
  //         break;
  //         case 'vuefaceafter':
          
  //         returnedImage = vuefaceafterimage;
  //         break;
  //     case 'vuefacebefore':
  //       returnedImage = vuefacebeforeimage;
  //         break;
  //         case 'vueprofilafter':
          
  //         returnedImage = vueprofilafterimage;
  //         break;
  //     case 'vueprofilbefore':
  //       returnedImage = vueprofilbeforeimage;
  //         break;
  
  //     default:
  //         console.log('Error, please contact administrator with code error 1');
  //         break;
  // }
  // console.log("returnedImage", returnedImage)
  // return returnedImage;
  // };

  const cropPicturesHandler = (image, newfieldName) => {
   
    
    switch(newfieldName || fieldName) {
      case 'conebeamafter':
          
          setConebeamafterimage(image);
          break;
      case 'conebeambefore':
        setConebeambeforeimage(image);
          break;


          case 'endobuccaleafter':
          
          setEndobuccaleafterimage(image);
          break;
      case 'endobuccalebefore':
        setEndobuccalebeforeimage(image);
          break;
          case 'radiopanoramicafter':
          
          setRadiopanormaicafterimage(image);
          break;
      case 'radiopanoramicbefore':
        setRadiopanoramicbeforeimage(image);
          break;
          case 'teleradioprofilafter':
          
          setTeleradioprofilafterimage(image);
          break;
      case 'teleradioprofilbefore':
        setTeleradioprofilbeforeimage(image);
          break;
          case 'vuefaceafter':
          
          setVuefaceafterimage(image);
          break;
      case 'vuefacebefore':
        setVuefacebeforeimage(image);
          break;
          case 'vueprofilafter':
          
          setVueprofilafterimage(image);
          break;
      case 'vueprofilbefore':
        setVueprofilbeforeimage(image);
          break;
  
      default:
          console.log('Error, please contact administrator with code error 1');
          break;
  }
  }


//crop image start


 




  function base64ImageToBlob(receivedImage) {
    // extract content type and base64 payload from original string
  
    var pos = receivedImage.indexOf(';base64,');
    var type = receivedImage.substring(5, pos);
    var b64 = receivedImage.substr(pos + 8);
  
    // decode base64
    var imageContent = atob(b64);
  
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);
  
    // fill the view, using the decoded base64
    for(var n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
  
    // convert ArrayBuffer to Blob
    var blob = new Blob([buffer], { type: type });
  
   setFormData((prevData) => ({
    ...prevData,
    [fieldName]: blob,
  }));
  }





  const handleFileChange = (e) => {
    const fieldName = e.target.name;
    setFieldName(fieldName);
    // const file = e.target.files[0];

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();
    reader.onload = () => {
      getUploadedFile(reader.result, fieldName);
    
    };
    reader.readAsDataURL(files[0]);
  
  };



  const submitFormData = (e) => {


    const imageFields = [
      "radiopanoramicbefore",
      "radiopanoramicafter",
      "conebeambefore",
      "conebeamafter",
      "endobuccalebefore",
      "endobuccaleafter",
      "vuefacebefore",
      "vuefaceafter",
      "vueprofilbefore",
      "vueprofilafter",
      "teleradioprofilbefore",
      "teleradioprofilafter",
    ];


    const data = new FormData();

    Object.keys(formData).forEach((key) => {
      if (key === 'sessions') {
        formData[key].forEach((session, index) => {
          data.append(`session${index + 1}`, session.plan);
        });
      } 
      else{
        data.append(key, formData[key]);
      }
      
    });


    editPostMode ? editPost(_id, data) : addPost(data);
    if (editPost){
      getPost(_id);
    }
   
  };


  const [show, setShow] = useState("A");

  const [isFormValidated, setIsFormValidated] = useState(false);

  const handleNextClick = () => {
   
    // if (isFormValidated ) {
      // Move to the next step
      setIsFormValidated(true);
      if (show === "A" && getVisibility && formData.title && formData.description && formData.patientReference && formData.dateOfBirth && getGender && formData.medicalHistory && formData.dentalHistory && formData.dailyMedications &&  ((formData.bloodPressure.length < 1) || (formData.bloodPressure >= 30 && formData.bloodPressure <= 250)) &&  ((formData.pulse.length < 1) || (formData.pulse >= 30 && formData.pulse <= 250)) &&  ((formData.respiration.length < 1) || (formData.respiration >= 5 && formData.respiration <= 80)) && formData.concernedTeeth) {
        setIsFormValidated(false);
        setShow("Account");
        setProgressBar(66);
      } else if (show === "Account" && formData.extraoralExamination && formData.examenExoBuccal && formData.dermato && formData.intraoralExamination && ((getSymetrie==="nonSymetrie" && formData.symetrieExplanation) || getSymetrie === "ouiSymetrie" ) && getRespirationType && getMastication && getDeglutition && formData.casediagnostics && (formData.sessions || formData.treatmentplan) && (   (isAtmAutreChecked || (isAtmNormalChecked || isAtmClaquementChecked || isAtmDouleurChecked)    ) && ((isAtmAutreChecked && formData.examenAtmAutreExplanation.length > 1) || !isAtmAutreChecked)    )     ) {
        setIsFormValidated(false);
        setShow("Personal");
        setProgressBar(100);
      }
      // Add logic for other steps if needed
    // } 
    
    // else {
    //   // Trigger form validation
    //   setIsFormValidated(true);
    // }
  };

  const handlePreviousClick = () => {
    // Move to the previous step
    if (show === "Account") {
      setShow("A");
      setProgressBar(33);
    } else if (show === "Personal") {
      setShow("Account");
      setProgressBar(66);
    }
    // Add logic for other steps if needed
  };






//handle treatment plan sessions start

const [sessions, setSessions] = useState([{ name: "Session 1", treatmentplan: "" }]);

useEffect(() => {
  if (formData?.treatmentplan || formData?.sessions?.length > 0) {
    
      setSessions(formData?.sessions?.length > 0 ? formData?.sessions : [{ name: "Session 1", treatmentplan: formData?.treatmentplan }]);
    
   
  }
}, [formData?.sessions, formData?.treatmentplan]);

const addSession = () => {
  setSessions([
    ...sessions,
    { name: `Session ${sessions.length + 1}`, treatmentplan: "" },
  ]);
};

const deleteSession = (index) => {
  const list = [...sessions];
  list.splice(index, 1);
  setSessions(list);
};

// const handleTreatmentPlanChange = (e, index) => {
//   const { name, value } = e.target;
//   const list = [...sessions];
//   list[index][name] = value;
//   setSessions(list);
// };

//handle treament plan sessions end
console.log("sessions?", sessions);
  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col sm="12">
              <Card
                className="position-relative inner-page-bg bg-primary"
                style={{ height: "150px" }}
              >
                <div className="inner-page-title">
                  <h3 className="text-white">New Case</h3>
                  <p className="text-white">
                    Put in your patient's information below
                  </p>
                </div>
              </Card>
            </Col>
            <Col sm="12" lg="12">
              <Card>
                <Card.Header className="d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title" style={{color:"#3479dc"}}>Patient case</h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Form id="form-wizard1"  className={`text-center mt-3 ${isFormValidated ? "was-validated" : ""}`}
      noValidate >
                    {/* <ul id="top-tab-list" className="p-0 row list-inline">
                      <li
                        className={` ${show === "Image" ? "active done" : ""} ${
                          show === "Personal" ? "active done" : ""
                        } ${show === "Account" ? "active done" : ""} ${
                          show === "A" ? "active " : ""
                        } col-lg-3 col-md-6 text-start mb-2 active`}
                        id="account"
                      >
                        <Link to="#">
                          <i className="material-symbols-outlined">lock_open</i>
                          <span>Personal</span>
                        </Link>
                      </li>
                      <li
                        id="personal"
                        className={` ${show === "Image" ? "active done" : ""} ${
                          show === "Personal" ? "active done" : ""
                        } ${
                          show === "Account" ? "active done" : ""
                        } col-lg-3 col-md-6 mb-2 text-start`}
                      >
                        <Link to="#">
                          <i className="material-symbols-outlined">person</i>
                          <span>Medical</span>
                        </Link>
                      </li>
                      <li
                        id="payment"
                        className={` ${show === "Image" ? "active done" : ""} ${
                          show === "Personal" ? "active done" : ""
                        } col-lg-3 col-md-6 mb-2 text-start`}
                      >
                        <Link to="#">
                          <i className="material-symbols-outlined">
                          person
                          </i>
                          <span>Details</span>
                        </Link>
                      </li>
                      <li
                        id="confirm"
                        className={` ${
                          show === "Image" ? "active done" : ""
                        } col-lg-3 col-md-6 mb-2 text-start`}
                      >
                        <Link to="#">
                          <i className="material-symbols-outlined">photo_camera</i>
                          <span>Image</span>
                        </Link>
                      </li>
                    </ul> */}
                    <BorderLinearProgress variant="determinate" value={progressBar} />
                    <br/>
                    <fieldset
                      className={`${show === "A" ? "d-block" : "d-none"}`}
                    >
                      <div className="form-card text-start">
                      <div className="col-7">
                            <h3>Personal Information</h3>
                            <p>Streamline your profile details for a personalized experience. Manage
your account information effortlessly, tailoring it to your preferences.
</p>
                          </div>
                        {/* <Row>
                        

                          <div className="col-5">
                            <h2 className="steps">Step 1 - 4</h2>
                          </div>
                        </Row> */}
                        <Row>
                          <Col md="12">
                          <Form.Group
                              controlId="getVisibility"
                              className="mb-3"
                              id="genderRadioboxContainer"
                              required 
                            >
                              <Form.Label>Case Visibility</Form.Label>
                              <Form.Check
                                value="public"
                                type="radio"
                                aria-label="public"
                                label="Public"
                                onChange={handleVisibilityChange}
                                checked={getVisibility === "public" || postInfo?.visibility === "public"}
                                name="visibility"
                                required 
                              />
                              <Form.Check
                                value="private"
                                type="radio"
                                aria-label="private"
                                label="Private"
                                onChange={handleVisibilityChange}
                                checked={getVisibility === "private" || postInfo?.visibility === "private"}
                                name="visibility"
                                required 
                              />
                               
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="validationTitle">
                              <Form.Label>Title</Form.Label>
                              <Form.Control
                                 
                                name="title"
                                defaultValue={formData.title}
                                type="text"
                                placeholder="Type an attractive title"
                                onChange={handleInputData("title")}
                                required
                              />
                               
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Description</Form.Label>
                              <Form.Control
                                 
                                name="description"
                                defaultValue={formData.description}
                                type="text"
                                placeholder="Describe in brief the case"
                                onChange={handleInputData("description")}
                                required
                              />
                               
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Patient Reference</Form.Label>
                              <Form.Control
                                 
                                name="patientReference"
                                defaultValue={formData.patientReference}
                                type="text"
                                placeholder="Patient #4561"
                                onChange={handleInputData("patientReference")}
                                required
                              />
                               
                            </Form.Group>
                          <Form.Group className="mb-3">
                              <Form.Label>Reason for consultation</Form.Label>
                              <Form.Control
                                 
                                name="reasonConsultation"
                                defaultValue={formData.reasonConsultation}
                                type="text"
                                placeholder="Write something"
                                onChange={handleInputData("reasonConsultation")}
                                required
                              />
                               
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Date of birth</Form.Label>
                              <Form.Control
                                 
                                name="dateOfBirth"
                                defaultValue={formData.dateOfBirth}
                                type="date"
                                placeholder="Date of birth"
                                onChange={handleInputData("dateOfBirth")}
                                required
                              />
                               
                            </Form.Group>
                            <Form.Group
                              controlId="getGender"
                              className="mb-3"
                              id="genderRadioboxContainer"
                            >
                              <Form.Label>Gender</Form.Label>
                              <Form.Check
                                value="male"
                                type="radio"
                                aria-label="male"
                                label="Male"
                                onChange={handleChange}
                                checked={getGender === "male" || postInfo?.gender === "male"}
                                name="gender"
                                required
                              />
                              <Form.Check
                                value="female"
                                type="radio"
                                aria-label="female"
                                label="Female"
                                onChange={handleChange}
                                checked={getGender === "female" || postInfo?.gender === "female"}
                                name="gender"
                                required
                              />
                               
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Medical History</Form.Label>
                              <Form.Control
                                 defaultValue={formData.medicalHistory}
                                type="text"
                                placeholder="Write something"
                                onChange={handleInputData("medicalHistory")}
                                required
                              />
                               
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Dental History</Form.Label>
                              <Form.Control
                                  defaultValue={formData.dentalHistory}
                                type="text"
                                placeholder="Write something"
                                onChange={handleInputData("dentalHistory")}
                                required
                              />
                               
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Daily Medications</Form.Label>
                              <Form.Control
                                 defaultValue={formData.dailyMedications}
                                type="text"
                                placeholder="Write something"
                                onChange={handleInputData("dailyMedications")}
                                required
                              />
                               
                            </Form.Group>


                            <div id="bloodPulseRespirationContainer">
                              <Form.Group className="mb-3">
                                <Form.Label>Blood Pressure</Form.Label>
                                <Form.Control
                                defaultValue={formData.bloodPressure}
                                   min="30" max="250"
                                  type="number"
                                  placeholder="Write something"
                                  onChange={handleInputData("bloodPressure")}
                                  
                                />
                                  <small className="form-text">
                              mm Hg
                              </small>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>Pulse</Form.Label>
                                <Form.Control
                                defaultValue={formData.pulse}
                                    min="30" max="250"
                                  type="number"
                                  placeholder="Write something"
                                  onChange={handleInputData("pulse")}
                                  
                                />
                                <small className="form-text">
                              Beats/min
                              </small>
                              </Form.Group>
                              <Form.Group className="mb-3">
                                <Form.Label>Breathing</Form.Label>
                                <Form.Control
                                     defaultValue={formData.respiration}
                                  type="number"
                                  placeholder="Write something"
                                  onChange={handleInputData("respiration")}
                                  min="5" max="80"
                                  
                                />
                                   <small className="form-text">
                              Breaths/min
                              </small>
                              </Form.Group>
                              <Form.Group className="mb-3">
                              <Form.Label>
                                Concerned theeth{" "}
                                <Icon
                                  icon="fluent:info-24-regular"
                                  onClick={handleOpen}
                                  className="concernedTeethIconInfo"
                                />
                              </Form.Label>
                             
                              <Form.Control
                                 
                                 defaultValue={formData.concernedTeeth}
                                 type="text"
                                placeholder="Write something"
                                onChange={handleInputData("concernedTeeth")}
                                required
                              />
                                <small className="form-text">
                                Please use comma separated (eg.
                                3,13,18,30)
                              </small>
                               
                            </Form.Group>
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <Box sx={style}>
                                <img
                                  src={teethNumbers}
                                  alt="teeth"
                                  style={{ width: "30em", textAlign: "center" }}
                                />
                              </Box>
                            </Modal>
                            </div>

                       


                         
                           
                          
                          
                          </Col>
                        </Row>
                      </div>
                      <Button
                        variant="primary"
                        name="next"
                        className="next action-button float-end"
                        value="Next"
                       

                        onClick={handleNextClick}
                      >
                        Next
                      </Button>
                    </fieldset>
                    <fieldset
                      className={`${show === "Account" ? "d-block" : "d-none"}`}
                    >
                      <div className="form-card text-start">

                      <div className="col-7">
                            <h3>Examination</h3>
                            <p>Streamline your profile details for a personalized experience. Manage
your account information effortlessly, tailoring it to your preferences.
</p>
                          </div>
                   
                        {/* <Row>
                       
                          <div className="col-5">
                            <h2 className="steps">Step 2 - 4</h2>
                          </div>
                        </Row> */}
                        <Row>
                          <Col md="12">
                           
                          
                           
                          

                           
                            <Form.Group className="mb-3">
                              <Form.Label>Extra-oral Examination</Form.Label>
                              <Form.Control
                              defaultValue={formData.extraoralExamination}
                                 required
                                type="text"
                                placeholder="Write something"
                                onChange={handleInputData(
                                  "extraoralExamination"
                                )}
                              />
                               
                            </Form.Group>
                            <Form.Group className="mb-3">
                          <Form.Label>Exo-buccal Examination </Form.Label>
                          <Form.Control
                             required
                            name="examenExoBuccal"
                            defaultValue={formData.examenExoBuccal}
                            type="text"
                            placeholder="Write something"
                            onChange={handleInputData("examenExoBuccal")}
                          />
                           
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Dermatology</Form.Label>
                          <Form.Control
                             required
                            name="dermato"
                            defaultValue={formData.dermato}
                            type="text"
                            placeholder="Write something"
                            onChange={handleInputData("dermato")}
                          />
                          
                        </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Label>Intraoral Examination</Form.Label>
                              <Form.Control
                                 required
                                 defaultValue={formData.intraoralExamination}
                                type="text"
                                placeholder="Write something"
                                onChange={handleInputData(
                                  "intraoralExamination"
                                )}
                              />
                               
                            </Form.Group>
                            <Form.Label>Symmetry</Form.Label>
                        <Form.Group controlId="getSymetrie" className="mb-3">
                          <div id="symetrieContainer">
                            <Form.Check
                              value="ouiSymetrie"
                              type="radio"
                              aria-label="ouiSymetrie"
                              label="Yes"
                              onChange={handleChangeSymetrie}
                              checked={getSymetrie === "ouiSymetrie" || postInfo?.symetrie === "ouiSymetrie" }
                              required
                              name="symetrie"
                            />
                            <Form.Check
                              value="nonSymetrie"
                              type="radio"
                              aria-label="nonSymetrie"
                              label="No"
                              onChange={handleChangeSymetrie}
                              checked={getSymetrie === "nonSymetrie" || postInfo?.symetrie === "nonSymetrie" }
                              required
                              name="symetrie"
                            />
                            
                          </div>
                        </Form.Group>


{getSymetrie === "nonSymetrie" &&  <Form.Group className="mb-3">
                          <Form.Label>
                          If no, indicate location
                          </Form.Label>
                          <Form.Control
                             required
                            name="symetrieExplanation"
                            defaultValue={formData.symetrieExplanation}
                            type="text"
                            placeholder="Write something"
                            onChange={handleInputData("symetrieExplanation")}
                          />
                          
                        </Form.Group>}
                       
                        <Form.Group controlId="getATM" className="mb-3">
                          <Form.Label>ATM examination</Form.Label>
                          <div id="atmContainer">
                            <Form.Check
                              value="examenAtmNormal"
                              type="checkbox"
                              aria-label="examenAtmNormal"
                              label="Normal"
                              name="examenAtmNormal"
                              checked={isAtmNormalChecked || postInfo?.isAtmNormalChecked  }
                              onChange={handleOnIsAtmNormalChange}
                              required
                            />
                            <Form.Check
                              value="Pain"
                              type="checkbox"
                              aria-label="Pain"
                              label="Pain"
                              checked={isAtmDouleurChecked || postInfo?.isAtmDouleurChecked }
                              onChange={handleOnIsAtmDouleurChange}
                              required
                              name="examenAtmPain"
                            />
                            <Form.Check
                              value="Snapping"
                              type="checkbox"
                              aria-label="Snapping"
                              label="Snapping"
                              checked={isAtmClaquementChecked || postInfo?.isAtmClaquementChecked }
                              onChange={handleOnIsAtmClaquementChange}
                              required
                              name="examenAtmSnapping"
                            />
                          </div>
                          <div id="atmAutre">
                            <Form.Check
                              value="Other"
                              type="checkbox"
                              aria-label="Other"
                              label="Other"
                              checked={isAtmAutreChecked || postInfo?.isAtmAutreChecked }
                              onChange={handleOnIsAtmAutreChange}
                              required
                              name="examenAtmOther"
                            />
                            
                            {(isAtmAutreChecked || postInfo?.isAtmAutreChecked   ) &&      
                             <div >
                                <Form.Control
                               required
                              type="text"
                              placeholder="Write something"
                              defaultValue={formData.examenAtmAutreExplanation}
                              name="examenAtmAutreExplanation"
                              onChange={handleInputData("examenAtmAutreExplanation")}
                            />
                              
                                </div>
                          }
                      
                          
                          </div>
                        </Form.Group>
                        <Form.Label>Functional Examination </Form.Label>

<Form.Group
  controlId="getRespirationType"
  className="mb-3"
>
  <Form.Label>Breathing </Form.Label>
  <div id="respirationChoices">
    <Form.Check
      value="Nasal"
      type="radio"
      aria-label="Nasal"
      label="Nasal"
      checked={getRespirationType === "Nasal" || postInfo?.getRespirationType === "Nasal" }
      onChange={handleChangeRespirationType}
      name="breathing"
      required
    />
    <Form.Check
      value="Oral"
      type="radio"
      aria-label="Oral"
      label="Oral"
      checked={getRespirationType === "Oral" || postInfo?.getRespirationType === "Oral" }
      onChange={handleChangeRespirationType}
      name="breathing"
      required
    />
    <Form.Check
      value="Mixed"
      type="radio"
      aria-label="Mixed"
      label="Mixed"
      checked={getRespirationType === "Mixed" || postInfo?.getRespirationType === "Mixed" }
      onChange={handleChangeRespirationType}
      name="breathing"
      required
    />
  </div>

  
</Form.Group>
<Form.Group className="mb-3">
  <Form.Label>Details</Form.Label>
  <Form.Control
     
    name="detailsRespiration"
    defaultValue={formData.detailsRespiration}
    type="text"
    placeholder="Write something"
    onChange={handleInputData("detailsRespiration")}
    
  />
 
</Form.Group>

<Form.Group controlId="getMastication" className="mb-3">
  <Form.Label>Chewing</Form.Label>
  <div id="masticationChoices">
    <Form.Check
      value="Unilateral"
      type="radio"
      aria-label="Unilateral"
      label="Unilateral"
      checked={getMastication === "Unilateral" || postInfo?.getMastication === "Unilateral" }
      onChange={handleChangeMastication}
      name="chewing"
      required
    />
    <Form.Check
      value="Bi-lateral"
      type="radio"
      aria-label="Bi-lateral"
      label="Bilateral"
      checked={ getMastication === "Bi-lateral" || postInfo?.getMastication === "Bi-lateral" }
      onChange={handleChangeMastication}
      name="chewing"
      required
    />
  </div>

  
</Form.Group>
<Form.Group className="mb-3">
  <Form.Label>Details</Form.Label>
  <Form.Control
     
    name="detailsMastication"
    defaultValue={formData.detailsMastication}
    type="text"
    placeholder="Write something"
    onChange={handleInputData("detailsMastication")}
    
  />
 
</Form.Group>

<Form.Group controlId="getDeglutition" className="mb-3">
  <Form.Label>Swallowing </Form.Label>
  <div id="deglutationChoices">
    <Form.Check
      value="Typical"
      type="radio"
      aria-label="Typical"
      label="Typical"
      checked={getDeglutition === "Typical" || postInfo?.getDeglutition === "Typical" }
      onChange={handleChangeDeglutition}
      name="swallowing"
      required
    />
    <Form.Check
      value="Atypical"
      type="radio"
      aria-label="Atypical"
      label="Atypical"
      checked={ getDeglutition === "Atypical" || postInfo?.getDeglutition === "Atypical" }
      onChange={handleChangeDeglutition}
      name="swallowing"
      required
    />
  </div>

  
</Form.Group>
<Form.Group className="mb-3">
  <Form.Label>Details</Form.Label>
  <Form.Control
     
    name="detailsDeglutition"
    defaultValue={formData.detailsDeglutition}
    type="text"
    placeholder="Write something"
    onChange={handleInputData("detailsDeglutition")}
  />

</Form.Group>




<Form.Group className="mb-3">
  <Form.Label>Participant Members</Form.Label>
  <div>
  <small className="form-text">
            Please use comma separated values (eg. Dr. John, Dr. White, Dr. Alex)
          </small>
  </div>
  
  <Form.Control
     
    name="participants"
    defaultValue={formData.participants}
    type="text"
    placeholder="Could be other doctors in this case"
    onChange={handleInputData("participants")}
  />
 
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>Diagnostics</Form.Label>
  <Form.Control
     required
    name="casediagnostics"
    defaultValue={formData.casediagnostics}
    type="text"
    as="textarea" rows={3}
    placeholder="Write something"
    onChange={handleInputData("casediagnostics")}
  />
 
</Form.Group>

<Form.Group className="mb-3">
  <Form.Label>Treatment Plan</Form.Label>


{Array?.isArray(sessions) && sessions?.map((session, index) => (
        <div key={index}>
          <label>{session.name}</label>
          {/* <input
            name="plan"
            placeholder="Enter Treatment Plan"
            value={session.plan}
            onChange={(e) => handleInputChange(e, index)}
          /> */}
            <Form.Control
     required
    name="treatmentplan"
    defaultValue={session.treatmentplan}
    type="text"
    index={index}
    as="textarea" rows={3}
    // value={session.treatmentplan}
    placeholder="Write something"
    // onChange={(e) => handleInputData(e, index)}
    onChange={handleInputData("treatmentplan", index)}
  />
  <Button style={{margin:"1em"}} variant="secondary" onClick={() => deleteSession(index)}>Delete Session</Button>
  <Button
                        variant="success"
                        name="addsession"
                        className="next action-button float-end"
                        value="Add a New Session"
                        style={{margin:"1em"}}

                        onClick={addSession}
                      >
                        Add a New Session
                      </Button>
        </div>
      ))}
       
</Form.Group>
                          </Col>
                        </Row>
                      </div>
                      <Button
                        variant="primary"
                        name="next"
                        className="next action-button float-end"
                        value="Next"
                 

                        onClick={handleNextClick}
                      >
                        Next
                      </Button>
                      <Button
                        variant="dark"
                        name="previous"
                        className="previous action-button-previous float-end me-3"
                        value="Previous"
                        // onClick={() => setShow("A")}
                        onClick={handlePreviousClick}
                      >
                        Previous
                      </Button>
                    </fieldset>
                    <fieldset
                      className={`${
                        show === "Personal" ? "d-block" : "d-none"
                      }`}
                    >
                      <div className="form-card text-start">



                      <div className="col-7">
                            <h3>Diagnostics</h3>
                          </div>
                            <p>Streamline your profile details for a personalized experience. Manage
your account information effortlessly, tailoring it to your preferences.
</p>
                     
                        {/* <Row>
                          <div className="col-7">
                            <h3 className="mb-4">Diagnostics</h3>
                          </div>
                          <div className="col-5">
                            <h2 className="steps">Step 3 - 4</h2>
                          </div>
                        </Row> */}
                        
                        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Radio Panormaic</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Form.Group className="mb-3">
                          <Form.Label>Before</Form.Label>
                          <Form.Control
                             accept="image/*"
                            type="file"
                            name="radiopanoramicbefore"
                            
                            onChange={handleFileChange}
                          />
                           
                        </Form.Group>
                        <Box my={2}>
                          {radiopanoramicbeforeimage && <img src={radiopanoramicbeforeimage} alt="cropped"  width={400}/>}
          
        </Box>
        <Popup
          open={cropOpen}
          handleClose={handleCropClose}
          image={imageToCrop}
          getCroppedFile={(image) => {
            cropPicturesHandler(image);
            base64ImageToBlob(image);
         
            handleCropClose();
          }}
        />
                        <Form.Group className="mb-3">
                          <Form.Label>After</Form.Label>
                          <Form.Control
                             accept="image/*"
                            type="file"
                            name="radiopanoramicafter"
                            
                            onChange={handleFileChange}
                          />
                           
                        </Form.Group>
                        <Box my={2}>
                          {radiopanoramicafterimage && <img src={radiopanoramicafterimage} alt="cropped"  width={400}/>}
          
        </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Cône Beam</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Form.Group className="mb-3">
                          <Form.Label>Before</Form.Label>
                          <Form.Control
                             
                            type="file"
                            name="conebeambefore"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                           
                        </Form.Group>
                        <Box my={2}>
                          {conebeambeforeimage && conebeambeforeimage !== null && <img src={conebeambeforeimage} alt="cropped"  width={400}/>}
          
        </Box>
                        <Form.Group className="mb-3">
                          <Form.Label>After</Form.Label>
                          <Form.Control
                             
                            type="file"
                            name="conebeamafter"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                           
                        </Form.Group>
                        <Box my={2}>
                          {conebeamafterimage && <img src={conebeamafterimage} alt="cropped"  width={400}/>}
          
        </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Endobuccale</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Form.Group className="mb-3">
                          <Form.Label>Before</Form.Label>
                          <Form.Control
                             
                            type="file"
                            name="endobuccalebefore"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                           
                        </Form.Group>
                        <Box my={2}>
                          {endobuccalebeforeimage && <img src={endobuccalebeforeimage} alt="cropped"  width={400}/>}
          
        </Box>
                        <Form.Group className="mb-3">
                          <Form.Label>After</Form.Label>
                          <Form.Control
                             
                            type="file"
                            name="endobuccaleafter"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                           
                        </Form.Group>
                        <Box my={2}>
                          {endobuccaleafterimage && <img src={endobuccaleafterimage} alt="cropped"  width={400}/>}
          
        </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Vue de Face</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Form.Group className="mb-3">
                          <Form.Label>Before</Form.Label>
                          <Form.Control
                             
                            type="file"
                            name="vuefacebefore"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                         
                        </Form.Group>
                        <Box my={2}>
                          {vuefacebeforeimage && <img src={vuefacebeforeimage} alt="cropped"  width={400}/>}
          
        </Box>
                        <Form.Group className="mb-3">
                          <Form.Label>After</Form.Label>
                          <Form.Control
                             
                            type="file"
                            name="vuefaceafter"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                         
                        </Form.Group>
                        <Box my={2}>
                          {vuefaceafterimage && <img src={vuefaceafterimage} alt="cropped"  width={400}/>}
          
        </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Vue de Profil</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Form.Group className="mb-3">
                          <Form.Label>Before</Form.Label>
                          <Form.Control
                             
                            type="file"
                            name="vueprofilbefore"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                          
                        </Form.Group>
                        <Box my={2}>
                          {vueprofilbeforeimage && <img src={vueprofilbeforeimage} alt="cropped"  width={400}/>}
          
        </Box>
                        <Form.Group className="mb-3">
                          <Form.Label>After</Form.Label>
                          <Form.Control
                             
                            type="file"
                            name="vueprofilafter"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                          
                        </Form.Group>
                        <Box my={2}>
                          {vueprofilafterimage && <img src={vueprofilafterimage} alt="cropped"  width={400}/>}
          
        </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Tele-Radio de Profil</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Form.Group className="mb-3">
                          <Form.Label>Before</Form.Label>
                          <Form.Control
                             
                            type="file"
                            name="teleradioprofilbefore"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                        
                        </Form.Group>
                        <Box my={2}>
                          {teleradioprofilbeforeimage && <img src={teleradioprofilbeforeimage} alt="cropped"  width={400}/>}
          
        </Box>
                        <Form.Group className="mb-3">
                          <Form.Label>After</Form.Label>
                          <Form.Control
                             
                            type="file"
                            name="teleradioprofilafter"
                            accept="image/*"
                            onChange={handleFileChange}
                          />
                          
                        </Form.Group>
                        <Box my={2}>
                          {teleradioprofilafterimage && <img src={teleradioprofilafterimage} alt="cropped"  width={400}/>}
          
        </Box>
        </AccordionDetails>
      </Accordion>
                      
                      </div>
                      <div style={{marginTop: "2em"}}>
{editPostMode ? ( <Button
                        variant="primary"
                        name="next"
                        className="next action-button float-end"
                        value="Submit"
                        // onClick={() => setShow("Image")}
                        // onClick={() => { setShow("Image"); setProgressBar(100);}}
                      
                        onClick={(e) => {
                          setProgressBar(100);
                          // setShow("Image");
                          submitFormData();
                          e.preventDefault();
                       
                        }}
                      >
                        Update
                      </Button>) : 
                      
                      <Button
                      variant="primary"
                      name="next"
                      className="next action-button float-end"
                      value="Submit"
                      // onClick={() => setShow("Image")}
                      // onClick={() => { setShow("Image"); setProgressBar(100);}}
                    
                      onClick={(e) => {
                        setProgressBar(100);
                        // setShow("Image");
                        submitFormData();
                        e.preventDefault();
                        handleCloseCaseModal();
                      }}
                    >
                      Submit
                    </Button>
                      }

                     
                      <Button
                        variant="dark"
                        name="previous"
                        className="previous action-button-previous float-end me-3"
                        value="Previous"
                        // onClick={() => setShow("Account")}
                        onClick={handlePreviousClick}
                      >
                        Previous
                      </Button>
                      </div>
                
                    </fieldset>
                    <fieldset
                      className={`${show === "Image" ? "d-block" : "d-none"}`}
                    >
                      <div className="form-card">
                        <Row>
                          <div className="col-7">
                            <h3 className="mb-4 text-left">Finish:</h3>
                          </div>
                          <div className="col-5">
                            <h2 className="steps">Step 4 - 4</h2>
                          </div>
                        </Row>
                        <br />
                        <br />

                        {/* <h2 className="text-success text-center"><strong>SUCCESS !</strong></h2>
                                            <br/>
                                            <Row className="justify-content-center">
                                                <div className="col-3"> 
                                                    <Image src={image1} className="img-fluid" alt="fit-image"/>
                                                </div>
                                            </Row>
                                            <br/><br/>
                                            <Row className="justify-content-center">
                                                <div className="col-7 text-center">
                                                    <h5 className="purple-text text-center">You Have Successfully Signed Up</h5>
                                                </div>
                                            </Row> */}
                     

                      
                      </div>
                      {editPostMode ? (<Button
                        variant="primary"
                        name="next"
                        className="next action-button float-end"
                        value="Submit"
                        onClick={(e) => {
                          
                          setShow("Image");
                          submitFormData();
                          e.preventDefault();
                          handleCloseEditPost();
                        }}
                      >
                        Update
                      </Button>) : (
                        <Button
                        variant="primary"
                        name="next"
                        className="next action-button float-end"
                        value="Submit"
                        onClick={(e) => {
                          
                          setShow("Image");
                          submitFormData();
                          e.preventDefault();
                          handleCloseCaseModal();
                        }}
                      >
                        Submit
                      </Button>
                      )}
                      
                      <Button
                        variant="dark"
                        name="previous"
                        className="previous action-button-previous float-end me-3"
                        value="Previous"
                        onClick={() => setShow("Account")}
                      >
                        Previous
                      </Button>
                    </fieldset>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

FormWizard.propTypes = {
  addPost: PropTypes.func.isRequired,
  editPost: PropTypes.func.isRequired,
  getPost: PropTypes.func.isRequired,
};

export default connect(null, { addPost, editPost, getPost })(FormWizard);
