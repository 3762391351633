import React, { Fragment, useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import ProfileHeader from "../../../components/profile-header";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CreateGroup from "./createGroup";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// images
import img7 from "../../../assets/images/page-img/profile-bg7.jpg";
import img3 from "../../../assets/images/page-img/profile-bg3.jpg";
import groupimg from "../../../assets/images/page-img/group-img.jpg";
import { getGroups } from "../../../actions/group";
import { requestToJoinGroup } from "../../../actions/group";
import { leaveGroup } from "../../../actions/group";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50vw",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const Groups = ({getGroups,groups,requestToJoinGroup,leaveGroup, auth}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getGroups();
  },[getGroups]);
  console.log("groups 0", groups[0]);
  console.log("pending",groups[0]?.pendingRequests?.filter(
    (member) => member)=== auth?.user?._id===false);
    console.log("members",groups[0]?.members?.filter(
      (member) => member)=== auth?.user?._id===false);
console.log("wtf bruh", groups[0]?.pendingRequests?.filter(
  (member) => member === auth?.user?._id
)===false && groups[0]?.members?.filter(
  (member) => member === auth?.user?._id
)===false)
  return (
    <>
      <ProfileHeader img={img7} title="Groups" />
      <Button onClick={handleOpen} style={{float:"right", margin: "2em"}}>
Create Group
          </Button>
      <div id="content-page" className="content-page">
        <Container>

          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <CreateGroup/>
        </Box>
      </Modal>
          <div className="d-grid gap-3 d-grid-template-1fr-19">
          {groups.map((group) => (
  <Card className="mb-0" key={group._id}>
    <div className="top-bg-image">
      <img src={group?.groupInfo?.groupImages?.groupbackgroundimage ? `${
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                group?.groupInfo?.groupImages?.groupbackgroundimage
                              }` : img3} className="img-fluid w-100" alt="group-bg" />
    </div>
    <Card.Body className="text-center">
      <div className="group-icon">
        <img
          src={group?.groupInfo?.groupImages?.groupimage ? `${
            process.env.REACT_APP_BASE_URL +
            "/" +
            group?.groupInfo?.groupImages?.groupimage
          }` : groupimg}
          alt="profile-img"
          className="rounded-circle img-fluid avatar-120"
        />
      </div>
      <div className="group-info pt-3 pb-3">
        <h4>
          {/* <Link to={`/dashboards/app/group-details/${group._id}`}>
           
          </Link> */}
          {group?.groupInfo?.title}
        </h4>
        <p>{group?.groupInfo?.description}</p>
      </div>
      <div className="group-details d-inline-block pb-3">
        <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0">
          <li className="pe-3 ps-3">
            <p className="mb-0">Posts</p>
            <h6>{group?.posts?.length}</h6>
          </li>
          <li className="pe-3 ps-3">
            <p className="mb-0">Members</p>
            <h6>{group?.members?.length}</h6>
          </li>
          {/* <li className="pe-3 ps-3">
            <p className="mb-0">Visits</p>
            <h6>{group?.visits}</h6>
          </li> */}
        </ul>
      </div>
      {/* <div className="group-member mb-3">
        <div className="iq-media-group">
          {group?.members?.slice(0, 6).map((member) => (
            <Link to="#" className="iq-media" key={member?._id}>
              <img
                className="img-fluid avatar-40 rounded-circle"
                src={member?.avatar}
                alt=""
              />
            </Link>
          ))}
        </div>
      </div> */}

{(() => {
  if (group._id && (group?.user === auth?.user?._id || group?.members?.includes(auth?.user?._id))) {
    return (
      <Link to={`/dashboards/app/group-details/${group._id}`}>
        <button className="btn btn-primary d-block w-100">Visit Group</button>
      </Link>
    );
  } else if (group._id && group?.pendingRequests?.includes(auth?.user?._id)) {
    return (
      <button className="btn btn-secondary d-block w-100" disabled>
        Request sent
      </button>
    );
  } else  {
    return (
      <button
        className="btn btn-success d-block w-100"
        onClick={(e) => {
          e.preventDefault();
          requestToJoinGroup(group._id);
          getGroups();
          getGroups();
        }}
      >
        Join
      </button>
    );
  }
  
})()}

      {/* {group._id && 
                            (group?.user === auth?.user?._id || group?.members?.filter(
                              (member) => member === auth?.user?._id
                            ).length !== 0) && (
                              <Link to={`/dashboards/app/group-details/${group._id}`}>
                                <button className="btn btn-primary d-block w-100" >
                              Visit Group
                            </button>
                            </Link>
                            
                            )}
    {group._id  &&
                            group?.pendingRequests?.filter(
                              (member) => member === auth?.user?._id
                            ).length !== 0 && (
                              <button className="btn btn-secondary d-block w-100" disabled>
                              Request sent
                            </button>
                            )}

{group._id  &&
                            (group?.pendingRequests?.some((member) => member !== auth?.user?._id) &&
                            group?.members?.some((member) => member !== auth?.user?._id)) &&(
                              <button className="btn btn-success d-block w-100" onClick={(e)=>{ e.preventDefault(); requestToJoinGroup(group._id);getGroups();getGroups() }}>
                              Join
                            </button>
                            )} */}
                                {/* <button className="btn btn-secondary d-block w-100" onClick={(e)=>{ e.preventDefault(); requestToJoinGroup(group._id) }}>
                              Join
                            </button> */}
    
    </Card.Body>
  </Card>
))}

          </div>
        </Container>
      </div>
    </>
  );
};
Groups.propTypes = {
  getGroups: PropTypes.func.isRequired,
  leaveGroup: PropTypes.func.isRequired,
  requestToJoinGroup: PropTypes.func.isRequired,
  groups: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  groups: state.group.groups,
  auth: state.auth
});
export default connect(mapStateToProps, {
  getGroups,
  leaveGroup,
  requestToJoinGroup,
})(Groups);
