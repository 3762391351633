import React from 'react'
import { Row, Col, Container, Form} from 'react-bootstrap'
import Card from '../../../components/Card'

import {Link} from 'react-router-dom'


const UserPrivacySetting =() =>{
  return(
     <>
        <Container>
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Cookies Notice</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className="acc-privacy">
                            <div data-id="933fda7" data-element_type="widget" data-model-cid="c3890" className="elementor-element elementor-element-edit-mode elementor-element-933fda7 elementor-element--toggle-edit-tools elementor-widget elementor-widget-text-editor elementor-element-editable" data-widget_type="text-editor.default">
  <div className="elementor-element-overlay">
    <ul className="elementor-editor-element-settings elementor-editor-widget-settings">
      <li className="elementor-editor-element-setting elementor-editor-element-edit" title="Modifier Éditeur de texte">
        <i className="eicon-edit" aria-hidden="true"></i>
        <span className="elementor-screen-only">Modifier Éditeur de texte</span>
      </li>
    </ul>
  </div>
  <div className="elementor-widget-container">
    <div className="elementor-text-editor elementor-clearfix elementor-inline-editing" data-elementor-setting-key="editor" data-elementor-inline-editing-toolbar="advanced">
      <h2><span style={{ fontWeight: 400 }}>1. Introduction</span></h2>
      <p><span style={{ fontWeight: 400 }}>Notre site web, https://www.dentistup.tn/ (ci-après : « le site web ») utilise des cookies et autres technologies liées (par simplification, toutes ces technologies sont désignées par le terme « cookies »). Des cookies sont également placés par des tierces parties que nous avons engagées. Dans le document ci-dessous, nous vous informons de l’utilisation des cookies sur notre site web.</span></p>
      <h2><span style={{ fontWeight: 400 }}>2. Que sont les cookies ?</span></h2>
      <p><span style={{ fontWeight: 400 }}>Un cookie est un petit fichier simple envoyé avec les pages de ce site web et stocké par votre navigateur sur le disque dur de votre ordinateur ou d’un autre appareil. Les informations qui y sont stockées peuvent être renvoyées à nos serveurs ou aux serveurs des tierces parties concernées lors d’une visite ultérieure.</span></p>
      <h2><span style={{ fontWeight: 400 }}>3. Que sont les scripts ?</span></h2>
      <p><span style={{ fontWeight: 400 }}>Un script est un élément de code utilisé pour que notre site web fonctionne correctement et de manière interactive. Ce code est exécuté sur notre serveur ou sur votre appareil.</span></p>
      <h2><span style={{ fontWeight: 400 }}>4. Qu’est-ce qu’une balise invisible ?</span></h2>
      <p><span style={{ fontWeight: 400 }}>Une balise invisible (ou balise web) est un petit morceau de texte ou d’image invisible sur un site web, utilisé pour suivre le trafic sur un site web. Pour ce faire, diverses données vous concernant sont stockées à l’aide de balises invisibles.</span></p>
      <h2><span style={{ fontWeight: 400 }}>5. Cookies</span></h2>
      <p><strong>5.1 Cookies techniques ou fonctionnels</strong></p>
      <p><span style={{ fontWeight: 400 }}>Certains cookies assurent le fonctionnement correct de certaines parties du site web et la prise en compte de vos préférences en tant qu’utilisateur. En plaçant des cookies fonctionnels, nous vous facilitons la visite de notre site web. Ainsi, vous n’avez pas besoin de saisir à plusieurs reprises les mêmes informations lors de la visite de notre site web et, par exemple, les éléments restent dans votre panier jusqu’à votre paiement. Nous pouvons placer ces cookies sans votre consentement.</span></p>
      <p><strong>5.2 Cookies de marketing/suivi</strong></p>
      <p><span style={{ fontWeight: 400 }}>Les cookies de marketing/suivi sont des cookies ou toute autre forme de stockage local, utilisés pour créer des profils d’utilisateurs afin d’afficher de la publicité ou de suivre l’utilisateur sur ce site web ou sur plusieurs sites web dans des finalités marketing similaires.</span></p>
      <p><strong>5.3 Boutons de réseaux sociaux</strong></p>
      <p><span style={{ fontWeight: 400 }}>Nous avons inclus sur notre site web des boutons pour que Facebook, Twitter, LinkedIn et Instagram puissent promouvoir des pages web (par ex. « J’aime », « Enregistrer ») ou partager (par ex. « Tweeter ») sur des réseaux sociaux tels que Facebook, Twitter, LinkedIn et Instagram. Ces boutons fonctionnent avec des morceaux de code provenant des Facebook, Twitter, LinkedIn et Instagram eux-mêmes. Ce code place des cookies. Ces boutons de réseaux sociaux peuvent également stocker et traiter certaines informations afin de vous montrer des publicités personnalisées.</span></p>
      <p><span style={{ fontWeight: 400 }}>Veuillez lire la déclaration de confidentialité de ces réseaux sociaux (qui peut être modifiée régulièrement) afin de savoir ce qu’ils font de vos données (personnelles) traitées en utilisant ces cookies. Les données récupérées sont anonymisées autant que possible. Facebook, Twitter, LinkedIn et Instagram se trouvent aux États-Unis.</span></p>
      <h2><span style={{ fontWeight: 400 }}>6. Cookies placés</span></h2>
      <p><span style={{ fontWeight: 400 }}>[LISTE et NATURE COOKIES]</span></p>
      <h2><span style={{ fontWeight: 400 }}>7. Consentement</span></h2>
      <p><span style={{ fontWeight: 400 }}>Lorsque vous visitez notre site web pour la première fois, nous vous montrerons une fenêtre contextuelle avec une explication sur les cookies. Dès que vous cliquez sur « Accepter », vous consentez à ce que nous utilisions tous les cookies et extensions comme décrit dans la fenêtre contextuelle et la présente politique de cookies. Vous pouvez désactiver l’utilisation des cookies via votre navigateur, mais veuillez noter que notre site web pourrait ne plus fonctionner correctement.</span></p>
      <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
      <h2><span style={{ fontWeight: 400 }}>8. Vos droits concernant les données personnelles</span></h2>
      <p><span style={{ fontWeight: 400 }}>Vous avez les droits suivants concernant vos données personnelles :</span></p>
      <ul>
        <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Vous avez le droit de savoir pourquoi vos données personnelles sont nécessaires, ce qui leur arrivera et combien de temps elles seront conservées.</span></li>
        <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Droit d’accès : vous avez le droit d’accéder à vos données personnelles que nous connaissons.</span></li>
        <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Droit de rectification : vous avez le droit à tout moment de compléter, corriger, faire supprimer ou bloquer vos données personnelles.</span></li>
        <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Si vous nous donnez votre consentement pour le traitement de vos données, vous avez le droit de révoquer ce consentement et de faire supprimer vos données personnelles.</span></li>
        <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Droit de transférer vos données : vous avez le droit de demander toutes vos données personnelles au responsable du traitement et de les transférer dans leur intégralité à un autre responsable du traitement.</span></li>
        <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>Droit d’opposition : vous pouvez vous opposer au traitement de vos données. Nous obtempérerons, à moins que certaines raisons ne justifient ce traitement.</span></li>
      </ul>
      <p><span style={{ fontWeight: 400 }}>&nbsp;</span></p>
      <p><span style={{ fontWeight: 400 }}>Pour exercer ces droits, veuillez nous contacter. Veuillez vous référer aux coordonnées au bas de cette politique de cookies. Si vous avez une plainte concernant la façon dont nous traitons vos données, nous aimerions en être informés, mais vous avez également le droit de déposer une plainte auprès de l’autorité de contrôle (l’autorité chargée de la protection des données, comme le CEPD).</span></p>
      <h2><span style={{ fontWeight: 400 }}>9. Activer/désactiver et supprimer les cookies</span></h2>
      <p><span style={{ fontWeight: 400 }}>Vous pouvez utiliser votre navigateur internet pour supprimer automatiquement ou manuellement les cookies. Vous pouvez également spécifier que certains cookies ne peuvent pas être placés. Une autre option consiste à modifier les réglages de votre navigateur Internet afin que vous receviez un message à chaque fois qu’un cookie est placé. Pour plus d’informations sur ces options, reportez-vous aux instructions de la section Aide de votre navigateur.</span></p>
      <p><span style={{ fontWeight: 400 }}>Veuillez noter que notre site web peut ne pas marcher correctement si tous les cookies sont désactivés. Si vous supprimez les cookies dans votre navigateur, ils seront de nouveau placés après votre consentement lorsque vous revisiterez nos sites web.</span></p>
      <h2><span style={{ fontWeight: 400 }}>10. Coordonnées</span></h2>
      <p><span style={{ fontWeight: 400 }}>Pour des questions et/ou des commentaires sur notre politique de cookies et cette déclaration, veuillez nous contacter en utilisant les coordonnées suivantes :</span></p>
      <p><span style={{ fontWeight: 400 }}>[COORDONNÉES]</span></p>
    </div>
  </div>
</div>


                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
     </>
  )

}

export default UserPrivacySetting;