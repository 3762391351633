import {
    GENERATE_GPT,
    GENERATE_ERROR,
 
  } from "../../actions/types";
  
  const initialState = {
    generates: [],
    generate: null,
    loading: true,
    error: {},
  };
  
  export default function generateReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GENERATE_GPT:
        return {
          ...state,
          generates: payload,
          
          loading: false,
        };
      case GENERATE_ERROR:
        return {
          ...state,
          error: payload,
          loading: false,
        };
      default:
        return state;
    }
  }
  