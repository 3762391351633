import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import img1 from "../../../../assets/images/page-img/profile-bg2.jpg";
import Card from "../../../../components/Card";
import { Row, Col, Container } from "react-bootstrap";
import { connect } from "react-redux";
import {followUser} from "../../../../actions/profile"
import { loadUser } from "../../../../actions/auth";
import { acceptRequest, rejectRequest} from "../../../../actions/group";
const GroupProfileItem = ({
  profile: {
    user: { _id, name, avatar },
    status,
    company,
    location,
    skills,
    profileImage,
    profileCover,
  },
  auth,
  followUser,
  loadUser,
  rejectRequest,
  acceptRequest,
  groupId,
  getGroup,
}) => {
   
  return (
    <Col md={6}>
      <Card className=" card-block card-stretch card-height">
        <Card.Body className=" profile-page p-0">
          <div className="profile-header-image">
            <div className="cover-container profile-top-container"
        style={{
          backgroundImage: profileCover
            ? "url(" +
              `${process.env.REACT_APP_BASE_URL + "/" + profileCover}` +
              ")"
            : "url(" + img1 + ")",
        }}>
             
            </div>
            <div className="profile-info p-4">
              <div className="user-detail">
                <div className="d-flex flex-wrap justify-content-between align-items-start">
                  <div className="profile-detail d-flex">
                    <div className="profile-img pe-4">
                      <img
                        loading="lazy"
                        src={
                          profileImage
                            ? `${
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                profileImage
                              }`
                            : avatar
                        }
                        alt="Dentisup"
                        className="avatar-130 img-fluid"
                      />
                    </div>
                    <div className="user-data-block">
                      <h4>
                        <Link to={`/dashboard/app/profile-feed/${_id}`}>
                          {name}
                        </Link>
                      </h4>
                      <h6>
                        {status} {company && <span> at {company}</span>}
                      </h6>
                      <p>
                        <ul
                          style={{
                            listStyleType: "none",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          {skills?.slice(0, 4).map((skill, index) => (
                            <li key={index} className="text-primary">
                              <i className="fas fa-check" /> {skill}
                            </li>
                          ))}
                        </ul>
                      </p>
                    </div>
                  </div>
     

{_id && _id !== auth?._id &&
                            auth?.following?.filter(
                              (following) => following?.user?.toString() === _id
                            ).length === 0 && (
                              <button type="submit" className="btn btn-primary"   onClick={(e) => {
                                e.preventDefault();
                                followUser(_id);
                                loadUser();
                                loadUser();
                                loadUser();
                              }}>
                        Follow
                      </button>
                            )}
{_id && _id !== auth?._id &&
                            auth?.following?.filter(
                              (following) => following?.user?.toString() === _id
                            ).length !== 0 && (
                              <button type="submit" className="btn btn-secondary"   onClick={(e) => {
                                e.preventDefault();
                                followUser(_id);
                                loadUser();
                                loadUser();
                                loadUser();
                              }}>
                        Unfollow
                      </button>
                            )}

<button type="submit" className="btn btn-success"   onClick={(e) => {
                                e.preventDefault();
                                acceptRequest(groupId, _id);
                                getGroup();
                              }}>
                        Accept
                      </button>
                      <button type="submit" className="btn btn-secondary"   onClick={(e) => {
                                e.preventDefault();
                                rejectRequest(groupId, _id);
                                getGroup();
                              }}>
                        Reject
                      </button>
                            
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

GroupProfileItem.propTypes = {
  followUser: PropTypes.func.isRequired,
  acceptRequest: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  profiles: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiles: state.auth,
  auth: state.auth.user,
});

export default connect(mapStateToProps, {
  followUser,
  loadUser,
  acceptRequest,
})(GroupProfileItem);
