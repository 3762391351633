import React from 'react'
import { Row, Col, Container, Form} from 'react-bootstrap'
import Card from '../../../components/Card'

import {Link} from 'react-router-dom'


const UserPrivacySetting =() =>{
  return(
     <>
        <Container>
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Privacy and Policy</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className="acc-privacy">
                            <div className="elementor-text-editor elementor-clearfix elementor-inline-editing" data-elementor-setting-key="editor" data-elementor-inline-editing-toolbar="advanced">
  <h1><strong>Mentions Légales</strong></h1>
  <ol>
    <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>EDITION DU SITE</span></li>
  </ol>
  <p><span style={{ fontWeight: 400 }}>Le site DentistUP accessible par voie électronique à l'adresse url www.dentistup.tn est la propriété de la société Carthage Solutions, SARL au capital de 3000 dt, inscrite au registre de commerce des entreprises de Tunisie sous le numéro 1442889S et dont le siège social est Avenue Taieb Mhiri, Imm. ElMakateb, Bureau 5-3, 5000 Monastir, Tunisie.</span></p>
  <p><span style={{ fontWeight: 400 }}>Les coordonnées de DentistUP sont :</span></p>
  <ul>
    <li><span style={{ fontWeight: 400 }}>Adresse postale (siège) : Avenue Taieb Mhiri, Imm. ElMakateb, Bureau 5-3, 5000 Monastir, Tunisie;</span></li>
    <li><span style={{ fontWeight: 400 }}>adresse de courrier électronique : carthage.solutions@gmail.com ;</span></li>
    <li><span style={{ fontWeight: 400 }}>numéro de téléphone : +216 58 388 777</span></li>
  </ul>
  <p><span style={{ fontWeight: 400 }}>La [NATURE Entreprise]. </span></p>
  <p><span style={{ fontWeight: 400 }}>DentistUP est la société éditrice du Site.</span></p>
  <p><span style={{ fontWeight: 400 }}>Le Directeur de la publication du Site est monsieur Oussama SOUAF, agissant en qualité de Gérant de la Carthage Solutions.</span></p>
  <ol>
    <li style={{ fontWeight: 400 }} aria-level="1"><span style={{ fontWeight: 400 }}>HÉBERGEMENT DU SITE</span></li>
  </ol>
  <p><span style={{ fontWeight: 400 }}>Le Site est hébergé par OVH.</span></p>
  <p>&nbsp;</p>
  <p><strong>Définitions :</strong></p>
  <p>&nbsp;</p>
  <p><strong>Confidentialité</strong></p>
  <p>&nbsp;</p>
  <p><strong>Recueil et stockage des données personnelles</strong></p>
  <p>&nbsp;</p>
  <p><strong>Protection des données personnelles</strong></p>
  <h1><strong>Avertissement :</strong></h1>
  <p><span style={{ fontWeight: 400 }}>DentistUP s'engage à maintenir ce site web à jour en temps et en heure. Si toutefois vous rencontriez un problème ou des données obsolètes, nous vous serions reconnaissants de nous le faire savoir. Veuillez indiquer où sur le site web vous lisez les informations incorrectes. Nous examinerons cela dès que possible. Veuillez envoyer votre réponse par e-mail à: [ADRESSMAIL]</span></p>
  <p><span style={{ fontWeight: 400 }}>Nous ne sommes pas responsables des pertes résultant d'inexactitudes ou de lacunes, ni des pertes résultant de problèmes causés par ou inhérents à la diffusion d'informations par Internet, tels que des perturbations ou des interruptions. Lors de l'utilisation de formulaires Web, nous nous efforçons de limiter le nombre de champs obligatoires au minimum. Pour toute perte subie à la suite de l'utilisation de données, de conseils ou d'idées fournis par ou au nom de DentistUP via ce site web, DentistUP n'assume aucune responsabilité.</span></p>
  <p><span style={{ fontWeight: 400 }}>Tous les droits de propriété intellectuelle sur le contenu de ce site web sont la propriété de DentistUP.</span></p>
  <p><span style={{ fontWeight: 400 }}>La copie, la diffusion et toute autre utilisation de ces documents sont interdites sans l'autorisation écrite de DentistUP, sauf si et dans la mesure où le stipule une réglementation impérative (telle que le droit de citer), sauf indication contraire du contenu.</span></p>
  <p><span style={{ fontWeight: 400 }}>Si vous avez des questions ou des problèmes d'accessibilité du site, n'hésitez pas à nous contacter.</span></p>
  <p>&nbsp;</p>
</div>

                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
     </>
  )

}

export default UserPrivacySetting;