// import React from "react";

// //layoutpages
// import Default from "../layouts/dashboard/default";

// import { DefaultRouter } from "./default-router";
// import { Layout1Router } from "./layout1-router";

// export const IndexRouters = [
//   {
//     path: "/",
//     element: <Default />,
//     children: [...DefaultRouter, ...Layout1Router],
//   },
// ];

// import React from "react";
// import { Navigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// //layoutpages
// import Default from "../layouts/dashboard/default";

// import { DefaultRouter } from "./default-router";
// import { Layout1Router } from "./layout1-router";

// // Function to check if the user is logged in
// const isLoggedIn = () => {
//   const auth = useSelector((state) => state.auth);
//   console.log("auth???", auth);

//   // Replace this with your own logic to check if the user is logged in
//   const loggedIn = false; // Example: Assume the user is logged in
//   return loggedIn;
// };

// // Wrapper component to handle redirection
// const PrivateRoute = ({ element }) => {
//   return isLoggedIn() ? element : <Navigate to="/auth/sign-in" />;
// };

// export const IndexRouters = [
//   {
//     path: "/",
//     element: <PrivateRoute element={<Default />} />, // Wrap the component with PrivateRoute
//     children: [...DefaultRouter, ...Layout1Router],
//   },
// ];

import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

//layoutpages
import Default from "../layouts/dashboard/default";

import { DefaultRouter } from "./default-router";
import { Layout1Router } from "./layout1-router";

// Function to check if the user is logged in
const isLoggedIn = (auth) => {
  // console.log("auth??", auth);
  // Replace this with your own logic to check if the user is logged in
  const loggedIn = auth.token; // Example: Assume the user is logged in

// const savedAuth = localStorage.getItem("secondAuth");
// const savedAuthString = JSON.parse(savedAuth);

// if(savedAuthString?._id!==auth.user?._id){
//   console.log("same auth?", savedAuthString?._id?.toString()?.trim()===auth.user?._id?.toString()?.trim())
//   console.log("same auth savedAuthString?", savedAuthString?._id)
//   console.log("same auth auth.user?", auth.user?._id)
//   localStorage.setItem("auth", JSON.stringify(auth.user));
// }
// window.dispatchEvent(new Event("storage"));
// localStorage.setItem("auth", JSON.stringify(auth.user));
  return loggedIn;
};

// Wrapper component to handle redirection
const PrivateRoute = ({ element, auth }) => {
  return isLoggedIn(auth) ? element : <Navigate to="/auth/sign-in" />;
};

const mapStateToProps = (state) => ({
  auth: state.auth, // Assuming `auth` is the prop that contains the login state
});

const ConnectedPrivateRoute = connect(mapStateToProps)(PrivateRoute);

export const IndexRouters = [
  {
    path: "/",
    element: <ConnectedPrivateRoute element={<Default />} />, // Wrap the component with ConnectedPrivateRoute
    children: [...DefaultRouter, ...Layout1Router],
  },
];
