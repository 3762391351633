import { React, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
//profile-header
import ProfileHeader from "../../../components/profile-header";

// image
import img1 from "../../../assets/images/page-img/profile-bg2.jpg";
import img2 from "../../../assets/images/page-img/profile-bg1.jpg";
import img3 from "../../../assets/images/page-img/profile-bg3.jpg";
import img4 from "../../../assets/images/page-img/profile-bg4.jpg";
import img5 from "../../../assets/images/page-img/profile-bg5.jpg";
import img6 from "../../../assets/images/page-img/profile-bg6.jpg";
import img7 from "../../../assets/images/page-img/profile-bg7.jpg";
import img8 from "../../../assets/images/page-img/profile-bg8.jpg";
import img9 from "../../../assets/images/page-img/profile-bg9.jpg";
import user05 from "../../../assets/images/user/05.jpg";
import user06 from "../../../assets/images/user/06.jpg";
import user07 from "../../../assets/images/user/07.jpg";
import user08 from "../../../assets/images/user/08.jpg";
import user09 from "../../../assets/images/user/09.jpg";
import user10 from "../../../assets/images/user/10.jpg";
import user13 from "../../../assets/images/user/13.jpg";
import user14 from "../../../assets/images/user/14.jpg";
import user15 from "../../../assets/images/user/15.jpg";
import user16 from "../../../assets/images/user/16.jpg";
import user17 from "../../../assets/images/user/17.jpg";
import user18 from "../../../assets/images/user/18.jpg";
import user19 from "../../../assets/images/user/19.jpg";

import ProfileItem from "./profileItem/ProfileItem";
import { connect } from "react-redux";
import { getAllProfiles } from "../../../actions/profile";

const FriendList = ({ getAllProfiles, profile: { profiles, loading } }) => {
  useEffect(() => {
    getAllProfiles();
  }, [getAllProfiles]);
  const [searchKeyword, setSearchKeyword] = useState(" ");

  const filteredProfiles = profiles?.filter(
    (profile) =>
      profile?.user &&
      profile?.user?.name?.toLowerCase()?.includes(searchKeyword?.toLowerCase())
  );
  return (
    <>
      <ProfileHeader title="Dentists" img={img3} />
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            {filteredProfiles.length > 0 ? (
              filteredProfiles.map(
                (profile) =>
                  profile.user && (
                    <ProfileItem key={profile._id} profile={profile} />
                  )
              )
            ) : (
              <h4>No Profiles found...</h4>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

FriendList.propTypes = {
  getAllProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getAllProfiles })(FriendList);
