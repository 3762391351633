import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import img1 from "../../../../assets/images/page-img/profile-bg2.jpg";
import Card from "../../../../components/Card";
import { Row, Col, Container, Button, Form } from "react-bootstrap";
import { connect } from "react-redux";
import {getProfileById} from "../../../../actions/profile"
import { loadUser } from "../../../../actions/auth";
import { verifyUser} from "../../../../actions/auth";
const AdminProfileItem = ({
  profile: {
    user: { _id, name, avatar },
    status,
    company,
    location,
    skills,
    profileImage,
    profileCover,
    
  },
  // key,
  auth,
  getProfileById,
  verifyUser,
  loadUser,
  rejectRequest,
  acceptRequest,
  proofPicture,
}) => {
  const [rejectionComment, setRejectionComment] = useState('');
  const [showCommentInput, setShowCommentInput] = useState(false);
  const handleCommentChange = (e) => {
    setRejectionComment(e.target.value);
  };

  return (
    <Row>
  <Col md={12}>
      <Card className=" card-block card-stretch card-height">
        <Card.Body className=" profile-page p-0">
          <div className="profile-header-image">
  
            <div className="profile-info p-4" style={{display:"flex",alignItems:"flex-start",justifyContent:"space-between"}}>

              <div className="user-detail" style={{ width: "30vw"}}>
                <div className="d-flex flex-wrap justify-content-between align-items-start">
                  <div className="profile-detail d-flex">
                    <div className="profile-img pe-4" style={{marginTop:"-1rem"}}>
                      <img
                        loading="lazy"
                        style={{width:100, height:100}}
                        src={
                          profileImage
                            ? `${
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                profileImage
                              }`
                            : avatar
                        }
                        alt="Dentisup"
                        className="avatar-130 img-fluid"
                      />
                    </div>
                    <div className="user-data-block">
                      <h4>
                        <Link to={`/dashboard/app/profile-feed/${_id}`}>
                          {name}
                        </Link>
                      </h4>
                      <h6>
                        {status} {company && <span> at {company}</span>}
                      </h6>
                      <p>
                        <ul
                          style={{
                            listStyleType: "none",
                            margin: 0,
                            padding: 0,
                          }}
                        >
                          {skills?.slice(0, 4).map((skill, index) => (
                            <li key={index} className="text-primary">
                              <i className="fas fa-check" /> {skill}
                            </li>
                          ))}
                        </ul>
                      </p>
                      
                    </div>
                    
                  </div>
     
<div className="profilesRequestActions" style={{display:"flex",gap:10}}>
<button type="submit" className="btn btn-success"   onClick={(e) => {
                                e.preventDefault();
                                // console.log("sending what?",_id);
                                verifyUser(_id,"accepted","none");
                              
                                // getProfileById(key);
                              }}>
                        Accept
                      </button>
                      <button type="submit" className="btn btn-secondary"   onClick={(e) => {
                                e.preventDefault();
                                setShowCommentInput(!showCommentInput);
                             
                              
                              }}>
                        Reject
                      </button>
</div>


{showCommentInput && (
     
            <Col md="8">
            <Form.Control as="textarea" rows={3}  placeholder="Enter rejection comment" 
             className="mb-3"
           
            value={rejectionComment}
            onChange={handleCommentChange} />
    
          <Button  onClick={(e) => {
                                e.preventDefault();
                             
                                verifyUser(_id,"rejected",rejectionComment);
                                // getProfileById(key);
                              }}>Submit Rejection</Button>
        </Col>
      
       
      )}

                            
                </div>
              </div>
              <div className="proofContainer" style={{textAlign:"center"}}>
          <h3>Proof of Identity/Profession :</h3>
    <img
                        loading="lazy"
                        src={
                           `${
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                proofPicture
                              }`
                            
                        }
                        style={{marginTop:"2rem"}}
                        alt="Dentisup"
                        className="avatar-130 img-fluid"
                      />
          </div>
          
            </div>
     
          </div>

        
        </Card.Body>
      </Card>
    </Col>

    </Row>
  
  );
};

AdminProfileItem.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  verifyUser: PropTypes.func.isRequired,
  profiles: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiles: state.auth,
  auth: state.auth.user,
});

export default connect(mapStateToProps, {

  getProfileById,
  verifyUser
})(AdminProfileItem);
