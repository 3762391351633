import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form, Button, Alert, Tab, Nav} from "react-bootstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import img1 from "../../../assets/images/page-img/profile-bg2.jpg";

import {
  getProfileById,
  createProfile,
  getCurrentProfile,
} from "../../../actions/profile";

import {
  updatePassword,

} from "../../../actions/auth";


import {
  generateAi,

} from "../../../actions/generate";

const UserAccountSetting = ({
  createProfile,
  profile: { profiles },
  //   profile,
  getProfileById,
  getCurrentProfile,
  generateAi,
  updatePassword,
}) => {

  const [showAlert, setShowAlert] = useState(false);

  const [hasOwnCompany, setHasOwnCompany] = useState({ getHasOwnCompany: "" });

  const { getHasOwnCompany } = hasOwnCompany;
  const handleChangeHasOwnCompany = (e) => {
    e.persist();
    console.log(e?.target?.value);

    setFormData((prevState) => ({
      ...prevState,
      ["hasOwnCompany"]: e?.target?.value,
    }));

    setHasOwnCompany((prevState) => ({
      ...prevState,
      getHasOwnCompany: e?.target?.value,
    }));
  };

  const [animalInput, setAnimalInput] = useState("");
  const profile = useSelector((state) => state.profile.profile);
  const alert = useSelector((state) => state.alert);

  const auth = useSelector((state) => state.auth.user);












//PW START


const [pwFormData, setPwFormData] = useState({
  currentPassword: '',
  newPassword: '',
});

const { currentPassword, newPassword } = pwFormData;



const onPwChange = (e) => {
  setPwFormData({ ...pwFormData, [e.target.name]: e.target.value });
};

const onPwSubmit = async (e) => {
  e.preventDefault();
  // console.log("CurrentPw", currentPassword, "newPw", newPassword)
  updatePassword(currentPassword,newPassword);
}

//PW END









  // const generateResponse = useSelector((state) => state.generate);
  // console.log("wtf", profile);
  // const retrievedAuth = localStorage.getItem("auth");
  // const myProfile = JSON.parse(retrievedAuth);
  // const id = myProfile?._id;

  // const [profile, setProfile] = useState(profile.profile);

  // useEffect(() => {
  //   // Fetch the profile only when id is available
  //   if (id) {
  //     getProfileById(id);
  //   }
  // }, [getProfileById, id]);

  // useEffect(() => {
  //   // Filter the profiles array to find the profile based on the id
  //   const myProfile = profiles.find(profile => profile.user._id === id);
  //   setProfile(myProfile || null); // Set to null if profile is not found
  // }, [profiles, id]);

  const [formData, setFormData] = useState({
    company: "",
    companyphonenumber: "",
    companyphonecode: "",
    companyaddress: "",
    website: "",
    country:"",
    state: "",
    bio: "",
    status: "",
    speciality: "",
    email: "",
    // githubusername: "",
    skills: "",
    youtube: "",
    twitter: "",
    instagram: "",
    linkedin: "",
    facebook: "",
    name: "",
    phonenumber:"",
    phonecode:"",
    profileImage: null,
    profileCover: null, 
    proofPicture:null,
    verified: "pending",
   
  });

  useEffect(() => {
    getCurrentProfile();

    setFormData({
      company: !profile?.company ? "" : profile?.company,
      companyphonenumber: !profile?.companyphonenumber ? "" : profile?.companyphonenumber,
      companyphonecode: !profile?.companyphonecode ? "" : profile?.companyphonecode,
      companyaddress: !profile?.companyaddress ? "" : profile?.companyaddress,
      website: !profile?.website ? "" : profile?.website,
      country: !profile?.country ? "" : profile?.country,
      state: !profile?.state ? "" : profile?.state,
      status: !profile?.status ? "" : profile?.status,
      speciality: !profile?.speciality ? "" : profile?.speciality,
      skills: !profile?.skills ? "" : profile?.skills.join(","),
      phonenumber: !profile?.phonenumber ? "" : profile?.phonenumber,
      phonecode: !profile?.phonecode ? "" : profile?.phonecode,
        email:  !profile?.user?.email ? auth?.email : profile?.user?.email,
      bio: !profile?.bio ? "" : profile?.bio,
      twitter: !profile?.social ? "" : profile?.social?.twitter,
      facebook: !profile?.social ? "" : profile?.social?.facebook,
      linkedin: !profile?.social ? "" : profile?.social?.linkedin,
      youtube: !profile?.social ? "" : profile?.social?.youtube,
      instagram: !profile?.social ? "" : profile?.social?.instagram,
      name: !profile?.user?.name ? auth?.name : profile?.user?.name,
      profileImage: !profile?.profileImage ? "" : profile?.profileImage,
      profileCover: !profile?.profileCover ? "" : profile?.profileCover,
      proofPicture: !profile?.proofPicture ? "" : profile?.proofPicture,
      avatar: !profile?.user?.avatar ? "" : profile?.user?.avatar,
      verified: !profile?.user?.verified ? "pending" : profile?.user?.verified,
    
    });
  }, [profile?.user?.email]);

  // Destructurization
  const {
    company,
    website,
    country,
    state,
    phonecode,
    phonenumber,
    bio,
    status,
    speciality,
       email,
       companyphonecode,
    companyphonenumber,
companyaddress,
    skills,
    youtube,
    twitter,
    instagram,
    linkedin,
    facebook,
    name,
    profileImage,
    avatar,
    profileCover,
    proofPicture,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  }


  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   createProfile(formData, history, true);
  // };



  const onGenerateSubmit = (e) => {

e.preventDefault()
    generateAi(animalInput);
  };



  function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
  
      formData.append(parentKey, value);
    }
  }
  
  function jsonToFormData(data) {
    const formData = new FormData();
    
    buildFormData(formData, data);
    
    return formData;
  }


  const onSubmit = () => {
    // const data = new FormData();
    // data.append("profileImage", formData.profileImage);
    // Object.keys(formData).forEach((key) => {
    //   if (key !== "profileImage") {
    //     data.append(key, formData[key]);
    //   }
    // });

    // data.append("profileCover", formData.profileCover);
    // Object.keys(formData).forEach((key) => {
    //   if (key !== "profileCover") {
    //     data.append(key, formData[key]);
    //   }
    // });
    createProfile(jsonToFormData(formData), true, true);
    
  

  };

  return (
    <>
      <Container>
        <Form>
          <Row>

          {/* <Alert variant="alert alert-solid alert-primary d-flex align-items-center gap-2 alert-dismissible fade show mb-3" show={showWaiting} role="alert" onClose={() => setShowWaiting(false)} dismissible>
                              <span><i className="material-symbols-outlined">rotate_right</i></span>
                              <span> Profile updating, please wait...</span>
                           </Alert>
                           <Alert variant="alert alert-solid alert-danger d-flex align-items-center gap-2 alert-dismissible fade show mb-3" className= " mb-3" show={showError} role="alert" onClose={() => setShowError(false)} dismissible>
                              <span><i className="material-symbols-outlined">warning</i></span>
                              <span> There was an error updating your profile!</span>
                           </Alert> */}

          {/* <Alert variant={`alert alert-solid alert-${alert[0]?.alertType} d-flex align-items-center gap-2 alert-dismissible fade show mb-3`} className="mb-3" show={alert[0]?.alertType==="success" ? true : false} role="alert" dismissible>
                              <span><i className="material-symbols-outlined">notifications</i></span>
                              <span> {alert[0]?.msg}</span>
                           </Alert> */}
                               <Tab.Container defaultActiveKey="first">
          <Row>
              <Col lg="12" >
                  <Card>
                      <Card.Body className="p-0">
                          <div>
                              <Nav as="ul" variant="pills" className="iq-edit-profile row">
                                  <Nav.Item as="li" className="col-md-3 p-0">
                                      <Nav.Link  eventKey="first" role="button">
                                          Personal Information
                                      </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item as="li" className="col-md-3 p-0">
                                      <Nav.Link eventKey="second" role="button">
                                          Change Password
                                      </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item as="li" className="col-md-3 p-0">
                                      <Nav.Link  eventKey="third" role="button">
                                          Social Media
                                      </Nav.Link>
                                  </Nav.Item>
                                  {/* <Nav.Item as="li" className="col-md-3 p-0">
                                      <Nav.Link eventKey="fourth" role="button">
                                          Manage Contact
                                      </Nav.Link>
                                  </Nav.Item> */}
                              </Nav>
                          </div>
                      </Card.Body>
                  </Card>
              </Col>
              <Col lg={12}>
                  {/* <div className="iq-edit-list-data"> */}
                      <Tab.Content>
                          <Tab.Pane eventKey="first" className="fade show">
                              <Card>
                                  <Card.Header className="d-flex justify-content-between">
                                      <div className="header-title">
                                          <h4 className="card-title">Personal Information</h4>
                                      </div>
                                  </Card.Header>
                                  <Card.Body>
                                      <Form>
                                          <Form.Group className="form-group align-items-center">  
                                              <Col md="12" style={{textAlign: "center"}}>
                                              <label className="p-image-cover" onClick={(e) =>document?.getElementById('profileCoverInput')?.click()}>
                                                        <i className="ri-pencil-line upload-button text-white" ></i>
                                                        </label>  
                                                        <input className="file-upload"   type="file" id="profileCoverInput"
                        accept=".jpg,.png,.jpeg"
                        name="profileCover"
                        onChange={(e) => {
                          // First function: Update formData
                          setFormData({
                            ...formData,
                            profileCover: e.target.files[0],
                          });
                      
                          const coverContainer = document.getElementById('profileCoverContainer');
                          if (coverContainer) {
                            const reader = new FileReader();
                            reader.onload = (event) => {
                              const blob = new Blob([event.target.result], { type: e.target.files[0].type });
                              const imageUrl = URL.createObjectURL(blob);
                              coverContainer.style.backgroundImage = `url(${imageUrl})`;
                            };
                            reader.readAsArrayBuffer(e.target.files[0]);
                          }
  
  }}
  hidden
                      />
                                                 
                                              <div className="profile-header-image">
                                           
            <div className="cover-container profile-top-container"
            id="profileCoverContainer"
        style={{


          backgroundImage: 
          profileCover
            ? "url(" +
              `${process.env.REACT_APP_BASE_URL + "/" + profileCover}` +
              ")"
            : "url(" + img1 + ")",
        }}>
        
            </div>
         
            </div>
            <div className="profile-img-edit">
                                                    <img className="profile-pic"  src={
                          profileImage
                            ? `${
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                profileImage
                              }`
                            : avatar
                        } alt="profile-pic" id="profileImageContainer"/>
                                                    <label className="p-image-profile" onClick={(e) => document?.getElementById('profileImageInput')?.click()}>
                                                        <i className="ri-pencil-line upload-button text-white" ></i>
                                                        </label>
                                                        <input className="file-upload" id="profileImageInput" type="file" accept=".jpg,.png,.jpeg" name="profileImage"  onChange={(e) => {
    // First function: Update formData
    setFormData({
      ...formData,
      profileImage: e.target.files[0],
    });

    // Second function: Call your additional function
    const imageContainer = document.getElementById('profileImageContainer');
    if (imageContainer) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const blob = new Blob([event.target.result], { type: e.target.files[0].type });
        imageContainer.src = URL.createObjectURL(blob);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  
  }}
  hidden
/>



                                          
                                                  </div>
                                                 




                                              
                                              </Col>
                                          </Form.Group>
                                          <Row className="align-items-center">
                                        
              <Card>
                <Card.Header className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Profile Settings</h4>
                   
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="acc-edit">


                  {profile?.user?.rejectionComment !== "none" && <h4 style={{color:"red"}}>{profile?.user?.rejectionComment}</h4>}


                  <Form.Group className="form-group">
                      <Form.Label htmlFor="profileImage" className="form-label">
                        Proof of Identity/Profession :
                      </Form.Label>

                      <Form.Control
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        name="proofPicture"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            proofPicture: e.target.files[0],
                          })
                        }
                      />
                    </Form.Group>



 


                    {/* <Form.Group>
                      <Form.Label htmlFor="profileCover">
                        Profile Cover
                      </Form.Label>

                      <Form.Control
                        type="file"
                        accept=".jpg,.png,.jpeg"
                        name="profileCover"
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            profileCover: e.target.files[0],
                          })
                        }
                      />
                    </Form.Group> */}
                 
      <Form.Group className="form-group">
                      <Form.Label htmlFor="name" className="form-label">
                        Full Name :
                      </Form.Label>
                      <Form.Control
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        name="name"
                        value={name}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>
                    


                    <Form.Group className="form-group">
                      <Form.Label htmlFor="email" className="form-label">
                        Email :
                      </Form.Label>
                      <Form.Control
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="email"
                        value={email}
                        // onChange={(e) => onChange(e)}
                        disabled
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
      <Form.Label htmlFor="country">
      Country :
                      </Form.Label>
                      <div>
                      <small className="form-text">Select where you are from</small>
                      </div>
        
        <Form.Control as="select" name="country" value={country} onChange={(e) => onChange(e)}>
        <option value="0">* Select Country</option>
        <option value="Afghanistan">Afghanistan</option>
    <option value="Aland Islands">Aland Islands</option>
    <option value="Albania">Albania</option>
    <option value="Algeria">Algeria</option>
    <option value="American Samoa">American Samoa</option>
    <option value="Andorra">Andorra</option>
    <option value="Angola">Angola</option>
    <option value="Anguilla">Anguilla</option>
    <option value="Antarctica">Antarctica</option>
    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
    <option value="Argentina">Argentina</option>
    <option value="Armenia">Armenia</option>
    <option value="Aruba">Aruba</option>
    <option value="Australia">Australia</option>
    <option value="Austria">Austria</option>
    <option value="Azerbaijan">Azerbaijan</option>
    <option value="Bahamas">Bahamas</option>
    <option value="Bahrain">Bahrain</option>
    <option value="Bangladesh">Bangladesh</option>
    <option value="Barbados">Barbados</option>
    <option value="Belarus">Belarus</option>
    <option value="Belgium">Belgium</option>
    <option value="Belize">Belize</option>
    <option value="Benin">Benin</option>
    <option value="Bermuda">Bermuda</option>
    <option value="Bhutan">Bhutan</option>
    <option value="Bolivia">Bolivia</option>
    <option value="Bonaire, Sint Eustatius and Saba">Bonaire, Sint Eustatius and Saba</option>
    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
    <option value="Botswana">Botswana</option>
    <option value="Bouvet Island">Bouvet Island</option>
    <option value="Brazil">Brazil</option>
    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
    <option value="Brunei Darussalam">Brunei Darussalam</option>
    <option value="Bulgaria">Bulgaria</option>
    <option value="Burkina Faso">Burkina Faso</option>
    <option value="Burundi">Burundi</option>
    <option value="Cambodia">Cambodia</option>
    <option value="Cameroon">Cameroon</option>
    <option value="Canada">Canada</option>
    <option value="Cape Verde">Cape Verde</option>
    <option value="Cayman Islands">Cayman Islands</option>
    <option value="Central African Republic">Central African Republic</option>
    <option value="Chad">Chad</option>
    <option value="Chile">Chile</option>
    <option value="China">China</option>
    <option value="Christmas Island">Christmas Island</option>
    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
    <option value="Colombia">Colombia</option>
    <option value="Comoros">Comoros</option>
    <option value="Congo">Congo</option>
    <option value="Congo, Democratic Republic of the Congo">Congo, Democratic Republic of the Congo</option>
    <option value="Cook Islands">Cook Islands</option>
    <option value="Costa Rica">Costa Rica</option>
    <option value="Cote D'Ivoire">Cote D'Ivoire</option>
    <option value="Croatia">Croatia</option>
    <option value="Cuba">Cuba</option>
    <option value="Curacao">Curacao</option>
    <option value="Cyprus">Cyprus</option>
    <option value="Czech Republic">Czech Republic</option>
    <option value="Denmark">Denmark</option>
    <option value="Djibouti">Djibouti</option>
    <option value="Dominica">Dominica</option>
    <option value="Dominican Republic">Dominican Republic</option>
    <option value="Ecuador">Ecuador</option>
    <option value="Egypt">Egypt</option>
    <option value="El Salvador">El Salvador</option>
    <option value="Equatorial Guinea">Equatorial Guinea</option>
    <option value="Eritrea">Eritrea</option>
    <option value="Estonia">Estonia</option>
    <option value="Ethiopia">Ethiopia</option>
    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
    <option value="Faroe Islands">Faroe Islands</option>
    <option value="Fiji">Fiji</option>
    <option value="Finland">Finland</option>
    <option value="France">France</option>
    <option value="French Guiana">French Guiana</option>
    <option value="French Polynesia">French Polynesia</option>
    <option value="French Southern Territories">French Southern Territories</option>
    <option value="Gabon">Gabon</option>
    <option value="Gambia">Gambia</option>
    <option value="Georgia">Georgia</option>
    <option value="Germany">Germany</option>
    <option value="Ghana">Ghana</option>
    <option value="Gibraltar">Gibraltar</option>
    <option value="Greece">Greece</option>
    <option value="Greenland">Greenland</option>
    <option value="Grenada">Grenada</option>
    <option value="Guadeloupe">Guadeloupe</option>
    <option value="Guam">Guam</option>
    <option value="Guatemala">Guatemala</option>
    <option value="Guernsey">Guernsey</option>
    <option value="Guinea">Guinea</option>
    <option value="Guinea-Bissau">Guinea-Bissau</option>
    <option value="Guyana">Guyana</option>
    <option value="Haiti">Haiti</option>
    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
    <option value="Honduras">Honduras</option>
    <option value="Hong Kong">Hong Kong</option>
    <option value="Hungary">Hungary</option>
    <option value="Iceland">Iceland</option>
    <option value="India">India</option>
    <option value="Indonesia">Indonesia</option>
    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
    <option value="Iraq">Iraq</option>
    <option value="Ireland">Ireland</option>
    <option value="Isle of Man">Isle of Man</option>
    <option value="Israel">Israel</option>
    <option value="Italy">Italy</option>
    <option value="Jamaica">Jamaica</option>
    <option value="Japan">Japan</option>
    <option value="Jersey">Jersey</option>
    <option value="Jordan">Jordan</option>
    <option value="Kazakhstan">Kazakhstan</option>
    <option value="Kenya">Kenya</option>
    <option value="Kiribati">Kiribati</option>
    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
    <option value="Korea, Republic of">Korea, Republic of</option>
    <option value="Kosovo">Kosovo</option>
    <option value="Kuwait">Kuwait</option>
    <option value="Kyrgyzstan">Kyrgyzstan</option>
    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
    <option value="Latvia">Latvia</option>
    <option value="Lebanon">Lebanon</option>
    <option value="Lesotho">Lesotho</option>
    <option value="Liberia">Liberia</option>
    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
    <option value="Liechtenstein">Liechtenstein</option>
    <option value="Lithuania">Lithuania</option>
    <option value="Luxembourg">Luxembourg</option>
    <option value="Macao">Macao</option>
    <option value="Macedonia, the Former Yugoslav Republic of">Macedonia, the Former Yugoslav Republic of</option>
    <option value="Madagascar">Madagascar</option>
    <option value="Malawi">Malawi</option>
    <option value="Malaysia">Malaysia</option>
    <option value="Maldives">Maldives</option>
    <option value="Mali">Mali</option>
    <option value="Malta">Malta</option>
    <option value="Marshall Islands">Marshall Islands</option>
    <option value="Martinique">Martinique</option>
    <option value="Mauritania">Mauritania</option>
    <option value="Mauritius">Mauritius</option>
    <option value="Mayotte">Mayotte</option>
    <option value="Mexico">Mexico</option>
    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
    <option value="Moldova, Republic of">Moldova, Republic of</option>
    <option value="Monaco">Monaco</option>
    <option value="Mongolia">Mongolia</option>
    <option value="Montenegro">Montenegro</option>
    <option value="Montserrat">Montserrat</option>
    <option value="Morocco">Morocco</option>
    <option value="Mozambique">Mozambique</option>
    <option value="Myanmar">Myanmar</option>
    <option value="Namibia">Namibia</option>
    <option value="Nauru">Nauru</option>
    <option value="Nepal">Nepal</option>
    <option value="Netherlands">Netherlands</option>
    <option value="Netherlands Antilles">Netherlands Antilles</option>
    <option value="New Caledonia">New Caledonia</option>
    <option value="New Zealand">New Zealand</option>
    <option value="Nicaragua">Nicaragua</option>
    <option value="Niger">Niger</option>
    <option value="Nigeria">Nigeria</option>
    <option value="Niue">Niue</option>
    <option value="Norfolk Island">Norfolk Island</option>
    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
    <option value="Norway">Norway</option>
    <option value="Oman">Oman</option>
    <option value="Pakistan">Pakistan</option>
    <option value="Palau">Palau</option>
    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
    <option value="Panama">Panama</option>
    <option value="Papua New Guinea">Papua New Guinea</option>
    <option value="Paraguay">Paraguay</option>
    <option value="Peru">Peru</option>
    <option value="Philippines">Philippines</option>
    <option value="Pitcairn">Pitcairn</option>
    <option value="Poland">Poland</option>
    <option value="Portugal">Portugal</option>
    <option value="Puerto Rico">Puerto Rico</option>
    <option value="Qatar">Qatar</option>
    <option value="Reunion">Reunion</option>
    <option value="Romania">Romania</option>
    <option value="Russian Federation">Russian Federation</option>
    <option value="Rwanda">Rwanda</option>
    <option value="Saint Barthelemy">Saint Barthelemy</option>
    <option value="Saint Helena">Saint Helena</option>
    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
    <option value="Saint Lucia">Saint Lucia</option>
    <option value="Saint Martin">Saint Martin</option>
    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
    <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
    <option value="Samoa">Samoa</option>
    <option value="San Marino">San Marino</option>
    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
    <option value="Saudi Arabia">Saudi Arabia</option>
    <option value="Senegal">Senegal</option>
    <option value="Serbia">Serbia</option>
    <option value="Serbia and Montenegro">Serbia and Montenegro</option>
    <option value="Seychelles">Seychelles</option>
    <option value="Sierra Leone">Sierra Leone</option>
    <option value="Singapore">Singapore</option>
    <option value="Sint Maarten">Sint Maarten</option>
    <option value="Slovakia">Slovakia</option>
    <option value="Slovenia">Slovenia</option>
    <option value="Solomon Islands">Solomon Islands</option>
    <option value="Somalia">Somalia</option>
    <option value="South Africa">South Africa</option>
    <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
    <option value="South Sudan">South Sudan</option>
    <option value="Spain">Spain</option>
    <option value="Sri Lanka">Sri Lanka</option>
    <option value="Sudan">Sudan</option>
    <option value="Suriname">Suriname</option>
    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
    <option value="Swaziland">Swaziland</option>
    <option value="Sweden">Sweden</option>
    <option value="Switzerland">Switzerland</option>
    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
    <option value="Taiwan, Province of China">Taiwan, Province of China</option>
    <option value="Tajikistan">Tajikistan</option>
    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
    <option value="Thailand">Thailand</option>
    <option value="Timor-Leste">Timor-Leste</option>
    <option value="Togo">Togo</option>
    <option value="Tokelau">Tokelau</option>
    <option value="Tonga">Tonga</option>
    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
    <option value="Tunisia">Tunisia</option>
    <option value="Turkey">Turkey</option>
    <option value="Turkmenistan">Turkmenistan</option>
    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
    <option value="Tuvalu">Tuvalu</option>
    <option value="Uganda">Uganda</option>
    <option value="Ukraine">Ukraine</option>
    <option value="United Arab Emirates">United Arab Emirates</option>
    <option value="United Kingdom">United Kingdom</option>
    <option value="United States">United States</option>
    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
    <option value="Uruguay">Uruguay</option>
    <option value="Uzbekistan">Uzbekistan</option>
    <option value="Vanuatu">Vanuatu</option>
    <option value="Venezuela">Venezuela</option>
    <option value="Viet Nam">Viet Nam</option>
    <option value="Virgin Islands, British">Virgin Islands, British</option>
    <option value="Virgin Islands, U.s.">Virgin Islands, U.s.</option>
    <option value="Wallis and Futuna">Wallis and Futuna</option>
    <option value="Western Sahara">Western Sahara</option>
    <option value="Yemen">Yemen</option>
    <option value="Zambia">Zambia</option>
    <option value="Zimbabwe">Zimbabwe</option>
        </Form.Control>
      </Form.Group>



                    <Form.Group className="form-group">
                      <Form.Label htmlFor="state" className="form-label">
                        State :
                      </Form.Label>
                      <div>
                      <small className="form-text">
            City & state suggested (eg. Monastir, Sousse)
          </small>
                      </div>
                      <Form.Control
                        type="text"
                        placeholder="State"
                        name="state"
                        value={state}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>


                    <Form.Label htmlFor="phonecode">
                    Phone Number :
                      </Form.Label>

<div id="phoneProfileContainer">
<Form.Group className="form-group">
               
<div>
<small className="form-text">Select your phone code</small>
</div>
       
        <Form.Control as="select" name="phonecode" value={phonecode} onChange={(e) => onChange(e)}>
        <option value="0">* Phone Code</option>
        <option value="93">Afghanistan +93</option>
    <option value="358">Aland Islands +358</option>
    <option value="355">Albania +355</option>
    <option value="213">Algeria +213</option>
    <option value="1684">American Samoa +1684</option>
    <option value="376">Andorra +376</option>
    <option value="244">Angola +244</option>
    <option value="1264">Anguilla +1264</option>
    <option value="672">Antarctica +672</option>
    <option value="1268">Antigua and Barbuda +1268</option>
    <option value="54">Argentina +54</option>
    <option value="374">Armenia +374</option>
    <option value="297">Aruba +297</option>
    <option value="61">Australia +61</option>
    <option value="43">Austria +43</option>
    <option value="994">Azerbaijan +994</option>
    <option value="1242">Bahamas +1242</option>
    <option value="973">Bahrain +973</option>
    <option value="880">Bangladesh +880</option>
    <option value="1246">Barbados +1246</option>
    <option value="375">Belarus +375</option>
    <option value="32">Belgium +32</option>
    <option value="501">Belize +501</option>
    <option value="229">Benin +229</option>
    <option value="1441">Bermuda +1441</option>
    <option value="975">Bhutan +975</option>
    <option value="591">Bolivia +591</option>
    <option value="599">Bonaire, Sint Eustatius and Saba +599</option>
    <option value="387">Bosnia and Herzegovina +387</option>
    <option value="267">Botswana +267</option>
    <option value="55">Bouvet Island +55</option>
    <option value="55">Brazil +55</option>
    <option value="246">British Indian Ocean Territory +246</option>
    <option value="673">Brunei Darussalam +673</option>
    <option value="359">Bulgaria +359</option>
    <option value="226">Burkina Faso +226</option>
    <option value="257">Burundi +257</option>
    <option value="855">Cambodia +855</option>
    <option value="237">Cameroon +237</option>
    <option value="1">Canada +1</option>
    <option value="238">Cape Verde +238</option>
    <option value="1345">Cayman Islands +1345</option>
    <option value="236">Central African Republic +236</option>
    <option value="235">Chad +235</option>
    <option value="56">Chile +56</option>
    <option value="86">China +86</option>
    <option value="61">Christmas Island +61</option>
    <option value="672">Cocos (Keeling) Islands +672</option>
    <option value="57">Colombia +57</option>
    <option value="269">Comoros +269</option>
    <option value="242">Congo +242</option>
    <option value="242">Congo, Democratic Republic of the Congo +242</option>
    <option value="682">Cook Islands +682</option>
    <option value="506">Costa Rica +506</option>
    <option value="225">Cote D'Ivoire +225</option>
    <option value="385">Croatia +385</option>
    <option value="53">Cuba +53</option>
    <option value="599">Curacao +599</option>
    <option value="357">Cyprus +357</option>
    <option value="420">Czech Republic +420</option>
    <option value="45">Denmark +45</option>
    <option value="253">Djibouti +253</option>
    <option value="1767">Dominica +1767</option>
    <option value="1809">Dominican Republic +1809</option>
    <option value="593">Ecuador +593</option>
    <option value="20">Egypt +20</option>
    <option value="503">El Salvador +503</option>
    <option value="240">Equatorial Guinea +240</option>
    <option value="291">Eritrea +291</option>
    <option value="372">Estonia +372</option>
    <option value="251">Ethiopia +251</option>
    <option value="500">Falkland Islands (Malvinas) +500</option>
    <option value="298">Faroe Islands +298</option>
    <option value="679">Fiji +679</option>
    <option value="358">Finland +358</option>
    <option value="33">France +33</option>
    <option value="594">French Guiana +594</option>
    <option value="689">French Polynesia +689</option>
    <option value="262">French Southern Territories +262</option>
    <option value="241">Gabon +241</option>
    <option value="220">Gambia +220</option>
    <option value="995">Georgia +995</option>
    <option value="49">Germany +49</option>
    <option value="233">Ghana +233</option>
    <option value="350">Gibraltar +350</option>
    <option value="30">Greece +30</option>
    <option value="299">Greenland +299</option>
    <option value="1473">Grenada +1473</option>
    <option value="590">Guadeloupe +590</option>
    <option value="1671">Guam +1671</option>
    <option value="502">Guatemala +502</option>
    <option value="44">Guernsey +44</option>
    <option value="224">Guinea +224</option>
    <option value="245">Guinea-Bissau +245</option>
    <option value="592">Guyana +592</option>
    <option value="509">Haiti +509</option>
    <option value="39">Holy See (Vatican City State) +39</option>
    <option value="504">Honduras +504</option>
    <option value="852">Hong Kong +852</option>
    <option value="36">Hungary +36</option>
    <option value="354">Iceland +354</option>
    <option value="91">India +91</option>
    <option value="62">Indonesia +62</option>
    <option value="98">Iran, Islamic Republic of +98</option>
    <option value="964">Iraq +964</option>
    <option value="353">Ireland +353</option>
    <option value="44">Isle of Man +44</option>
    <option value="972">Israel +972</option>
    <option value="39">Italy +39</option>
    <option value="1876">Jamaica +1876</option>
    <option value="81">Japan +81</option>
    <option value="44">Jersey +44</option>
    <option value="962">Jordan +962</option>
    <option value="7">Kazakhstan +7</option>
    <option value="254">Kenya +254</option>
    <option value="686">Kiribati +686</option>
    <option value="850">Korea, Democratic People's Republic of +850</option>
    <option value="82">Korea, Republic of +82</option>
    <option value="383">Kosovo +383</option>
    <option value="965">Kuwait +965</option>
    <option value="996">Kyrgyzstan +996</option>
    <option value="856">Lao People's Democratic Republic +856</option>
    <option value="371">Latvia +371</option>
    <option value="961">Lebanon +961</option>
    <option value="266">Lesotho +266</option>
    <option value="231">Liberia +231</option>
    <option value="218">Libyan Arab Jamahiriya +218</option>
    <option value="423">Liechtenstein +423</option>
    <option value="370">Lithuania +370</option>
    <option value="352">Luxembourg +352</option>
    <option value="853">Macao +853</option>
    <option value="389">Macedonia, the Former Yugoslav Republic of +389</option>
    <option value="261">Madagascar +261</option>
    <option value="265">Malawi +265</option>
    <option value="60">Malaysia +60</option>
    <option value="960">Maldives +960</option>
    <option value="223">Mali +223</option>
    <option value="356">Malta +356</option>
    <option value="692">Marshall Islands +692</option>
    <option value="596">Martinique +596</option>
    <option value="222">Mauritania +222</option>
    <option value="230">Mauritius +230</option>
    <option value="262">Mayotte +262</option>
    <option value="52">Mexico +52</option>
    <option value="691">Micronesia, Federated States of +691</option>
    <option value="373">Moldova, Republic of +373</option>
    <option value="377">Monaco +377</option>
    <option value="976">Mongolia +976</option>
    <option value="382">Montenegro +382</option>
    <option value="1664">Montserrat +1664</option>
    <option value="212">Morocco +212</option>
    <option value="258">Mozambique +258</option>
    <option value="95">Myanmar +95</option>
    <option value="264">Namibia +264</option>
    <option value="674">Nauru +674</option>
    <option value="977">Nepal +977</option>
    <option value="31">Netherlands +31</option>
    <option value="599">Netherlands Antilles +599</option>
    <option value="687">New Caledonia +687</option>
    <option value="64">New Zealand +64</option>
    <option value="505">Nicaragua +505</option>
    <option value="227">Niger +227</option>
    <option value="234">Nigeria +234</option>
    <option value="683">Niue +683</option>
    <option value="672">Norfolk Island +672</option>
    <option value="1670">Northern Mariana Islands +1670</option>
    <option value="47">Norway +47</option>
    <option value="968">Oman +968</option>
    <option value="92">Pakistan +92</option>
    <option value="680">Palau +680</option>
    <option value="970">Palestinian Territory, Occupied +970</option>
    <option value="507">Panama +507</option>
    <option value="675">Papua New Guinea +675</option>
    <option value="595">Paraguay +595</option>
    <option value="51">Peru +51</option>
    <option value="63">Philippines +63</option>
    <option value="64">Pitcairn +64</option>
    <option value="48">Poland +48</option>
    <option value="351">Portugal +351</option>
    <option value="1787">Puerto Rico +1787</option>
    <option value="974">Qatar +974</option>
    <option value="262">Reunion +262</option>
    <option value="40">Romania +40</option>
    <option value="7">Russian Federation +7</option>
    <option value="250">Rwanda +250</option>
    <option value="590">Saint Barthelemy +590</option>
    <option value="290">Saint Helena +290</option>
    <option value="1869">Saint Kitts and Nevis +1869</option>
    <option value="1758">Saint Lucia +1758</option>
    <option value="590">Saint Martin +590</option>
    <option value="508">Saint Pierre and Miquelon +508</option>
    <option value="1784">Saint Vincent and the Grenadines +1784</option>
    <option value="684">Samoa +684</option>
    <option value="378">San Marino +378</option>
    <option value="239">Sao Tome and Principe +239</option>
    <option value="966">Saudi Arabia +966</option>
    <option value="221">Senegal +221</option>
    <option value="381">Serbia +381</option>
    <option value="381">Serbia and Montenegro +381</option>
    <option value="248">Seychelles +248</option>
    <option value="232">Sierra Leone +232</option>
    <option value="65">Singapore +65</option>
    <option value="721">Sint Maarten +721</option>
    <option value="421">Slovakia +421</option>
    <option value="386">Slovenia +386</option>
    <option value="677">Solomon Islands +677</option>
    <option value="252">Somalia +252</option>
    <option value="27">South Africa +27</option>
    <option value="500">South Georgia and the South Sandwich Islands +500</option>
    <option value="211">South Sudan +211</option>
    <option value="34">Spain +34</option>
    <option value="94">Sri Lanka +94</option>
    <option value="249">Sudan +249</option>
    <option value="597">Suriname +597</option>
    <option value="47">Svalbard and Jan Mayen +47</option>
    <option value="268">Swaziland +268</option>
    <option value="46">Sweden +46</option>
    <option value="41">Switzerland +41</option>
    <option value="963">Syrian Arab Republic +963</option>
    <option value="886">Taiwan, Province of China +886</option>
    <option value="992">Tajikistan +992</option>
    <option value="255">Tanzania, United Republic of +255</option>
    <option value="66">Thailand +66</option>
    <option value="670">Timor-Leste +670</option>
    <option value="228">Togo +228</option>
    <option value="690">Tokelau +690</option>
    <option value="676">Tonga +676</option>
    <option value="1868">Trinidad and Tobago +1868</option>
    <option value="216">Tunisia +216</option>
    <option value="90">Turkey +90</option>
    <option value="7370">Turkmenistan +7370</option>
    <option value="1649">Turks and Caicos Islands +1649</option>
    <option value="688">Tuvalu +688</option>
    <option value="256">Uganda +256</option>
    <option value="380">Ukraine +380</option>
    <option value="971">United Arab Emirates +971</option>
    <option value="44">United Kingdom +44</option>
    <option value="1">United States +1</option>
    <option value="1">United States Minor Outlying Islands +1</option>
    <option value="598">Uruguay +598</option>
    <option value="998">Uzbekistan +998</option>
    <option value="678">Vanuatu +678</option>
    <option value="58">Venezuela +58</option>
    <option value="84">Viet Nam +84</option>
    <option value="1284">Virgin Islands, British +1284</option>
    <option value="1340">Virgin Islands, U.s. +1340</option>
    <option value="681">Wallis and Futuna +681</option>
    <option value="212">Western Sahara +212</option>
    <option value="967">Yemen +967</option>
    <option value="260">Zambia +260</option>
    <option value="263">Zimbabwe +263</option>
        </Form.Control>
      </Form.Group>









      <Form.Group className="form-group">
                 
                      <div>
                      <small className="form-text">
            Type in your phone number
          </small>
                      </div>
                      <Form.Control
                        type="tel"
                        placeholder="Phone Number"
                        name="phonenumber"
                        value={phonenumber}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>


             
</div>


             
                    <Form.Group className="form-group">
                    <Form.Label htmlFor="professionalPosition">
                    Professional Position :
                      </Form.Label>
<div>
<small className="form-text">Give us an idea of where you are at in your career</small>
</div>
       
        <Form.Control as="select" name="status" value={status} onChange={(e) => onChange(e)}>
        <option value="0">* Select Professional Position</option>
            <option value="Professor">Professor</option>
            <option value="Associate professor">Associate professor</option>
            <option value="Resident">Resident</option>
            <option value="Intern">Intern</option>
            <option value="Dental Clinic Owner">Dental Clinic Owner</option>
            <option value="Dentist in public sector">Dentist in public sector</option>
            <option value="Dental Student">Dental Student</option>
            
        </Form.Control>
      </Form.Group>

      <Form.Group className="form-group">
      <Form.Label htmlFor="speciality">
      Professional Speciality :
                      </Form.Label>
                      <div>
                      <small className="form-text">Give us an idea of your professional specialty</small>
                      </div>
        
        <Form.Control as="select" name="speciality" value={speciality} onChange={(e) => onChange(e)}>
        <option value="0">* Select Professional Speciality</option>
        <option value="Endodontics">Endodontics</option>
<option value="Periodontics">Periodontics</option>
<option value="Orthodontics">Orthodontics</option>
<option value="Implantology">Implantology</option>
<option value="Aesthetic">Aesthetic</option>
<option value="General Dentist">General Dentist</option>

          {/* Add other options */}
        </Form.Control>
      </Form.Group>
                 




                  







                    <Form.Group className="form-group">
                      <Form.Label htmlFor="skills" className="form-label">
                        Skills :
                      </Form.Label>
                      <div>
                      <small className="form-text">
            Please use comma separated values (eg. skill1,skill2,skill3,skill4)
          </small>
                      </div>
                      <Form.Control
                        type="text"
                        placeholder="Skills"
                        name="skills"
                        value={skills}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>
                    <Form.Group className="form-group">
                    
                      <Form.Label htmlFor="bio" className="form-label">
                        Bio :
                      </Form.Label>
                      <div>
                      <small className="form-text">Tell us a little about yourself</small>
                      </div>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Tell us something about yourself..."
                        name="bio"
                        value={bio}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>

                   

                   




                    <Form.Label>Do you have your own dental clinic?</Form.Label>
                        <Form.Group controlId="getHasOwnCompany" className="mb-3">
                          <div id="hasOwnCompanyContainer">
                            <Form.Check
                              value="yesHasOwnCompany"
                              type="radio"
                              aria-label="yesHasOwnCompany"
                              label="Yes"
                              onChange={handleChangeHasOwnCompany}
                              checked={getHasOwnCompany === "yesHasOwnCompany"}
                            />
                            <Form.Check
                              value="nonHasOwnCompany"
                              type="radio"
                              aria-label="nonHasOwnCompany"
                              label="No"
                              onChange={handleChangeHasOwnCompany}
                              checked={getHasOwnCompany === "nonHasOwnCompany"}
                            />
                      
                          </div>
                        </Form.Group>


{(getHasOwnCompany === "yesHasOwnCompany") &&    

<div>
<Form.Group className="form-group">
                      <Form.Label htmlFor="company" className="form-label">
                        Company :
                      </Form.Label>
                      <div>
                      <small className="form-text">
            Could be your own company or one you work for
          </small>
                      </div>
                  
                      <Form.Control
                        type="text"
                        placeholder="Company"
                        name="company"
                        value={company}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>





<Form.Group className="form-group">
                      <Form.Label htmlFor="companyaddress" className="form-label">
                        Address :
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Your Company's Address"
                        name="companyaddress"
                        value={companyaddress}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>




                    <div id="phoneProfileContainer">
<Form.Group className="form-group">
               
<div>
<small className="form-text">Select phone code</small>
</div>
       
        <Form.Control as="select" name="companyphonecode" value={companyphonecode} onChange={(e) => onChange(e)}>
        <option value="0">* Phone Code</option>
        <option value="93">Afghanistan +93</option>
    <option value="358">Aland Islands +358</option>
    <option value="355">Albania +355</option>
    <option value="213">Algeria +213</option>
    <option value="1684">American Samoa +1684</option>
    <option value="376">Andorra +376</option>
    <option value="244">Angola +244</option>
    <option value="1264">Anguilla +1264</option>
    <option value="672">Antarctica +672</option>
    <option value="1268">Antigua and Barbuda +1268</option>
    <option value="54">Argentina +54</option>
    <option value="374">Armenia +374</option>
    <option value="297">Aruba +297</option>
    <option value="61">Australia +61</option>
    <option value="43">Austria +43</option>
    <option value="994">Azerbaijan +994</option>
    <option value="1242">Bahamas +1242</option>
    <option value="973">Bahrain +973</option>
    <option value="880">Bangladesh +880</option>
    <option value="1246">Barbados +1246</option>
    <option value="375">Belarus +375</option>
    <option value="32">Belgium +32</option>
    <option value="501">Belize +501</option>
    <option value="229">Benin +229</option>
    <option value="1441">Bermuda +1441</option>
    <option value="975">Bhutan +975</option>
    <option value="591">Bolivia +591</option>
    <option value="599">Bonaire, Sint Eustatius and Saba +599</option>
    <option value="387">Bosnia and Herzegovina +387</option>
    <option value="267">Botswana +267</option>
    <option value="55">Bouvet Island +55</option>
    <option value="55">Brazil +55</option>
    <option value="246">British Indian Ocean Territory +246</option>
    <option value="673">Brunei Darussalam +673</option>
    <option value="359">Bulgaria +359</option>
    <option value="226">Burkina Faso +226</option>
    <option value="257">Burundi +257</option>
    <option value="855">Cambodia +855</option>
    <option value="237">Cameroon +237</option>
    <option value="1">Canada +1</option>
    <option value="238">Cape Verde +238</option>
    <option value="1345">Cayman Islands +1345</option>
    <option value="236">Central African Republic +236</option>
    <option value="235">Chad +235</option>
    <option value="56">Chile +56</option>
    <option value="86">China +86</option>
    <option value="61">Christmas Island +61</option>
    <option value="672">Cocos (Keeling) Islands +672</option>
    <option value="57">Colombia +57</option>
    <option value="269">Comoros +269</option>
    <option value="242">Congo +242</option>
    <option value="242">Congo, Democratic Republic of the Congo +242</option>
    <option value="682">Cook Islands +682</option>
    <option value="506">Costa Rica +506</option>
    <option value="225">Cote D'Ivoire +225</option>
    <option value="385">Croatia +385</option>
    <option value="53">Cuba +53</option>
    <option value="599">Curacao +599</option>
    <option value="357">Cyprus +357</option>
    <option value="420">Czech Republic +420</option>
    <option value="45">Denmark +45</option>
    <option value="253">Djibouti +253</option>
    <option value="1767">Dominica +1767</option>
    <option value="1809">Dominican Republic +1809</option>
    <option value="593">Ecuador +593</option>
    <option value="20">Egypt +20</option>
    <option value="503">El Salvador +503</option>
    <option value="240">Equatorial Guinea +240</option>
    <option value="291">Eritrea +291</option>
    <option value="372">Estonia +372</option>
    <option value="251">Ethiopia +251</option>
    <option value="500">Falkland Islands (Malvinas) +500</option>
    <option value="298">Faroe Islands +298</option>
    <option value="679">Fiji +679</option>
    <option value="358">Finland +358</option>
    <option value="33">France +33</option>
    <option value="594">French Guiana +594</option>
    <option value="689">French Polynesia +689</option>
    <option value="262">French Southern Territories +262</option>
    <option value="241">Gabon +241</option>
    <option value="220">Gambia +220</option>
    <option value="995">Georgia +995</option>
    <option value="49">Germany +49</option>
    <option value="233">Ghana +233</option>
    <option value="350">Gibraltar +350</option>
    <option value="30">Greece +30</option>
    <option value="299">Greenland +299</option>
    <option value="1473">Grenada +1473</option>
    <option value="590">Guadeloupe +590</option>
    <option value="1671">Guam +1671</option>
    <option value="502">Guatemala +502</option>
    <option value="44">Guernsey +44</option>
    <option value="224">Guinea +224</option>
    <option value="245">Guinea-Bissau +245</option>
    <option value="592">Guyana +592</option>
    <option value="509">Haiti +509</option>
    <option value="39">Holy See (Vatican City State) +39</option>
    <option value="504">Honduras +504</option>
    <option value="852">Hong Kong +852</option>
    <option value="36">Hungary +36</option>
    <option value="354">Iceland +354</option>
    <option value="91">India +91</option>
    <option value="62">Indonesia +62</option>
    <option value="98">Iran, Islamic Republic of +98</option>
    <option value="964">Iraq +964</option>
    <option value="353">Ireland +353</option>
    <option value="44">Isle of Man +44</option>
    <option value="972">Israel +972</option>
    <option value="39">Italy +39</option>
    <option value="1876">Jamaica +1876</option>
    <option value="81">Japan +81</option>
    <option value="44">Jersey +44</option>
    <option value="962">Jordan +962</option>
    <option value="7">Kazakhstan +7</option>
    <option value="254">Kenya +254</option>
    <option value="686">Kiribati +686</option>
    <option value="850">Korea, Democratic People's Republic of +850</option>
    <option value="82">Korea, Republic of +82</option>
    <option value="383">Kosovo +383</option>
    <option value="965">Kuwait +965</option>
    <option value="996">Kyrgyzstan +996</option>
    <option value="856">Lao People's Democratic Republic +856</option>
    <option value="371">Latvia +371</option>
    <option value="961">Lebanon +961</option>
    <option value="266">Lesotho +266</option>
    <option value="231">Liberia +231</option>
    <option value="218">Libyan Arab Jamahiriya +218</option>
    <option value="423">Liechtenstein +423</option>
    <option value="370">Lithuania +370</option>
    <option value="352">Luxembourg +352</option>
    <option value="853">Macao +853</option>
    <option value="389">Macedonia, the Former Yugoslav Republic of +389</option>
    <option value="261">Madagascar +261</option>
    <option value="265">Malawi +265</option>
    <option value="60">Malaysia +60</option>
    <option value="960">Maldives +960</option>
    <option value="223">Mali +223</option>
    <option value="356">Malta +356</option>
    <option value="692">Marshall Islands +692</option>
    <option value="596">Martinique +596</option>
    <option value="222">Mauritania +222</option>
    <option value="230">Mauritius +230</option>
    <option value="262">Mayotte +262</option>
    <option value="52">Mexico +52</option>
    <option value="691">Micronesia, Federated States of +691</option>
    <option value="373">Moldova, Republic of +373</option>
    <option value="377">Monaco +377</option>
    <option value="976">Mongolia +976</option>
    <option value="382">Montenegro +382</option>
    <option value="1664">Montserrat +1664</option>
    <option value="212">Morocco +212</option>
    <option value="258">Mozambique +258</option>
    <option value="95">Myanmar +95</option>
    <option value="264">Namibia +264</option>
    <option value="674">Nauru +674</option>
    <option value="977">Nepal +977</option>
    <option value="31">Netherlands +31</option>
    <option value="599">Netherlands Antilles +599</option>
    <option value="687">New Caledonia +687</option>
    <option value="64">New Zealand +64</option>
    <option value="505">Nicaragua +505</option>
    <option value="227">Niger +227</option>
    <option value="234">Nigeria +234</option>
    <option value="683">Niue +683</option>
    <option value="672">Norfolk Island +672</option>
    <option value="1670">Northern Mariana Islands +1670</option>
    <option value="47">Norway +47</option>
    <option value="968">Oman +968</option>
    <option value="92">Pakistan +92</option>
    <option value="680">Palau +680</option>
    <option value="970">Palestinian Territory, Occupied +970</option>
    <option value="507">Panama +507</option>
    <option value="675">Papua New Guinea +675</option>
    <option value="595">Paraguay +595</option>
    <option value="51">Peru +51</option>
    <option value="63">Philippines +63</option>
    <option value="64">Pitcairn +64</option>
    <option value="48">Poland +48</option>
    <option value="351">Portugal +351</option>
    <option value="1787">Puerto Rico +1787</option>
    <option value="974">Qatar +974</option>
    <option value="262">Reunion +262</option>
    <option value="40">Romania +40</option>
    <option value="7">Russian Federation +7</option>
    <option value="250">Rwanda +250</option>
    <option value="590">Saint Barthelemy +590</option>
    <option value="290">Saint Helena +290</option>
    <option value="1869">Saint Kitts and Nevis +1869</option>
    <option value="1758">Saint Lucia +1758</option>
    <option value="590">Saint Martin +590</option>
    <option value="508">Saint Pierre and Miquelon +508</option>
    <option value="1784">Saint Vincent and the Grenadines +1784</option>
    <option value="684">Samoa +684</option>
    <option value="378">San Marino +378</option>
    <option value="239">Sao Tome and Principe +239</option>
    <option value="966">Saudi Arabia +966</option>
    <option value="221">Senegal +221</option>
    <option value="381">Serbia +381</option>
    <option value="381">Serbia and Montenegro +381</option>
    <option value="248">Seychelles +248</option>
    <option value="232">Sierra Leone +232</option>
    <option value="65">Singapore +65</option>
    <option value="721">Sint Maarten +721</option>
    <option value="421">Slovakia +421</option>
    <option value="386">Slovenia +386</option>
    <option value="677">Solomon Islands +677</option>
    <option value="252">Somalia +252</option>
    <option value="27">South Africa +27</option>
    <option value="500">South Georgia and the South Sandwich Islands +500</option>
    <option value="211">South Sudan +211</option>
    <option value="34">Spain +34</option>
    <option value="94">Sri Lanka +94</option>
    <option value="249">Sudan +249</option>
    <option value="597">Suriname +597</option>
    <option value="47">Svalbard and Jan Mayen +47</option>
    <option value="268">Swaziland +268</option>
    <option value="46">Sweden +46</option>
    <option value="41">Switzerland +41</option>
    <option value="963">Syrian Arab Republic +963</option>
    <option value="886">Taiwan, Province of China +886</option>
    <option value="992">Tajikistan +992</option>
    <option value="255">Tanzania, United Republic of +255</option>
    <option value="66">Thailand +66</option>
    <option value="670">Timor-Leste +670</option>
    <option value="228">Togo +228</option>
    <option value="690">Tokelau +690</option>
    <option value="676">Tonga +676</option>
    <option value="1868">Trinidad and Tobago +1868</option>
    <option value="216">Tunisia +216</option>
    <option value="90">Turkey +90</option>
    <option value="7370">Turkmenistan +7370</option>
    <option value="1649">Turks and Caicos Islands +1649</option>
    <option value="688">Tuvalu +688</option>
    <option value="256">Uganda +256</option>
    <option value="380">Ukraine +380</option>
    <option value="971">United Arab Emirates +971</option>
    <option value="44">United Kingdom +44</option>
    <option value="1">United States +1</option>
    <option value="1">United States Minor Outlying Islands +1</option>
    <option value="598">Uruguay +598</option>
    <option value="998">Uzbekistan +998</option>
    <option value="678">Vanuatu +678</option>
    <option value="58">Venezuela +58</option>
    <option value="84">Viet Nam +84</option>
    <option value="1284">Virgin Islands, British +1284</option>
    <option value="1340">Virgin Islands, U.s. +1340</option>
    <option value="681">Wallis and Futuna +681</option>
    <option value="212">Western Sahara +212</option>
    <option value="967">Yemen +967</option>
    <option value="260">Zambia +260</option>
    <option value="263">Zimbabwe +263</option>
        </Form.Control>
      </Form.Group>









      <Form.Group className="form-group">
                 
                      <div>
                      <small className="form-text">
            Type in your company's phone number
          </small>
                      </div>
                      <Form.Control
                        type="tel"
                        placeholder="Company's Phone Number"
                        name="companyphonenumber"
                        value={companyphonenumber}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>


             
</div>




                    <Form.Group className="form-group">
                      <Form.Label htmlFor="website" className="form-label">
                        Website :
                      </Form.Label>
                      <div>
                      <small className="form-text">
            Could be your own or a company website
          </small>
                      </div>
                   
                      <Form.Control
                        type="text"
                        placeholder="Website"
                        name="website"
                        value={website}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>
  </div>
}




                  
                   

                   
                  </div>
                </Card.Body>
              </Card>
           
                                          </Row>
                                          <Button onClick={onSubmit} className="btn btn-primary me-2">Submit</Button>
                                          <Button type="reset" className="btn bg-danger">Cancel</Button>
                                      </Form>
                                  </Card.Body>
                              </Card>
                          </Tab.Pane>
                          <Tab.Pane eventKey="second" className="fade show">
                              <Card>
                                  <Card.Header className="d-flex justify-content-between">
                                  <div className="iq-header-title">
                                      <h4 className="card-title">Change Password</h4>
                                  </div>
                                  </Card.Header>
                                  <Card.Body>
                                    <Form>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="cpass" className="form-label">Current Password:</Form.Label>
                                            {/* <Link to="#" className="float-end">Forgot Password</Link> */}
                                            <Form.Control  type="password"
            id="currentPassword"
            name="currentPassword"
            value={currentPassword}
            onChange={onPwChange}
            required/>
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label htmlFor="npass" className="form-label">New Password:</Form.Label>
                                            <Form.Control   type="password"
            id="newPassword"
            name="newPassword"
            value={newPassword}
            onChange={onPwChange}
            minLength="6"
            required/>
                                        </Form.Group>

                                        <Button onClick={onPwSubmit} className="btn btn-primary me-2">Submit</Button>
                                        <Button type="reset" className="btn bg-danger">Cancel</Button>
                                    </Form>
                                  </Card.Body>
                              </Card>
                          </Tab.Pane>
                          <Tab.Pane eventKey="third" className="fade show">
                              <Card>
                                  <Card.Header className="card-header d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Social Media</h4>
                                    </div>
                                  </Card.Header>
                                  <Card.Body>
                                  <Col lg="12">
              <Card>
            
                <Card.Body>
                  <div>
                    <Form.Group className="form-group">
                      <Form.Label htmlFor="youtube" className="form-label">
                        YouTube:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="YouTube"
                        name="youtube"
                        value={youtube}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label htmlFor="twitter" className="form-label">
                        Twitter:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Twitter"
                        name="twitter"
                        value={twitter}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label htmlFor="instagram" className="form-label">
                        Instagram:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Instagram"
                        name="instagram"
                        value={instagram}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label htmlFor="linkedin" className="form-label">
                        LinkedIn:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="LinkedIn"
                        name="linkedin"
                        value={linkedin}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>

                    <Form.Group className="form-group">
                      <Form.Label htmlFor="facebook" className="form-label">
                        Facebook:
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Facebook"
                        name="facebook"
                        value={facebook}
                        onChange={(e) => onChange(e)}
                      />
                    </Form.Group>

                    {/* <Form.Group className="form-group">
                                <Form.Label htmlFor="altemail" className="form-label">Alternate Email:</Form.Label>
                                <Form.Control type="email" className="form-control" id="altemail" defaultValue="designtheme@gmail.com"/>
                            </Form.Group>

                            <Form.Group className="form-group">
                                <Form.Label htmlFor="altemail" className="form-label">Alternate Email:</Form.Label>
                                <Form.Control type="email" className="form-control" id="altemail" defaultValue="designtheme@gmail.com"/>
                            </Form.Group>


                            <Form.Group className="form-group">
                                <Form.Label htmlFor="altemail" className="form-label">Alternate Email:</Form.Label>
                                <Form.Control type="email" className="form-control" id="altemail" defaultValue="designtheme@gmail.com"/>
                            </Form.Group> */}

                    {/* <Form.Group className="form-group">
                                <Form.Label className="d-block form-label">Language Known:</Form.Label>
                                <Form.Check className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" id="english" defaultChecked/>
                                    <Form.Check.Label className="form-check-label" htmlFor="english">English</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" id="french" defaultChecked/>
                                    <Form.Check.Label className="form-check-label" htmlFor="french">French</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" id="hindi"/>
                                    <Form.Check.Label className="form-check-label" htmlFor="hindi">Hindi</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" id="spanish" defaultChecked/>
                                    <Form.Check.Label className="form-check-label" htmlFor="spanish">Spanish</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" id="arabic"/>
                                    <Form.Check.Label className="form-check-label" htmlFor="arabic">Arabic</Form.Check.Label>
                                </Form.Check>
                                <Form.Check className="form-check form-check-inline">
                                    <Form.Check.Input type="checkbox" className="form-check-input" id="italian"/>
                                    <Form.Check.Label className="form-check-label" htmlFor="italian">Italian</Form.Check.Label>
                                </Form.Check>
                            </Form.Group> */}
                    <Button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        onSubmit();
                      }}
                      className="btn btn-primary me-2"
                    >
                      Submit
                    </Button>
                    <Button type="reset" className="btn bg-danger">
                      Cancel
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
                                  </Card.Body>
                              </Card>
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth" className="fade show">
                              <Card>
                                  <Card.Header className="d-flex justify-content-between">
                                  <div className="header-title">
                                      <h4 className="card-title">Manage Contact</h4>
                                  </div>
                                  </Card.Header>
                                  <Card.Body>
                                      <Form>
                                          <Form.Group className="form-group">
                                              <Form.Label htmlFor="cno"  className="form-label">Contact Number:</Form.Label>
                                              <Form.Control type="text" className="form-control" id="cno" defaultValue="001 2536 123 458"/>
                                          </Form.Group>
                                          <Form.Group className="form-group">
                                              <Form.Label htmlFor="email"  className="form-label">Email:</Form.Label>
                                              <Form.Control type="text" className="form-control" id="email" defaultValue="Bnijone@demo.com"/>
                                          </Form.Group>
                                          <Form.Group className="form-group">
                                              <Form.Label htmlFor="url"  className="form-label">Url:</Form.Label>
                                              <Form.Control type="text" className="form-control" id="url" defaultValue="https://getbootstrap.com"/>
                                          </Form.Group>
                                          <Button type="submit" className="btn btn-primary me-2">Submit</Button>
                                          <Button type="reset" className="btn bg-danger">Cancel</Button>
                                      </Form>
                                  </Card.Body>
                              </Card>
                          </Tab.Pane>
                      </Tab.Content>
                  {/* </div> */}
              </Col>
          </Row>
          </Tab.Container>






        

     

            {/* <Col lg="6">
                <Card>
                <Card.Header className="card-header d-flex justify-content-between">
                    <div className="header-title">
                        <h4 className="card-title">Social Media</h4>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className="acc-edit">
                        <Form>
                            <Form.Group className="form-group">
                                <Form.Label htmlFor="facebook" className="form-label">Facebook:</Form.Label>
                                <Form.Control type="text" className="form-control" id="facebook" defaultValue="www.facebook.com"/>
                            </Form.Group>
                            <Form.Group className="form-group">
                            <Form.Label htmlFor="twitter" className="form-label">Twitter:</Form.Label>
                            <Form.Control type="text" className="form-control" id="twitter" defaultValue="www.twitter.com"/>
                            </Form.Group>
                            <Form.Group className="form-group">
                            <Form.Label htmlFor="google" className="form-label">Google +:</Form.Label>
                            <Form.Control type="text" className="form-control" id="google" defaultValue="www.google.com"/>
                            </Form.Group>
                            <Form.Group className="form-group">
                            <Form.Label htmlFor="instagram" className="form-label">Instagram:</Form.Label>
                            <Form.Control type="text" className="form-control" id="instagram" defaultValue="www.instagram.com"/>
                            </Form.Group>
                            <Form.Group className="form-group">
                            <Form.Label htmlFor="youtube" className="form-label">You Tube:</Form.Label>
                            <Form.Control type="text" className="form-control" id="youtube" defaultValue="www.youtube.com"/>
                            </Form.Group>
                            <Button type="submit" className="btn btn-primary me-2">Submit</Button>
                            <Button type="reset" className="btn bg-danger">Cancel</Button>
                        </Form>
                    </div>
                </Card.Body>
                </Card>
            </Col> */}
          </Row>
        </Form>


        {/* <h3>Name my pet</h3>
        <form >
          <input
            type="text"
            name="animal"
            placeholder="Enter an animal"
            value={animalInput}
            onChange={(e) => setAnimalInput(e.target.value)}
          />
          <input type="submit" value="Generate names" onClick={(e) => onGenerateSubmit(e)}/>
        </form> */}
        {/* <div >{generateResponse}</div> */}


      </Container>
    </>
  );
};

UserAccountSetting.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  createProfile: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  createProfile,
  getProfileById,
  getCurrentProfile,
  generateAi,
  updatePassword,
})(UserAccountSetting);
