import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addGroup, editGroup, getGroup } from "../../../actions/group";
import Box from "@mui/material/Box";
import Popup from "../from/Popup";
import {
  Container,
  Card,
  Row,
  Col,
  Image,
  Form,
  Button,
} from "react-bootstrap";

const CreateGroup = ({addGroup,editGroup, editGroupMode, groupToEdit, getGroup, groupToEditId}) => {
  useEffect(() => {
    if(editGroupMode){
      setFormData(groupToEdit)
    }
   }, [groupToEdit, editGroupMode]);
 
  
  const [formData, setFormData] = useState(editGroupMode ? {
    title: groupToEdit?.groupInfo?.title,
    description: groupToEdit?.groupInfo?.description,
    type: groupToEdit?.groupInfo?.type,
    privacy: groupToEdit?.groupInfo?.privacy,
    groupImage: groupToEdit?.groupInfo?.groupImage,
    groupBackgroundImage: groupToEdit?.groupInfo?.groupBackgroundImage
  } : {
    title: '',
    description: '',
    type: '',
    privacy: '',
    groupImage: '',
    groupBackgroundImage: ''
  });






  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
    // setFormData({
    //   ...groupToEdit,
    //   [e.target.name]: e.target.value
    // });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();



    const data = new FormData();

    Object.keys(formData).forEach((key) => {
    
        data.append(key, formData[key]);
      
      
    });

    editGroupMode ? editGroup(groupToEditId, data) : addGroup(data);
    if (editGroupMode){
      getGroup(groupToEditId);
    }
    addGroup(groupToEdit);
  };

  function base64ImageToBlob(receivedImage) {
    // extract content type and base64 payload from original string
  
    var pos = receivedImage.indexOf(';base64,');
    var type = receivedImage.substring(5, pos);
    var b64 = receivedImage.substr(pos + 8);
  
    // decode base64
    var imageContent = atob(b64);
  
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);
  
    // fill the view, using the decoded base64
    for(var n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
  
    // convert ArrayBuffer to Blob
    var blob = new Blob([buffer], { type: type });
  
   setFormData((prevData) => ({
    ...prevData,
    [fieldName]: blob,
  }));
  }

  const cropPicturesHandler = (image, newfieldName) => {
   
    
    switch(newfieldName || fieldName) {
      case 'groupimage':
          
      setGroupImage(image);
          break;
      case 'groupbackgroundimage':
        setGroupBackgroundImage(image);
          break;
  
      default:
          console.log('Error, please contact administrator with code error 1');
          break;
  }
  }
  const [cropOpen, setCropOpen] = React.useState(false);
  const [groupImage, setGroupImage] = React.useState(
    (editGroupMode && groupToEdit?.groupImages?.groupimage) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      groupToEdit?.groupImages?.groupimage }` : null
  );
  const [groupBackgroundImage, setGroupBackgroundImage] = React.useState(
    (editGroupMode && groupToEdit?.groupImages?.groupbackgroundimage) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      groupToEdit?.groupImages?.groupbackgroundimage }` : null
  );
  const [fieldName, setFieldName] = useState(null);
  const [imageToCrop, setImageToCrop] = useState(null);
  // console.log("fieldName", fieldName)
  
    const handleCropClose = () => {
      setCropOpen(false);
    };
  const getUploadedFile = (image, newfieldName) => {
    setCropOpen(true);
    setImageToCrop(image);
    cropPicturesHandler(image, newfieldName);
  };
  const handleFileChange = (e) => {
    const fieldName = e.target.name;
    setFieldName(fieldName);
    // const file = e.target.files[0];

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();
    reader.onload = () => {
      getUploadedFile(reader.result, fieldName);
    
    };
    reader.readAsDataURL(files[0]);
  
  };

  return (
    <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                // defaultValue={formData.pulse}
                              
                                  type="text"
                                  placeholder="Write something"
                                  name="title" defaultValue={formData?.title} onChange={handleChange} required
                                  
                                />
                                <small className="form-text">
                              This will be the name of your group
                              </small>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                // defaultValue={formData.pulse}
                                 
                                  type="text"
                              
                                 
                                  name="description" defaultValue={formData?.description} onChange={handleChange} placeholder="Description"
                                />
                                <small className="form-text">
                              Describe what group is about
                              </small>
                              </Form.Group>
   
                              <Form.Group className="mb-3">
                                <Form.Label>Type</Form.Label>
                               
  <Form.Select
    name="type"
    defaultValue={formData?.type}
    onChange={handleChange}
    aria-label="Type"
  >
    <option value="official">Official</option>
    <option value="university">University</option>
    <option value="study">Study</option>
    {/* Add more options as needed */}
  </Form.Select>
  <small className="form-text">
    Select the type of your group
  </small>
</Form.Group>
                              <Form.Group className="mb-3">
                              
  <Form.Label>Privacy</Form.Label>
  <Form.Select
    name="privacy"
    defaultValue={formData?.privacy}
    onChange={handleChange}
    aria-label="Privacy"
  >
    <option value="Public">Public</option>
    <option value="Private">Private</option>
    <option value="Unlisted">Unlisted</option>
  </Form.Select>
  <small className="form-text">
    Select your group's privacy
  </small>
</Form.Group>




                              <Form.Group className="mb-3">
                          <Form.Label>Group Image</Form.Label>
                          <Form.Control
                             accept="image/*"
                            type="file"
                            name="groupimage"
                            
                            onChange={handleFileChange}
                          />
                           
                        </Form.Group>
                        <Box my={2}>
                          {groupImage && <img src={groupImage} alt="cropped"  width={400}/>}
          
        </Box>
        <Form.Group className="mb-3">
                          <Form.Label>Group Background Image</Form.Label>
                          <Form.Control
                             accept="image/*"
                            type="file"
                            name="groupbackgroundimage"
                            
                            onChange={handleFileChange}
                          />
                           
                        </Form.Group>
                        <Box my={2}>
                          {groupBackgroundImage && <img src={groupBackgroundImage} alt="cropped"  width={400}/>}
          
        </Box>
        <Popup
          open={cropOpen}
          handleClose={handleCropClose}
          image={imageToCrop}
          getCroppedFile={(image) => {
            cropPicturesHandler(image);
            base64ImageToBlob(image);
         
            handleCropClose();
          }}
        />


    
      <Button type="submit">Create Group</Button>
    </Form>
  );
};

CreateGroup.propTypes = {
    addGroup: PropTypes.func.isRequired,
    editGroup: PropTypes.func.isRequired,
    getGroup: PropTypes.func.isRequired,
  };
  
  export default connect(null, { addGroup, editGroup, getGroup })(CreateGroup);

