import axios from "axios";
import { setAlert } from "./alert";
import {
  GET_EVENTS,
  EVENT_ERROR,
  UPDATE_LIKES,
  DELETE_EVENT,
  ADD_EVENT,
  EDIT_EVENT,
  GET_EVENT,
  ADD_EVENT_POST,
  EDIT_EVENT_POST,
  DELETE_EVENT_POST,
} from "./types";
import { createBrowserHistory } from 'history';
axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URL}`;
const history = createBrowserHistory();
// Get events
export const getEvents = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/events");

    dispatch({
      type: GET_EVENTS,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e?.response?.statusText, status: e?.response?.status },
    });
  }
};

// Add like
export const addLike = (id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/posts/like/${id}`);

    dispatch({
      type: UPDATE_LIKES,
      payload: { id, likes: res.data },
    });
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e.response.statusText, status: e.response.status },
    });
  }
};

// Remove like
export const removeLike = (id) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/posts/unlike/${id}`);

    dispatch({
      type: UPDATE_LIKES,
      payload: { id, likes: res.data },
    });
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e.response.statusText, status: e.response.status },
    });
  }
};

//Delete Post
export const deleteEvent = (id) => async (dispatch) => {
  try {
    await axios.delete(`/api/events/${id}`);

    dispatch({
      type: DELETE_EVENT,
      payload: id,
    });

    dispatch(setAlert("Event successfully removed", "success"));
   
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e.response.statusText, status: e.response.status },
    });
  }
  // history.push('/events');
  window.location.reload();
};

// Add Event
export const addEvent = (formData) => async (dispatch) => {
  const config = {
    // headers: {
    //   "Content-Type": "application/json",
    // },
  };

  try {
    const res = await axios.post("/api/events", formData, config);

    dispatch({
      type: ADD_EVENT,
      payload: res.data,
    });

    dispatch(setAlert("Event successfully created", "success"));
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e.response.statusText, status: e.response.status },
    });
  }
};


export const requestToJoinEvent = (eventId) => async (dispatch) => {
  try {
    const res = await axios.post(`/api/events/${eventId}/request`);

    dispatch(setAlert('Request sent successfully', 'success'));
    // Optionally, you can dispatch an action to update your state indicating the request was sent successfully
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e.response.statusText, status: e.response.status },
    });
    dispatch(setAlert('Failed to send request. Please try again later.', 'danger'));
  }
};

export const leaveEvent = (eventId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/events/${eventId}/leave`);

    dispatch(setAlert('Successfully left the event', 'success'));
    // Optionally, you can dispatch an action to update your state indicating the user left the event
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e.response.statusText, status: e.response.status },
    });
    dispatch(setAlert('Failed to leave the event. Please try again later.', 'danger'));
  }
};

export const acceptRequest = (eventId, userId) => async (dispatch) => {
  console.log("trying...");
  try {
    const res = await axios.put(`/api/events/${eventId}/accept-request/${userId}`);

    dispatch(setAlert('Request accepted successfully', 'success'));
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch(setAlert('Failed to accept request. Please try again later.', 'danger'));
  }
};

export const rejectRequest = (eventId, userId) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/events/${eventId}/reject-request/${userId}`);

    dispatch(setAlert('Request rejected successfully', 'success'));
  } catch (err) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch(setAlert('Failed to reject request. Please try again later.', 'danger'));
  }
};

// Edit event
export const editEvent = (_id, formData) => async (dispatch) => {
  const config = {
    // headers: {
    //   "Content-Type": "application/json",
    // },
  };
  try {
    await axios.put(`/api/events/${_id}`, formData, config);

    dispatch({
      type: EDIT_EVENT,
      payload: _id,
    });

    dispatch(setAlert("Event successfully edited", "success"));
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e.response.statusText, status: e.response.status },
    });
  }
};

// Get event
export const getEvent = (id) => async (dispatch) => {
  try {
    const res = await axios.get(`/api/events/${id}`);

    dispatch({
      type: GET_EVENT,
      payload: res.data,
    });
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e.response.statusText, status: e.response.status },
    });
  }
};

// Add event post
export const addeventPost = (eventId, formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.post(
      `/api/events/${eventId}/posts`,
      formData,
      config
    );

    dispatch({
      type: ADD_EVENT_POST,
      payload: res.data,
    });

    dispatch(setAlert("Post successfully added", "success"));
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e.response.statusText, status: e.response.status },
    });
  }
};

// Delete event post
export const deleteEventPost = (eventId, postId) => async (dispatch) => {
  try {
    await axios.delete(`/api/events/${eventId}/posts/${postId}`);

    dispatch({
      type: DELETE_EVENT_POST,
      payload: postId,
    });

    dispatch(setAlert("Post successfully deleted", "success"));
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e.response.statusText, status: e.response.status },
    });
  }
};

// Edit comment
export const editComment = (eventId, postId, formData) => async (dispatch) => {
  try {
    await axios.put(`/api/events/${eventId}/posts/${postId}`, {formData});

    dispatch({
      type: EDIT_EVENT_POST,
      payload: postId,
    });

    dispatch(setAlert("Post successfully edited", "success"));
  } catch (e) {
    dispatch({
      type: EVENT_ERROR,
      payload: { msg: e.response.statusText, status: e.response.status },
    });
  }
};