import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addEvent, editEvent, getEvent } from "../../../actions/event";
import Box from "@mui/material/Box";
import Popup from "../from/Popup";
import {
  Container,
  Card,
  Row,
  Col,
  Image,
  Form,
  Button,
} from "react-bootstrap";

const CreateEvent = ({addEvent,editEvent, editEventMode, eventToEdit, getEvent, eventToEditId}) => {
  console.log("addEvent",addEvent);
  console.log("editEvent",editEvent);
  console.log("editEventMode",editEventMode);
  console.log("eventToEdit",eventToEdit);
  console.log("getEvent",getEvent);
  console.log("eventToEditId",eventToEditId);
  useEffect(() => {
    if(editEventMode){
      setFormData(eventToEdit)
    }
   }, [eventToEdit, editEventMode]);
 
  
  const [formData, setFormData] = useState(editEventMode ? {
    title: eventToEdit?.eventInfo?.title,
    description: eventToEdit?.eventInfo?.description,
    type: eventToEdit?.eventInfo?.type,
    privacy: eventToEdit?.eventInfo?.privacy,
    eventImage: eventToEdit?.eventInfo?.eventImage,
    eventBackgroundImage: eventToEdit?.eventInfo?.eventBackgroundImage
  } : {
    title: '',
    description: '',
    type: '',
    privacy: '',
    eventImage: '',
    eventBackgroundImage: ''
  });






  const handleChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
    // setFormData({
    //   ...eventToEdit,
    //   [e.target.name]: e.target.value
    // });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();



    const data = new FormData();

    Object.keys(formData).forEach((key) => {
    
        data.append(key, formData[key]);
      
      
    });

    editEventMode ? editEvent(eventToEditId, data) : addEvent(data);
    if (editEventMode){
      getEvent(eventToEditId);
    }
    addEvent(eventToEdit);
  };

  function base64ImageToBlob(receivedImage) {
    // extract content type and base64 payload from original string
  
    var pos = receivedImage.indexOf(';base64,');
    var type = receivedImage.substring(5, pos);
    var b64 = receivedImage.substr(pos + 8);
  
    // decode base64
    var imageContent = atob(b64);
  
    // create an ArrayBuffer and a view (as unsigned 8-bit)
    var buffer = new ArrayBuffer(imageContent.length);
    var view = new Uint8Array(buffer);
  
    // fill the view, using the decoded base64
    for(var n = 0; n < imageContent.length; n++) {
      view[n] = imageContent.charCodeAt(n);
    }
  
    // convert ArrayBuffer to Blob
    var blob = new Blob([buffer], { type: type });
  
   setFormData((prevData) => ({
    ...prevData,
    [fieldName]: blob,
  }));
  }

  const cropPicturesHandler = (image, newfieldName) => {
   
    
    switch(newfieldName || fieldName) {
      case 'eventimage':
          
      setEventImage(image);
          break;
      case 'eventbackgroundimage':
        setEventBackgroundImage(image);
          break;
  
      default:
          console.log('Error, please contact administrator with code error 1');
          break;
  }
  }
  const [cropOpen, setCropOpen] = React.useState(false);
  const [eventImage, setEventImage] = React.useState(
    (editEventMode && eventToEdit?.eventImages?.eventimage) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      eventToEdit?.eventImages?.eventimage }` : null
  );
  const [eventBackgroundImage, setEventBackgroundImage] = React.useState(
    (editEventMode && eventToEdit?.eventImages?.eventbackgroundimage) ? `${
      process.env.REACT_APP_BASE_URL +
      "/" +
      eventToEdit?.eventImages?.eventbackgroundimage }` : null
  );
  const [fieldName, setFieldName] = useState(null);
  const [imageToCrop, setImageToCrop] = useState(null);
  // console.log("fieldName", fieldName)
  
    const handleCropClose = () => {
      setCropOpen(false);
    };
  const getUploadedFile = (image, newfieldName) => {
    setCropOpen(true);
    setImageToCrop(image);
    cropPicturesHandler(image, newfieldName);
  };
  const handleFileChange = (e) => {
    const fieldName = e.target.name;
    setFieldName(fieldName);
    // const file = e.target.files[0];

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    if (files.length === 0) {
      return alert("Please select a file.");
    }
    const reader = new FileReader();
    reader.onload = () => {
      getUploadedFile(reader.result, fieldName);
    
    };
    reader.readAsDataURL(files[0]);
  
  };

  return (
    <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                // defaultValue={formData.pulse}
                              
                                  type="text"
                                  placeholder="Write something"
                                  name="title" defaultValue={formData?.title} onChange={handleChange} required
                                  
                                />
                                <small className="form-text">
                              This will be the name of your event
                              </small>
                              </Form.Group>

                              <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                // defaultValue={formData.pulse}
                                 
                                  type="text"
                              
                                 
                                  name="description" defaultValue={formData?.description} onChange={handleChange} placeholder="Description"
                                />
                                <small className="form-text">
                              Describe what event is about
                              </small>
                              </Form.Group>
   
                              <Form.Group className="mb-3">
                                <Form.Label>Type</Form.Label>
                               
  <Form.Select
    name="type"
    defaultValue={formData?.type}
    onChange={handleChange}
    aria-label="Type"
  >
    <option value="official">Official</option>
    <option value="university">University</option>
    <option value="study">Study</option>
    {/* Add more options as needed */}
  </Form.Select>
  <small className="form-text">
    Select the type of your event
  </small>
</Form.Group>
                              <Form.Group className="mb-3">
                              
  <Form.Label>Privacy</Form.Label>
  <Form.Select
    name="privacy"
    defaultValue={formData?.privacy}
    onChange={handleChange}
    aria-label="Privacy"
  >
    <option value="Public">Public</option>
    <option value="Private">Private</option>
    <option value="Unlisted">Unlisted</option>
  </Form.Select>
  <small className="form-text">
    Select your event's privacy
  </small>
</Form.Group>




                              <Form.Group className="mb-3">
                          <Form.Label>Event Image</Form.Label>
                          <Form.Control
                             accept="image/*"
                            type="file"
                            name="eventimage"
                            
                            onChange={handleFileChange}
                          />
                           
                        </Form.Group>
                        <Box my={2}>
                          {eventImage && <img src={eventImage} alt="cropped"  width={400}/>}
          
        </Box>
        <Form.Group className="mb-3">
                          <Form.Label>Event Background Image</Form.Label>
                          <Form.Control
                             accept="image/*"
                            type="file"
                            name="eventbackgroundimage"
                            
                            onChange={handleFileChange}
                          />
                           
                        </Form.Group>
                        <Box my={2}>
                          {eventBackgroundImage && <img src={eventBackgroundImage} alt="cropped"  width={400}/>}
          
        </Box>
        <Popup
          open={cropOpen}
          handleClose={handleCropClose}
          image={imageToCrop}
          getCroppedFile={(image) => {
            cropPicturesHandler(image);
            base64ImageToBlob(image);
         
            handleCropClose();
          }}
        />


    
      <Button type="submit">Create Event</Button>
    </Form>
  );
};

CreateEvent.propTypes = {
    addEvent: PropTypes.func.isRequired,
    editEvent: PropTypes.func.isRequired,
    getEvent: PropTypes.func.isRequired,
  };
  
  export default connect(null, { addEvent, editEvent, getEvent })(CreateEvent);

