import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Card from "../../components/Card";
import CustomToggle from "../../components/dropdowns";
import ShareOffcanvas from "../../components/share-offcanvas";
import PostItem from "./posts/PostItem";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getPosts } from "../../actions/post";
import { getEvents } from "../../actions/event";
import { loadUser } from "../../actions/auth";
//image
import eventIcon from "../../assets/images/event.png";
import caseStudyIcon from "../../assets/images/case-study-icon.png"

import loader from "../../assets/images/page-img/page-load-loader.gif";
import { useDispatch, useSelector } from "react-redux";

import FormWizard from "./from/form-wizard";
import FormWizardValidate from "./from/form-wizard-vertical";
import sponsoredAd from "../../assets/images/sponsored-ad-sample.jpg";

const Index = ({ getPosts, auth, loadUser, getEvents }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => auth?.user?.verified === "verified" ? setShow(true) : setShow(false);

  const [showEventModal, setShowEventModal] = useState(false);
  const handleCloseEventModal = () => setShowEventModal(false);
  const handleShowEventModal = () => setShowEventModal(true);
  const [searchKeyword, setSearchKeyword] = React.useState("");

  const posts = useSelector((state) => state.posts.posts);
  useEffect(() => {
    loadUser();
  }, [loadUser]);
  useEffect(() => {
    getPosts();
  }, [getPosts]); 




  const events = useSelector((state) => state?.event?.events);
  useEffect(() => {
    getEvents();
  }, []);



  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={8} className="row m-0 p-0">
              <Col sm={12}>
                <Card
                  id="post-modal-data"
                  className="card-block card-stretch card-height"
                >
                  {/* <div className="card-header d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">Create Post</h4>
                    </div>
                  </div> */}
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="user-img">
                        <img
                          alt="Dentistup"
                          src={
                            auth?.user?.profileImage
                              ? `${
                                  process.env.REACT_APP_BASE_URL +
                                  "/" +
                                  auth?.user?.profileImage
                                }`
                              : auth?.user?.avatar
                          }
                          // src="http://api-community.dentistup.tn:5050/uploads/profileImage-1688135673838.jpeg"
                          className="avatar-60 rounded-circle"
                        />
                      </div>
                      <Link className="search-link" to="/dashboard/form/form-wizard/">
                      <form
                        className="post-text ms-3 w-100 "
                        // onClick={handleShow}
                      >
                        <input
                          type="text"
                          className="form-control rounded"
                          placeholder="Write something here..."
                          style={{ border: "none" }}
                          
                        />
                      </form>
                      </Link>
                     
                    </div>
                    <hr></hr>
                    <Modal
                      // size="lg"
                      className="fade"
                      
                      id="post-modal"
                      onHide={handleCloseEventModal}
                      show={showEventModal}
                    >
                      <Modal.Header className="d-flex justify-content-between">
                        <Modal.Title id="post-modalLabel">
                          Create Post
                        </Modal.Title>
                        <Link
                          to="#"
                          className="lh-1"
                          onClick={handleCloseEventModal}
                        >
                          <span className="material-symbols-outlined">
                            close
                          </span>
                        </Link>
                      </Modal.Header>
                      <Modal.Body>
                        <FormWizardValidate />
                      </Modal.Body>
                    </Modal>

                    <ul className=" post-opt-block d-flex list-inline m-0 p-0 flex-wrap">
                    <li
                        className="me-3 mb-md-0 mb-2"
                        onClick={handleShow}
                      >
                        <Link to="#" className="btn btn-soft-primary">
                          <img
                            src={caseStudyIcon}
                            style={{ width: 24 }}
                            alt="icon"
                            className="img-fluid me-2"
                          />{" "}
                          Case
                        </Link>
                      </li>
                      <li
                        className="me-3 mb-md-0 mb-2 coming-soon"
                       
                        // onClick={handleShowEventModal}
                      >
                        <Link to="#" className="btn btn-soft-primary">
                          <img
                            src={eventIcon}
                            style={{ width: 24 }}
                            alt="icon"
                            className="img-fluid me-2"
                          />{" "}
                          Event
                        </Link>
                      </li>

                    
                      {/* <li className="me-3 mb-md-0 mb-2">
                        <Link to="#" className="btn btn-soft-primary">
                          <img
                            src={img2}
                            alt="icon"
                            className="img-fluid me-2"
                          />{" "}
                          Tag Friend
                        </Link>
                      </li>
                      <li className="me-3">
                        <Link to="#" className="btn btn-soft-primary">
                          <img
                            src={img3}
                            alt="icon"
                            className="img-fluid me-2"
                          />{" "}
                          Feeling/Activity
                        </Link>
                      </li> */}
                      {/* <li>
                        <button className=" btn btn-soft-primary">
                          <div className="card-header-toolbar d-flex align-items-center">
                            <Dropdown>
                              <Dropdown.Toggle as="div" className="lh-1">
                                <span className="material-symbols-outlined">
                                  more_horiz
                                </span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={handleShow} href="#">
                                  Check in
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleShow} href="#">
                                  Live Video
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleShow} href="#">
                                  Gif
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleShow} href="#">
                                  Watch Party
                                </Dropdown.Item>
                                <Dropdown.Item onClick={handleShow} href="#">
                                  Play with Friend
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </button>
                      </li> */}
                    </ul>
                  </Card.Body>
                  <Modal
                    size="lg"
                    className="fade"
                    id="post-modal"
                    onHide={handleClose}
                    show={show}
                  >
                    <Modal.Header className="d-flex justify-content-between">
                      <Modal.Title id="post-modalLabel">
                        Create Post
                      </Modal.Title>
                      <Link to="#" className="lh-1" onClick={handleClose}>
                        <span className="material-symbols-outlined">close</span>
                      </Link>
                    </Modal.Header>
                    <Modal.Body>
                      {/* <div className="d-flex align-items-center">
                        <div className="user-img">
                          <img
                            src={user1}
                            alt="user1"
                            className="avatar-60 rounded-circle img-fluid"
                          />
                        </div>
                        <form
                          className="post-text ms-3 w-100 "
                          data-bs-toggle="modal"
                          data-bs-target="#post-modal"
                        >
                          <input
                            type="text"
                            className="form-control rounded"
                            placeholder="Write something here..."
                            style={{ border: "none" }}
                          />
                        </form>
                      </div>
                      <hr />
                      <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                        <li className="col-md-6 mb-3">
                          <div className="bg-soft-primary rounded p-2 pointer me-3">
                            <Link to="#"></Link>
                            <img
                              src={img1}
                              alt="icon"
                              className="img-fluid"
                            />{" "}
                            Photo/Video
                          </div>
                        </li>
                        <li className="col-md-6 mb-3">
                          <div className="bg-soft-primary rounded p-2 pointer me-3">
                            <Link to="#"></Link>
                            <img
                              src={img2}
                              alt="icon"
                              className="img-fluid"
                            />{" "}
                            Tag Friend
                          </div>
                        </li>
                        <li className="col-md-6 mb-3">
                          <div className="bg-soft-primary rounded p-2 pointer me-3">
                            <Link to="#"></Link>
                            <img
                              src={img3}
                              alt="icon"
                              className="img-fluid"
                            />{" "}
                            Feeling/Activity
                          </div>
                        </li>
                        <li className="col-md-6 mb-3">
                          <div className="bg-soft-primary rounded p-2 pointer me-3">
                            <Link to="#"></Link>
                            <img
                              src={img4}
                              alt="icon"
                              className="img-fluid"
                            />{" "}
                            Check in
                          </div>
                        </li>
                        <li className="col-md-6 mb-3">
                          <div className="bg-soft-primary rounded p-2 pointer me-3">
                            <Link to="#"></Link>
                            <img
                              src={img5}
                              alt="icon"
                              className="img-fluid"
                            />{" "}
                            Live Video
                          </div>
                        </li>
                        <li className="col-md-6 mb-3">
                          <div className="bg-soft-primary rounded p-2 pointer me-3">
                            <Link to="#"></Link>
                            <img
                              src={img6}
                              alt="icon"
                              className="img-fluid"
                            />{" "}
                            Gif
                          </div>
                        </li>
                        <li className="col-md-6 mb-3">
                          <div className="bg-soft-primary rounded p-2 pointer me-3">
                            <Link to="#"></Link>
                            <img
                              src={img7}
                              alt="icon"
                              className="img-fluid"
                            />{" "}
                            Watch Party
                          </div>
                        </li>
                        <li className="col-md-6 mb-3">
                          <div className="bg-soft-primary rounded p-2 pointer me-3">
                            <Link to="#"></Link>
                            <img
                              src={img8}
                              alt="icon"
                              className="img-fluid"
                            />{" "}
                            Play with Friends
                          </div>
                        </li>
                      </ul>
                      <hr />
                      <div className="other-option">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <div className="user-img me-3">
                              <img
                                src={user1}
                                alt="user1"
                                className="avatar-60 rounded-circle img-fluid"
                              />
                            </div>
                            <h6>Your Story</h6>
                          </div>
                          <div className="card-post-toolbar">
                            <Dropdown>
                              <Dropdown.Toggle as={CustomToggle} role="button">
                                <span className="btn btn-primary">Friend</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu className=" m-0 p-0">
                                <Dropdown.Item className=" p-3" to="#">
                                  <div className="d-flex align-items-top">
                                    <i className="ri-save-line h4"></i>
                                    <div className="data ms-2">
                                      <h6>Public</h6>
                                      <p className="mb-0">
                                        Anyone on or off Facebook
                                      </p>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item className="p-3" to="#">
                                  <div className="d-flex align-items-top">
                                    <i className="ri-close-circle-line h4"></i>
                                    <div className="data ms-2">
                                      <h6>Friends</h6>
                                      <p className="mb-0">
                                        Your Friend on facebook
                                      </p>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item className=" p-3" to="#">
                                  <div className="d-flex align-items-top">
                                    <i className="ri-user-unfollow-line h4"></i>
                                    <div className="data ms-2">
                                      <h6>Friends except</h6>
                                      <p className="mb-0">
                                        Don't show to some friends
                                      </p>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Item className=" p-3" to="#">
                                  <div className="d-flex align-items-top">
                                    <i className="ri-notification-line h4"></i>
                                    <div className="data ms-2">
                                      <h6>Only Me</h6>
                                      <p className="mb-0">Only me</p>
                                    </div>
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-primary d-block w-100 mt-3"
                      >
                        Post
                      </button> */}

                      <FormWizard handleCloseCaseModal={handleClose} groupdId={null}/>
                    </Modal.Body>
                  </Modal>
                </Card>
              </Col>
              {/* {filteredPosts.length > 0 ? (
            filteredPosts.map((post) => (
              <PostItem key={post._id} post={post} showDetails={false} />
            ))
          ) : (
            <h4>No Posts found...</h4>
          )} */}
              {/* {posts?.map((post) => (
                <PostItem key={post._id} post={post} showDetails={false} />
              ))} */}
              {posts?.map((post) => {
               
  if (post.postInfo.visibility !== 'private') {
    return <PostItem key={post._id} post={post} showDetails={false} />;
  }
  return null; // Skip private posts
})}
              {/* <Col sm={12}>
                            <Card className=" card-block card-stretch card-height">
                                <Card.Body>
                                    <div className="user-post-data">
                                        <div className="d-flex justify-content-between">
                                            <div className="me-3">
                                                <img className="rounded-circle img-fluid" src={user01} alt=""/>
                                            </div>
                                            <div className="w-100">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <h5 className="mb-0 d-inline-block">Anna Sthesia</h5>
                                                        <span className="mb-0 ps-1 d-inline-block">Add New Post</span>
                                                        <p className="mb-0 text-primary">Just Now</p>
                                                    </div>
                                                    <div className="card-post-toolbar">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="bg-transparent">
                                                            <span className="material-symbols-outlined">
                                                                more_horiz
                                                            </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <div className="h4 material-symbols-outlined">
                                                                            <i className="ri-save-line"></i>
                                                                        </div>
                                                                        <div className="data ms-2">
                                                                            <h6>Save Post</h6>
                                                                            <p className="mb-0">Add this to your saved items</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className= "p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                        <i className="ri-close-circle-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Hide Post</h6>
                                                                            <p className="mb-0">See fewer posts like this.</p>
                                                                        </div>
                                                                    </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-user-unfollow-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Unfollow User</h6>
                                                                                <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item className=" p-3" to="#">
                                                                        <div className="d-flex align-items-top">
                                                                            <i className="ri-notification-line h4"></i>
                                                                            <div className="data ms-2">
                                                                                <h6>Notifications</h6>
                                                                                <p className="mb-0">Turn on notifications for this post</p>
                                                                            </div>
                                                                        </div>
                                                                    </Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-3">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus</p>
                                        </div>
                                        <div className="user-post">
                                            <div className=" d-grid grid-rows-2 grid-flow-col gap-3">
                                                <div className="row-span-2 row-span-md-1">
                                                    <img src={p2} alt="post1" className="img-fluid rounded w-100"/>
                                                </div>
                                                <div className="row-span-1">
                                                    <img src={p1} alt="post2" className="img-fluid rounded w-100"/>
                                                </div>
                                                <div className="row-span-1 ">
                                                    <img src={p3} alt="post3" className="img-fluid rounded w-100"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="comment-area mt-3">
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <div className="like-block position-relative d-flex align-items-center">
                                                    <div className="d-flex align-items-center">
                                                        <div className="like-data">
                                                            <Dropdown>
                                                                <Dropdown.Toggle  as={CustomToggle} >
                                                                    <img src={icon1} className="img-fluid" alt=""/>
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className=" py-2">
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="total-like-block ms-2 me-3">
                                                            <Dropdown>
                                                                <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                                140 Likes
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                                    <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                                    <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                                    <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                                    <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                                    <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                                    <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div className="total-comment-block">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                            20 Comment
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <ShareOffcanvas />
                                            </div>
                                        <hr/>
                                        <ul className="post-comments list-inline p-0 m-0">
                                            <li className="mb-2">
                                                <div className="d-flex">
                                                    <div className="user-img">
                                                        <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                    </div>
                                                    <div className="comment-data-block ms-3">
                                                        <h6>Monty Carlo</h6>
                                                        <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                        <div className="d-flex flex-wrap align-items-center comment-activity">
                                                            <Link to="#">like</Link>
                                                            <Link to="#">reply</Link>
                                                            <Link to="#">translate</Link>
                                                            <span> 5 min </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex">
                                                    <div className="user-img">
                                                        <img src={user3} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                    </div>
                                                    <div className="comment-data-block ms-3">
                                                        <h6>Paul Molive</h6>
                                                        <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                        <div className="d-flex flex-wrap align-items-center comment-activity">
                                                            <Link to="#">like</Link>
                                                            <Link to="#">reply</Link>
                                                            <Link to="#">translate</Link>
                                                            <span> 5 min </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <form className="comment-text d-flex align-items-center mt-3" >
                                            <input type="text" className="form-control rounded" placeholder="Enter Your Comment"/>
                                            <div className="comment-attagement d-flex">
                                                <Link to="#"><i className="ri-link me-3"></i></Link>
                                                <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                                <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                            </div>
                                        </form>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={12}>
                            <div className="card card-block card-stretch card-height">
                                <div className="card-body">
                                    <div className="user-post-data">
                                        <div className="d-flex justify-content-between">
                                            <div className="me-3">
                                                <img className="rounded-circle img-fluid" src={user3} alt=""/>
                                            </div>
                                            <div className="w-100">
                                                <div className="d-flex  justify-content-between">
                                                    <div>
                                                        <h5 className="mb-0 d-inline-block">Barb Ackue</h5>
                                                        <span className="mb-0 ps-1 d-inline-block">Added New Image in a Post</span>
                                                        <p className="mb-0 text-primary">1 hour ago</p>
                                                    </div>
                                                    <div className="card-post-toolbar">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="bg-transparent">
                                                            <span className="material-symbols-outlined">
                                                                more_horiz
                                                            </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <div className="h4">
                                                                            <i className="ri-save-line"></i>
                                                                        </div>
                                                                        <div className="data ms-2">
                                                                            <h6>Save Post</h6>
                                                                            <p className="mb-0">Add this to your saved items</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className= "p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-close-circle-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Hide Post</h6>
                                                                            <p className="mb-0">See fewer posts like this.</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-user-unfollow-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Unfollow User</h6>
                                                                            <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-notification-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Notifications</h6>
                                                                            <p className="mb-0">Turn on notifications for this post</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus</p>
                                    </div>
                                    <div className="user-post">
                                        <Link to="#"><img src={p4} alt="post1" className="img-fluid rounded w-100"/></Link>
                                    </div>
                                    <div className="comment-area mt-3">
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div className="like-block position-relative d-flex align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div className="like-data">
                                                        <Dropdown>
                                                            <Dropdown.Toggle  as={CustomToggle} >
                                                                <img src={icon1} className="img-fluid" alt=""/>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className=" py-2">
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                    <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <div className="total-like-block ms-2 me-3">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                            140 Likes
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="total-comment-block">
                                                    <Dropdown>
                                                        <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                        20 Comment
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <ShareOffcanvas />
                                        </div>
                                        <hr/>
                                        <ul className="post-comments list-inline p-0 m-0">
                                            <li className="mb-2">
                                                <div className="d-flex ">
                                                    <div className="user-img">
                                                        <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                    </div>
                                                    <div className="comment-data-block ms-3">
                                                        <h6>Monty Carlo</h6>
                                                        <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                        <div className="d-flex flex-wrap align-items-center comment-activity">
                                                            <Link to="#">like</Link>
                                                            <Link to="#">reply</Link>
                                                            <Link to="#">translate</Link>
                                                            <span> 5 min </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex ">
                                                    <div className="user-img">
                                                        <img src={user3} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                    </div>
                                                    <div className="comment-data-block ms-3">
                                                        <h6>Paul Molive</h6>
                                                        <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                        <div className="d-flex flex-wrap align-items-center comment-activity">
                                                            <Link to="#">like</Link>
                                                            <Link to="#">reply</Link>
                                                            <Link to="#">translate</Link>
                                                            <span> 5 min </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <form className="comment-text d-flex align-items-center mt-3" >
                                            <input type="text" className="form-control rounded" placeholder="Enter Your Comment"/>
                                            <div className="comment-attagement d-flex">
                                                <Link to="#"><i className="ri-link me-3"></i></Link>
                                                <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                                <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                            </div>
                                        </form>
                                    </div>    
                                </div>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="card card-block card-stretch card-height">
                                <div className="card-body">
                                    <div className="user-post-data">
                                        <div className="d-flex justify-content-between">
                                            <div className="me-3">
                                                <img className="rounded-circle img-fluid" src={user4} alt=""/>
                                            </div>
                                            <div className="w-100">
                                                <div className=" d-flex  justify-content-between">
                                                    <div>
                                                        <h5 className="mb-0 d-inline-block">Ira Membrit</h5>
                                                        <p className="mb-0 ps-1 d-inline-block">Update her Status</p>
                                                        <p className="mb-0 text-primary">6 hour ago</p>
                                                    </div>
                                                    <div className="card-post-toolbar">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="bg-transparent">
                                                                <span className="material-symbols-outlined">
                                                                    more_horiz
                                                                </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <div className="h4">
                                                                            <i className="ri-save-line"></i>
                                                                        </div>
                                                                        <div className="data ms-2">
                                                                            <h6>Save Post</h6>
                                                                            <p className="mb-0">Add this to your saved items</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className= "p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-close-circle-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Hide Post</h6>
                                                                            <p className="mb-0">See fewer posts like this.</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-user-unfollow-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Unfollow User</h6>
                                                                            <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-notification-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Notifications</h6>
                                                                            <p className="mb-0">Turn on notifications for this post</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus</p>
                                    </div>
                                    <div className="comment-area mt-3">
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div className="like-block position-relative d-flex align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div className="like-data">
                                                        <Dropdown>
                                                            <Dropdown.Toggle  as={CustomToggle} >
                                                                <img src={icon1} className="img-fluid" alt=""/>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className=" py-2">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <div className="total-like-block ms-2 me-3">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                            140 Likes
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="total-comment-block">
                                                    <Dropdown>
                                                        <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                        20 Comment
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <ShareOffcanvas />
                                        </div>
                                        <hr/>
                                        <ul className="post-comments list-inline p-0 m-0">
                                            <li className="mb-2">
                                                <div className="d-flex">
                                                    <div className="user-img">
                                                        <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                    </div>
                                                    <div className="comment-data-block ms-3">
                                                        <h6>Monty Carlo</h6>
                                                        <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                        <div className="d-flex flex-wrap align-items-center comment-activity">
                                                            <Link to="#">like</Link>
                                                            <Link to="#">reply</Link>
                                                            <Link to="#">translate</Link>
                                                            <span> 5 min </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex ">
                                                    <div className="user-img">
                                                        <img src={user3} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                    </div>
                                                    <div className="comment-data-block ms-3">
                                                        <h6>Paul Molive</h6>
                                                        <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                        <div className="d-flex flex-wrap align-items-center comment-activity">
                                                            <Link to="#">like</Link>
                                                            <Link to="#">reply</Link>
                                                            <Link to="#">translate</Link>
                                                            <span> 5 min </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <form className="comment-text d-flex align-items-center mt-3" >
                                            <input type="text" className="form-control rounded" placeholder="Enter Your Comment"/>
                                            <div className="comment-attagement d-flex">
                                                <Link to="#"><i className="ri-link me-3"></i></Link>
                                                <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                                <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="card card-block card-stretch card-height">
                                <div className="card-body">
                                    <div className="post-item">
                                        <div className="d-flex justify-content-between">
                                            <div className="me-3">
                                                <img className="rounded-circle img-fluid avatar-60" src={user1} alt=""/>
                                            </div>
                                            <div className="w-100">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <h5 className="mb-0 d-inline-block">Bni Cyst</h5>
                                                        <p className="ms-1 mb-0 ps-1 d-inline-block">Changed Profile Picture</p>
                                                        <p className="mb-0">3 day ago</p>
                                                    </div>
                                                    <div className="card-post-toolbar">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="bg-transparent">
                                                            <span className="material-symbols-outlined">
                                                                more_horiz
                                                            </span>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                            <Dropdown.Item className=" p-3" to="#">
                                                                <div className="d-flex align-items-top">
                                                                    <div className="h4">
                                                                        <i className="ri-save-line"></i>
                                                                    </div>
                                                                    <div className="data ms-2">
                                                                        <h6>Save Post</h6>
                                                                        <p className="mb-0">Add this to your saved items</p>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className= "p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                    <i className="ri-close-circle-line h4"></i>
                                                                    <div className="data ms-2">
                                                                        <h6>Hide Post</h6>
                                                                        <p className="mb-0">See fewer posts like this.</p>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className=" p-3" to="#">
                                                                <div className="d-flex align-items-top">
                                                                    <i className="ri-user-unfollow-line h4"></i>
                                                                    <div className="data ms-2">
                                                                        <h6>Unfollow User</h6>
                                                                        <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                            <Dropdown.Item className=" p-3" to="#">
                                                                <div className="d-flex align-items-top">
                                                                    <i className="ri-notification-line h4"></i>
                                                                    <div className="data ms-2">
                                                                        <h6>Notifications</h6>
                                                                        <p className="mb-0">Turn on notifications for this post</p>
                                                                    </div>
                                                                </div>
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="user-post text-center">
                                    <Link to="#"><img src={p5} alt="post1" className="img-fluid rounded w-100 mt-3"/></Link>
                                </div>
                                <div className="comment-area mt-3">
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div className="like-block position-relative d-flex align-items-center">
                                            <div className="d-flex align-items-center">
                                                <div className="like-data">
                                                    <Dropdown>
                                                        <Dropdown.Toggle  as={CustomToggle} >
                                                            <img src={icon1} className="img-fluid" alt=""/>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu className=" py-2">
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                            <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                <div className="total-like-block ms-2 me-3">
                                                    <Dropdown>
                                                        <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                        140 Likes
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="total-comment-block">
                                                <Dropdown>
                                                    <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                    20 Comment
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                        <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                        <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                        <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                        <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                        <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                        <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <ShareOffcanvas />
                                    </div>
                                    <hr/>
                                    <ul className="post-comments list-inline p-0 m-0">
                                        <li className="mb-2">
                                            <div className="d-flex">
                                                <div className="user-img">
                                                    <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                </div>
                                                <div className="comment-data-block ms-3">
                                                    <h6>Monty Carlo</h6>
                                                    <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                    <div className="d-flex flex-wrap align-items-center comment-activity">
                                                        <Link to="#">like</Link>
                                                        <Link to="#">reply</Link>
                                                        <Link to="#">translate</Link>
                                                        <span> 5 min </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                <div className="user-img">
                                                    <img src={user3} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                </div>
                                                <div className="comment-data-block ms-3">
                                                    <h6>Paul Molive</h6>
                                                    <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                    <div className="d-flex flex-wrap align-items-center comment-activity">
                                                        <Link to="#">like</Link>
                                                        <Link to="#">reply</Link>
                                                        <Link to="#">translate</Link>
                                                        <span> 5 min </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                    <form className="comment-text d-flex align-items-center mt-3" >
                                        <input type="text" className="form-control rounded" placeholder="Enter Your Comment"/>
                                        <div className="comment-attagement d-flex">
                                            <Link to="#"><i className="ri-link me-3"></i></Link>
                                            <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                            <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div className="card card-block card-stretch card-height">
                                <div className="card-body">
                                    <div className="user-post-data">
                                        <div className="d-flex justify-content-between">
                                            <div className="me-3">
                                                <img className="rounded-circle img-fluid" src={user2} alt=""/>
                                            </div>
                                            <div className="w-100">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <h5 className="mb-0 d-inline-block">Paige Turner</h5>
                                                        <p className="mb-0 ps-1 d-inline-block">Added New Video in his Timeline</p>
                                                        <p className="mb-0 text-primary">1 day ago</p>
                                                    </div>
                                                    <div className="card-post-toolbar">
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="bg-transparent">
                                                                <span className="material-symbols-outlined">
                                                                    more_horiz
                                                                </span>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className="dropdown-menu m-0 p-0">
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <div className="h4">
                                                                            <i className="ri-save-line"></i>
                                                                        </div>
                                                                        <div className="data ms-2">
                                                                            <h6>Save Post</h6>
                                                                            <p className="mb-0">Add this to your saved items</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className= "p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-close-circle-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Hide Post</h6>
                                                                            <p className="mb-0">See fewer posts like this.</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-user-unfollow-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Unfollow User</h6>
                                                                            <p className="mb-0">Stop seeing posts but stay friends.</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item className=" p-3" to="#">
                                                                    <div className="d-flex align-items-top">
                                                                        <i className="ri-notification-line h4"></i>
                                                                        <div className="data ms-2">
                                                                            <h6>Notifications</h6>
                                                                            <p className="mb-0">Turn on notifications for this post</p>
                                                                        </div>
                                                                    </div>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolor, ornare at commodo non, feugiat non nisi. Phasellus faucibus mollis pharetra. Proin blandit ac massa sed rhoncus</p>
                                    </div>
                                    <div className="user-post">
                                        <div className="ratio ratio-16x9">
                                            <iframe title="vedio" src="https://www.youtube.com/embed/j_GsIanLxZk?rel=0" ></iframe>
                                        </div>
                                    </div>
                                    <div className="comment-area mt-3">
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div className="like-block position-relative d-flex align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div className="like-data">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                                <img src={icon1} className="img-fluid" alt=""/>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu className=" py-2">
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Like</Tooltip>} className="ms-2 me-2" ><img src={icon1} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Love</Tooltip>} className="me-2" ><img src={icon2} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Happy</Tooltip>} className="me-2" ><img src={icon3} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>HaHa</Tooltip>} className="me-2" ><img src={icon4} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Think</Tooltip>} className="me-2" ><img src={icon5} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Sade</Tooltip>} className="me-2" ><img src={icon6} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={<Tooltip>Lovely</Tooltip>} className="me-2" ><img src={icon7} className="img-fluid me-2" alt=""/></OverlayTrigger>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <div className="total-like-block ms-2 me-3">
                                                        <Dropdown>
                                                            <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                            140 Likes
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                                <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                </div>
                                                <div className="total-comment-block">
                                                    <Dropdown>
                                                        <Dropdown.Toggle as={CustomToggle}  id="post-option" >
                                                        20 Comment
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <Dropdown.Item  href="#">Max Emum</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Bill Yerds</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Hap E. Birthday</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Tara Misu</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Midge Itz</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Sal Vidge</Dropdown.Item>
                                                            <Dropdown.Item  href="#">Other</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <ShareOffcanvas />
                                        </div>
                                        <hr/>
                                        <ul className="post-comments list-inline p-0 m-0">
                                            <li className="mb-2">
                                                <div className="d-flex flex-wrap">
                                                    <div className="user-img">
                                                        <img src={user2} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                    </div>
                                                    <div className="comment-data-block ms-3">
                                                        <h6>Monty Carlo</h6>
                                                        <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                        <div className="d-flex flex-wrap align-items-center comment-activity">
                                                            <Link to="#">like</Link>
                                                            <Link to="#">reply</Link>
                                                            <Link to="#">translate</Link>
                                                            <span> 5 min </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="d-flex flex-wrap">
                                                    <div className="user-img">
                                                        <img src={user3} alt="user1" className="avatar-35 rounded-circle img-fluid"/>
                                                    </div>
                                                    <div className="comment-data-block ms-3">
                                                        <h6>Paul Molive</h6>
                                                        <p className="mb-0">Lorem ipsum dolor sit amet</p>
                                                        <div className="d-flex flex-wrap align-items-center comment-activity">
                                                            <Link to="#">like</Link>
                                                            <Link to="#">reply</Link>
                                                            <Link to="#">translate</Link>
                                                            <span> 5 min </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <form className="comment-text d-flex align-items-center mt-3">
                                            <input type="text" className="form-control rounded" placeholder="Enter Your Comment"/>
                                            <div className="comment-attagement d-flex">
                                            <Link to="#"><i className="ri-link me-3"></i></Link>
                                            <Link to="#"><i className="ri-user-smile-line me-3"></i></Link>
                                            <Link to="#"><i className="ri-camera-line me-3"></i></Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </Col> */}
            </Col>
            <Col lg={4}>
              {/* <Card>
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Stories</h4>
                  </div>
                </div>
                <Card.Body>
                  <ul className="media-story list-inline m-0 p-0">
                    <li className="d-flex mb-3 align-items-center">
                      <i className="ri-add-line"></i>
                      <div className="stories-data ms-3">
                        <h5>Creat Your Story</h5>
                        <p className="mb-0">time to story</p>
                      </div>
                    </li>
                    <li className="d-flex mb-3 align-items-center active">
                      <img
                        src={s2}
                        alt="story-img"
                        className="rounded-circle img-fluid"
                      />
                      <div className="stories-data ms-3">
                        <h5>Anna Mull</h5>
                        <p className="mb-0">1 hour ago</p>
                      </div>
                    </li>
                    <li className="d-flex mb-3 align-items-center">
                      <img
                        src={s3}
                        alt="story-img"
                        className="rounded-circle img-fluid"
                      />
                      <div className="stories-data ms-3">
                        <h5>Ira Membrit</h5>
                        <p className="mb-0">4 hour ago</p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center">
                      <img
                        src={s1}
                        alt="story-img"
                        className="rounded-circle img-fluid"
                      />
                      <div className="stories-data ms-3">
                        <h5>Bob Frapples</h5>
                        <p className="mb-0">9 hour ago</p>
                      </div>
                    </li>
                  </ul>
                  <Link to="#" className="btn btn-primary d-block mt-3">
                    See All
                  </Link>
                </Card.Body>
              </Card> */}
              <Card>
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Events</h4>
                  </div>
                  {/* <div className="card-header-toolbar d-flex align-items-center">
                    <Dropdown>
                      <Dropdown.Toggle
                        as={CustomToggle}
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        role="button"
                      >
                        <i className="ri-more-fill h4"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className=" dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <Dropdown.Item href="#">
                          <i className="ri-eye-fill me-2"></i>View
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i className="ri-delete-bin-6-fill me-2"></i>Delete
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i className="ri-pencil-fill me-2"></i>Edit
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i className="ri-printer-fill me-2"></i>Print
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i className="ri-file-download-fill me-2"></i>Download
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                </div>
                <Card.Body>
                  {/* <ul className="media-story list-inline m-0 p-0">
                    <li className="d-flex mb-4 align-items-center ">
                      <img
                        src={dentistupEvent}
                        alt="event"
                        style={{width: "7.75rem", height: "7.75rem"}}
                
                      />
                      <div className="stories-data ms-3">
                        <a target="_blank" rel="noreferrer" href="https://dentistup.tn/4eme-edition-de-lafrican-medical-tourism-congress-amtc-tunisia-investissement-et-cooperation-entre-les-pays-dafrique-et-du-moyen-orient/">4th edition of the African Medical Tourism Congress (AMTC Tunisia)</a>
                        <p className="mb-0">20 - 22 October</p>
                      </div>
                    </li>
                  </ul> */}

                    <ul className="media-story list-inline m-0 p-0">
            {events.map(event => (
                <li key={event.id} className="d-flex mb-4 align-items-center">
                    {event.eventInfo.eventImages.eventimage && (
                        <img
                        src={
                           `${
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                event.eventInfo.eventImages.eventimage
                              }`
                           
                        }
                           
                            alt="event"
                            style={{ width: "7.75rem", height: "7.75rem" }}
                        />
                    )}
                    <div className="stories-data ms-3">
                        <a target="_blank" rel="noreferrer" href={
                           `${
                              
                                "dashboards/app/event-details/" +
                                event._id
                              }`
                           
                        }>{event.eventInfo.title}</a>
                        {/* <p className="mb-0">{event.date}</p>  */}
                    </div>
                </li>
            ))}
        </ul>
                </Card.Body>
              </Card>
              <Card>
                <Card.Body>
                  <a href="#">
                    <img src={sponsoredAd} style={{ width: "21em" }} alt="dentistupAdvertisment"/>
                  </a>
                </Card.Body>
              </Card>
              {/* <Card>
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Upcoming Birthday</h4>
                  </div>
                </div>
                <Card.Body>
                  <ul className="media-story list-inline m-0 p-0">
                    <li className="d-flex mb-4 align-items-center">
                      <img
                        src={user01}
                        alt="story3"
                        className="rounded-circle img-fluid"
                      />
                      <div className="stories-data ms-3">
                        <h5>Anna Sthesia</h5>
                        <p className="mb-0">Today</p>
                      </div>
                    </li>
                    <li className="d-flex align-items-center">
                      <img
                        src={user2}
                        alt="story-img"
                        className="rounded-circle img-fluid"
                      />
                      <div className="stories-data ms-3">
                        <h5>Paul Molive</h5>
                        <p className="mb-0">Tomorrow</p>
                      </div>
                    </li>
                  </ul>
                </Card.Body>
              </Card>
              <Card>
                <div className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">Suggested Pages</h4>
                  </div>
                  <div className="card-header-toolbar d-flex align-items-center">
                    <Dropdown>
                      <Dropdown.Toggle as={CustomToggle}>
                        <i className="ri-more-fill h4"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="dropdown-menu-right"
                        aria-labelledby="dropdownMenuButton01"
                      >
                        <Dropdown.Item href="#">
                          <i className="ri-eye-fill me-2"></i>View
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i className="ri-delete-bin-6-fill me-2"></i>Delete
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i className="ri-pencil-fill me-2"></i>Edit
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i className="ri-printer-fill me-2"></i>Print
                        </Dropdown.Item>
                        <Dropdown.Item href="#">
                          <i className="ri-file-download-fill me-2"></i>Download
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
                <Card.Body>
                  <ul className="suggested-page-story m-0 p-0 list-inline">
                    <li className="mb-3">
                      <div className="d-flex align-items-center mb-3">
                        <img
                          src={img42}
                          alt="story-img"
                          className="rounded-circle img-fluid avatar-50"
                        />
                        <div className="stories-data ms-3">
                          <h5>Iqonic Studio</h5>
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </div>
                      <img
                        src={img9}
                        className="img-fluid rounded"
                        alt="Responsive"
                      />
                      <div className="mt-3">
                        <Link to="#" className="btn d-block">
                          <i className="ri-thumb-up-line me-2"></i> Like Page
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="d-flex align-items-center mb-3">
                        <img
                          src={img42}
                          alt="story-img"
                          className="rounded-circle img-fluid avatar-50"
                        />
                        <div className="stories-data ms-3">
                          <h5>Cakes & Bakes </h5>
                          <p className="mb-0">Lorem Ipsum</p>
                        </div>
                      </div>
                      <img
                        src={img10}
                        className="img-fluid rounded"
                        alt="Responsive"
                      />
                      <div className="mt-3">
                        <Link to="#" className="btn d-block">
                          <i className="ri-thumb-up-line me-2"></i> Like Page
                        </Link>
                      </div>
                    </li>
                  </ul>
                </Card.Body>
              </Card> */}
            </Col>
            <div className="col-sm-12 text-center">
              <img src={loader} alt="loader" style={{ height: "100px" }} />
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

Index.propTypes = {
  getPosts: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  post: PropTypes?.object?.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state?.post,
  auth: state?.auth,
});

export default connect(mapStateToProps, { getPosts, loadUser,getEvents })(Index);
