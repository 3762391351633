import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";


import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


const AdvancedSearch = ({profiles, posts, auth, followUser, loadUser}) => {



  function valuetext(value) {
    return `${value} Years old`;
  }
  const [rangeValue, setRangeValue] = React.useState([20, 37]);

  const handleRangeChange = (event, newRangeValue) => {
    setRangeValue(newRangeValue);
    performSearch();
  };


  const [searchCriteria, setSearchCriteria] = useState({
    patientReference: "",
    // dateOfBirth: "",
    reasonConsultation: "",
    gender: "",
    medicalHistory: "",
    dailyMedications: "",
    bloodPressure: "",
    pulse: "",
    respiration: "",
    dentalHistory: "",
    extraoralExamination: "",
    intraoralExamination: "",
    examenExoBuccal: "",
    dermato: "",
    symetrie: "",
    symetrieExplanation: "",
    examenAtmNormal: "",
    examenAtmDouleur: "",
    examenAtmClaquement: "",
    examenAtmAutre: "",
    examenAtmAutreExplanation: "",
    concernedTeeth: "",
    respirationType: "",
    detailsRespiration: "",
    mastication: "",
    detailsMastication: "",
    deglutition: "",
    detailsDeglutition: "",
    title: "",
    description: "",
    participants: "",
    casediagnostics: "",
    treatmentplan: "",
    visibility:"public",
    age: rangeValue
  });

  const handleSearchCriteriaChange = (field) => (e) => {
    const value = e.target.value;
    setSearchCriteria({
      ...searchCriteria,
      [field]: value,
    });
    performSearch();
  };









  const [profileSearchCriteria, setProfileSearchCriteria] = useState({
    bio: "",
    company: "",
    companyaddress:  "",
    companyphonecode :  "",
    companyphonenumber :  "",
    country :  "",
    // date:  "2023-08-25T14:14:48.471Z",
    // education :  [],
    // experience:  [],
    location:  "",
    phonecode : "",
    phonenumber:  "",
    skills :  "",
    speciality : "",
    state : "",
    status :  "",
    title :  "",
    name: "",
  });

  const handleProfileSearchCriteriaChange = (field) => (e) => {
    const value = e.target.value;
    setProfileSearchCriteria({
      ...profileSearchCriteria,
      [field]: value,
    });
    performProfilesSearch();
  };


  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };






 
  const [filteredPosts, setFilteredPosts] = React.useState([]);
  const [filteredProfiles, setFilteredProfiles] = React.useState([]);


  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    const age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      return age - 1;
    }
    return age;
  };

  const performSearch = () => {


    // const filteredPosts = posts.filter((post) => {
    //     // Initialize a flag to check if all non-empty criteria match
    //     let isMatch = true;
    
    //     // Iterate through the search criteria fields
    //     for (const field in searchCriteria) {
    //       // Check if the search criteria field is not empty
    //       if (searchCriteria[field] !== '' && searchCriteria[field]) {
    //         // Check if the post field matches the non-empty search criteria
    //         if (!post.postInfo[field]?.toLowerCase()?.includes(searchCriteria[field]?.toLowerCase())) {
    //           isMatch = false;
    //           break; // Break out of the loop if any non-empty criteria don't match
    //         }
    //       }
    //     }
    
    //     return isMatch; // Return true if all non-empty criteria match
    //   });
    //   setFilteredPosts(filteredPosts);
    const filteredPosts = posts.filter((post) => {
      // Initialize a flag to check if all non-empty criteria match
      let isMatch = true;
  
      // Iterate through the search criteria fields
      for (const field in searchCriteria) {
        // Check if the search criteria field is not empty
        if (searchCriteria[field] !== '' && searchCriteria[field]) {
          // Special handling for the age range
       
          if (field === 'age') {
           
            // const { minAge, maxAge } = rangeValue;
            const minAge = rangeValue[0];
            const maxAge = rangeValue[1];
            const patientAge = calculateAge(post.postInfo.dateOfBirth);
          
            // Check if the patient's age falls within the specified range
            const isAgeInRange = (!minAge || patientAge >= Number(minAge)) &&
                                (!maxAge || patientAge <= Number(maxAge));
  
            if (!isAgeInRange) {
              isMatch = false;
              break; // Break out of the loop if the age range doesn't match
            }
          } else {
            // Check if the post field matches the non-empty search criteria
            if (!post.postInfo[field]?.toLowerCase()?.includes(searchCriteria[field]?.toLowerCase())) {
              isMatch = false;
              break; // Break out of the loop if any non-empty criteria don't match
            }
          }
        }
      }
  
      return isMatch; // Return true if all non-empty criteria match
    });
  
    setFilteredPosts(filteredPosts);
};



const performProfilesSearch = () => {
  const filteredProfiles = profiles.filter((profile) => {
    // Initialize a flag to check if all non-empty criteria match
    let isMatch = true;

    // Iterate through the search criteria fields
    for (const field in profileSearchCriteria) {
      // Check if the search criteria field is not empty
      if (profileSearchCriteria[field] !== '' && profileSearchCriteria[field]) {
        // Check if the profile field contains the search criteria value (case-insensitive)
        const profileValue = (profile[field] || profile.user[field] || '').toString(); // Convert to a string or an empty string
        const searchValue = profileSearchCriteria[field].toLowerCase(); // Convert to lowercase for case-insensitive matching

        if (!profileValue.toLowerCase().includes(searchValue)) {
          isMatch = false;
          break; // Break out of the loop if any non-empty criteria don't match
        }
      }
    }

    return isMatch; // Return true if all non-empty criteria match
  });

  // The filteredProfiles array now contains profiles that match the search criteria
 
  setFilteredProfiles(filteredProfiles)
 

};

  return (
    <div className='advancedSearchContainer'>
       <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224 }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Posts" {...a11yProps(0)} />
        <Tab label="Profiles" {...a11yProps(1)} />
        {/* <Tab label="Profiles" {...a11yProps(2)} /> */}
        {/* <Tab label="Item Four" {...a11yProps(3)} />
        <Tab label="Item Five" {...a11yProps(4)} />
        <Tab label="Item Six" {...a11yProps(5)} />
        <Tab label="Item Seven" {...a11yProps(6)} /> */}
      </Tabs>
      <TabPanel value={value} index={0}>

        <div className='advancedSearchPostsContainer'>
<div className='advancedSearchPostsContainerForm'>
<Form>

<Form.Group className="mb-3">
<Form.Label>Title</Form.Label>
<Form.Control
name="title"
value={searchCriteria.title}
type="text"
placeholder="Write something"
onChange={handleSearchCriteriaChange('title')}
/>
</Form.Group>


<Form.Group className="mb-3">
<Form.Label>Description</Form.Label>
<Form.Control
name="description"
value={searchCriteria.description}
type="text"
placeholder="Write something"
onChange={handleSearchCriteriaChange('description')}
/>
</Form.Group>


<Form.Group className="mb-3" controlId="getGender">
<Form.Label>Age</Form.Label>
<Slider
  getAriaLabel={() => 'Age range'}
  value={rangeValue}
  onChange={handleRangeChange}
  valueLabelDisplay="auto"
  getAriaValueText={valuetext}
  name="age"
/>
<Form.Label>Age between {rangeValue[0]} and  {rangeValue[1]}</Form.Label>
</Form.Group>


<Form.Group className="mb-3" controlId="getGender">
<Form.Label>Gender</Form.Label>
<div>
<Form.Check
value=""
type="radio"
aria-label="All"
label="All"
onChange={handleSearchCriteriaChange('gender')}
checked={searchCriteria.gender === ''}
name="gender"
required
/>
<Form.Check
value="male"
type="radio"
aria-label="male"
label="Male"
onChange={handleSearchCriteriaChange('gender')}
checked={searchCriteria.gender === 'male'}
name="gender"
required
/>
<Form.Check
value="female"
type="radio"
aria-label="female"
label="Female"
onChange={handleSearchCriteriaChange('gender')}
checked={searchCriteria.gender === 'female'}
name="gender"
required
/>
</div>

</Form.Group>

<Form.Group className="mb-3">
<Form.Label>Symetrie</Form.Label>

<div>
<Form.Check
value=""
type="radio"
aria-label=""
label="All"
name="symetrie"

checked={searchCriteria.symetrie === ''}
onChange={handleSearchCriteriaChange('symetrie')}
/>

<Form.Check
value="ouiSymetrie"
type="radio"
aria-label="ouiSymetrie"
label="Yes"
name="symetrie"

checked={searchCriteria.symetrie === 'ouiSymetrie'}
onChange={handleSearchCriteriaChange('symetrie')}
/>
<Form.Check
   value="nonSymetrie"
   type="radio"
   aria-label="nonSymetrie"
   label="No"
name="symetrie"
checked={searchCriteria.symetrie === 'nonSymetrie'}
onChange={handleSearchCriteriaChange('symetrie')}
/>
</div>

</Form.Group>


{/* <Form.Group className="mb-3">
<Form.Label>Examen ATM Normal</Form.Label>
<Form.Check

type="checkbox"
aria-label="examenAtmNormal"
label="Normal"
name="examenAtmNormal"
value="true"
checked={searchCriteria.examenAtmNormal === "true"}
onChange={handleSearchCriteriaChange('examenAtmNormal')}
/>


<Form.Check
name="examenAtmPain"
type="checkbox"
aria-label="Pain"
label="Pain"

value="true"
checked={searchCriteria.examenAtmDouleur === "true"}

onChange={handleSearchCriteriaChange('examenAtmDouleur')}
/>



<Form.Check
type="checkbox"
aria-label="Snapping"
label="Snapping"
name="examenAtmSnapping"

value="true"
checked={searchCriteria.examenAtmClaquement === "true"}

onChange={handleSearchCriteriaChange('examenAtmClaquement')}
/>



<Form.Check
  type="checkbox"
  aria-label="Other"
  label="Other"
  value="true"
  checked={searchCriteria.examenAtmAutre === "true"}


onChange={handleSearchCriteriaChange('examenAtmAutre')}
/>
</Form.Group> */}

<Form.Group className="mb-3">
<Form.Label>Concerned Teeth</Form.Label>
<Form.Control
name="concernedTeeth"
value={searchCriteria.concernedTeeth}
type="text"
placeholder="Write something"
onChange={handleSearchCriteriaChange('concernedTeeth')}
/>
</Form.Group>



<Button onClick={performSearch}>Search</Button>

</Form>
</div>
<div className='advancedSearchPostsContainerResults'>
{filteredPosts.length > 0 &&   <p class="mb-0" style={{color: "#606060e0", marginTop:"20px"}} >Posts</p>}

{filteredPosts.map((post) => (
                  <div key={post._id} className="postSearchResultTopBar">
                    <Link to={`dashboard/app/post-details/${post._id}`} className="postsSearchResultsHeader">
                    <span to="/" className="material-symbols-outlined text-dark postIconSearch" >
    article
  </span>
                    <span>
                    {post.postInfo.title}{" "}
                    </span>
                      
                    </Link>
                  </div>
                ))}

{filteredPosts.length === 0  && <p class="mb-0" style={{color: "#606060e0", marginTop:"20px"}} >No results found</p>}
</div>
        </div>
     
    
      </TabPanel>
      <TabPanel value={value} index={1}>

<div className='advancedSearchProfilesContainer'>
<div className='advancedSearchProfilesContainerForm'>
<Form>


<Form.Group className="form-group">
                <Form.Label htmlFor="Name" className="form-label">
                  Name :
                </Form.Label>
                <div>
                <small className="form-text">
                Type in a name
    </small>
                </div>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  name="name"
                  value={searchCriteria.name}
                  onChange={handleProfileSearchCriteriaChange('name')}
                />
              </Form.Group>

<Form.Group className="form-group">
                <Form.Label htmlFor="Country" className="form-label">
                  Country :
                </Form.Label>
                <div>
                <small className="form-text">
                Type in a country (eg. Tunisia)
    </small>
                </div>
                <Form.Control
                  type="text"
                  placeholder="Country"
                  name="country"
                  value={searchCriteria.country}
                  onChange={handleProfileSearchCriteriaChange('country')}
                />
              </Form.Group>

<Form.Group className="form-group">
                <Form.Label htmlFor="state" className="form-label">
                  State :
                </Form.Label>
                <div>
                <small className="form-text">
      City & state suggested (eg. Monastir, Sousse)
    </small>
                </div>
                <Form.Control
                  type="text"
                  placeholder="State"
                  name="state"
                  value={searchCriteria.state}
                  onChange={handleProfileSearchCriteriaChange('state')}
                />
              </Form.Group>



              <Form.Group className="form-group">
              <Form.Label htmlFor="profileCover">
              Professional Position :
                </Form.Label>
<div>
<small className="form-text">Search for a professional position</small>
</div>
 
  <Form.Control as="select" name="status" value={searchCriteria.status}  onChange={handleProfileSearchCriteriaChange('status')}>
  <option value="">* Select Professional Position</option>
      <option value="Professor">Professor</option>
      <option value="Associate professor">Associate professor</option>
      <option value="Resident">Resident</option>
      <option value="Intern">Intern</option>
      <option value="Dental Clinic Owner">Dental Clinic Owner</option>
      <option value="Dentist in public sector">Dentist in public sector</option>
      <option value="Dental Student">Dental Student</option>
      
  </Form.Control>
</Form.Group>


<Form.Group className="form-group">
<Form.Label htmlFor="speciality">
Professional Speciality :
                </Form.Label>
                <div>
                <small className="form-text">Give us an idea of your professional specialty</small>
                </div>
  
  <Form.Control as="select" name="speciality" value={searchCriteria.speciality} onChange={handleProfileSearchCriteriaChange('speciality')}>
  <option value="">* Select Professional Speciality</option>
  <option value="Endodontics">Endodontics</option>
<option value="Periodontics">Periodontics</option>
<option value="Orthodontics">Orthodontics</option>
<option value="Implantology">Implantology</option>
<option value="Aesthetic">Aesthetic</option>
<option value="General Dentist">General Dentist</option>

    {/* Add other options */}
  </Form.Control>
</Form.Group>




<Form.Group className="form-group">
                <Form.Label htmlFor="skills" className="form-label">
                  Skills :
                </Form.Label>
                <div>
                <small className="form-text">
      Please use comma separated values (eg. skill1,skill2,skill3,skill4)
    </small>
                </div>
                <Form.Control
                  type="text"
                  placeholder="Skills"
                  name="skills"
                  value={searchCriteria.skills}
                  onChange={handleProfileSearchCriteriaChange('skills')}
                />
              </Form.Group>


<Button onClick={performProfilesSearch}>Search</Button>

</Form>
  </div>
  <div className='advancedSearchProfilesContainerResults'>
  {filteredProfiles.length === 0  && <p class="mb-0" style={{color: "#606060e0", marginTop:"20px"}} >No results found</p>}
  {filteredProfiles.length > 0 &&   <p class="mb-0" style={{color: "#606060e0", marginTop:"20px"}} >Profiles</p>}
        {filteredProfiles.map((profile, index) => (
  <div
    key={index}
    className="d-flex align-items-center border-bottom search-hover py-2 px-3"
  >
    
    <div className="flex-shrink-0">
      <img
        className="align-self-center img-fluid avatar-50 rounded-pill"
        src={
          profile?.profileImage
            ? `${
                process.env.REACT_APP_BASE_URL +
                "/" +
                profile?.profileImage
              }`
            : profile?.user?.avatar
        }
        alt="dentistupProfile"
        loading="lazy"
      />
    </div>

    <div className="d-flex flex-column ms-3">
      <Link
        to={`/dashboard/app/profile-feed/${profile?.user?._id}`}
        className="h5"
      >
        {profile?.user?.name}
      </Link>

      <span>{profile?.status}</span>
    </div>

    <div className="d-flex align-items-center ms-auto">
    {profile?.user?._id && profile?.user?._id !== auth?._id &&
                            auth?.following?.filter(
                              (following) => following?.user?.toString() === profile?.user?._id
                            ).length === 0 && (
                              <small type="submit" style={{color: "#346ac3"}}  onClick={(e) => {
                                e.preventDefault();
                                followUser(profile?.user?._id);
                                loadUser();
                                loadUser();
                                loadUser();
                              }}>
                        Follow
                      </small>
                            )}
{profile?.user?._id && profile?.user?._id !== auth?._id &&
                            auth?.following?.filter(
                              (following) => following?.user?.toString() === profile?.user?._id
                            ).length !== 0 && (
                              <small type="submit"    onClick={(e) => {
                                e.preventDefault();
                                followUser(profile?.user?._id);
                                loadUser();
                                loadUser();
                                loadUser();
                              }}>
                        Unfollow
                      </small>
                            )}

      {/* <Link to="/" className="material-symbols-outlined text-dark">
        close
      </Link> */}
    </div>
  </div>
))}
</div>
</div>

    
   
      </TabPanel>
      <TabPanel value={value} index={2}>
        Item Three
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel>
    </Box>
    </div>



    


    
  );
};

export default AdvancedSearch;
