import React, { Fragment, useEffect, useState } from "react";

// react-boostrap
import {Container, Col, Row, Dropdown,ProgressBar } from 'react-bootstrap'
import { useSelector } from "react-redux";
// components
import Card from '../../../components/Card'
import CustomToggle from '../../../components/dropdowns'
import {   OverlayTrigger,Modal, Tooltip,Form } from 'react-bootstrap';
// Datepicker
import Datepicker from '../../../components/datepicker'

// apex-chart
import Chart from "react-apexcharts"
import { getGroups } from "../../../actions/group";
import { getEvents } from "../../../actions/event";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AdminProfileItem from "./profileItem/AdminProfileItem";
const Admin = ({getGroups,getEvents}) =>{

    useEffect(() => {
        getGroups();
      }, [getGroups]); 

      useEffect(() => {
        getEvents();
      }, [getEvents]); 


      const [showMembers, setShowMembers] = useState(false);
      const handleCloseMembers = () => setShowMembers(false);
      const handleShowMembers = () => setShowMembers(true);

    const events = useSelector((state) => state?.event?.events);
    const posts = useSelector((state) => state?.posts?.posts);
    const profileData = useSelector((state) => state?.profile?.profiles);
    const groups = useSelector((state) => state?.group?.groups);
 

   const filteredProfiles = profileData.filter(
    profile => profile?.user?.verified === 'unverified' && profile?.proofPicture !== null
  );



    // Get the count of unverified profiles
    const unverifiedCount = filteredProfiles.length;


    const [monthlyCounts, setMonthlyCounts] = useState([]);
    useEffect(() => {
        if (profileData.length > 0) {
            const counts = {};
            profileData.forEach(profile => {
                const date = new Date(profile.date);
                const month = date.toLocaleString('default', { month: 'short' });
                counts[month] = (counts[month] || 0) + 1;
            });
            const monthlyCountsArray = Object.values(counts);
            setMonthlyCounts(monthlyCountsArray);
        }
    }, [profileData]);


    const [postsMonthlyCounts, setPostsMonthlyCounts] = useState([]);
    useEffect(() => {
        if (posts.length > 0) {
            const counts = {};
            posts.forEach(post => {
                const date = new Date(post.date);
                const month = date.toLocaleString('default', { month: 'short' });
                counts[month] = (counts[month] || 0) + 1;
            });
            const postsMonthlyCountsArray = Object.values(counts);
            setPostsMonthlyCounts(postsMonthlyCountsArray);
        }
    }, [posts]);

    const [countryFlags, setCountryFlags] = useState({});
    useEffect(() => {
        const fetchCountryFlags = async () => {
          try {
            const response = await fetch('https://restcountries.com/v2/all');
            if (!response.ok) {
              throw new Error('Failed to fetch country flags');
            }
            const data = await response.json();
            const flags = {};
            data.forEach(country => {
              flags[country.name] = country.flags.svg;
            });
            setCountryFlags(flags);
          } catch (error) {
            console.error('Error fetching country flags:', error);
          }
        };
    
        fetchCountryFlags();
      }, []);
    
      // Count users from each country
      const countryCounts = profileData.reduce((counts, user) => {
        counts[user.country] = (counts[user.country] || 0) + 1;
        return counts;
      }, {});
   
      // Map country counts to JSX elements
      const countryElements = Object.entries(countryCounts).map(([countryCode, count]) => (
        <Col lg="4" md="4" className="col-4" key={countryCode}>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center justify-content-center">
                <div className=" avatar-55 d-flex align-items-center justify-content-center">
                  {countryFlags[countryCode] && (
                    <img src={countryFlags[countryCode]} alt={countryCode} width="30" height="auto" />
                  )}
                </div>
              </div>
              <div className="mt-4 text-center">
                <h3>{count}</h3>
                <p className="mb-0">{`${countryCode}`}</p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ));


    
    const adminChart = {
        options: {
            colors: ["#50b5ff"],
                chart: {
                toolbar: {
                show: false
                },
            },
            forecastDataPoints: {
                count: 2,
            },
            stroke: {
                width: 3,
            },
            grid: {
                show:true,
                strokeDashArray: 7,
            },
            markers: {
                size: 6,
                colors:  '#FFFFFF',
                strokeColors: ["#50b5ff"],
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 0,
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                axisBorder: {
                show: false,      
                },
                axisTicks: {
                show: false,     
                },
                tooltip: {
                enabled: false,
                }
            },
        },
        series: [{
            name: 'Total Account',
            data: monthlyCounts 
        }],
    }


    const adminChartPostsDates = {
        options: {
            colors: ["#50b5ff"],
                chart: {
                toolbar: {
                show: false
                },
            },
            forecastDataPoints: {
                count: 2,
            },
            stroke: {
                width: 3,
            },
            grid: {
                show:true,
                strokeDashArray: 7,
            },
            markers: {
                size: 6,
                colors:  '#FFFFFF',
                strokeColors: ["#50b5ff"],
                strokeWidth: 2,
                strokeOpacity: 0.9,
                strokeDashArray: 0,
                fillOpacity: 0,
                shape: "circle",
                radius: 2,
                offsetX: 0,
                offsetY: 0,
            },
            xaxis: {
                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
                axisBorder: {
                show: false,      
                },
                axisTicks: {
                show: false,     
                },
                tooltip: {
                enabled: false,
                }
            },
        },
        series: [{
            name: 'Total Posts',
            data: postsMonthlyCounts 
        }],
    }


    const totalPosts = posts.length;
    const maleCount = posts.filter(post => post?.postInfo?.gender === 'male').length;
    const femaleCount = totalPosts - maleCount;
    const malePercentage = Math.round((maleCount / totalPosts) * 100);
    const femalePercentage = Math.round((femaleCount / totalPosts) * 100);

   


    const likesPercentages = posts
    .filter(post => post.postInfo.visibility === 'public') // Filter only public posts
    .map(post => {
      const totalLikes = post?.likes?.length ?? 0;
      const totalComments = post?.comments?.length ?? 0;
      let likesPercentage = Math.round((totalLikes / (totalLikes + totalComments)) * 100);
  
      // Check if likesPercentage is NaN and set it to 0
      if (isNaN(likesPercentage)) {
        likesPercentage = 0;
      }
  
      return likesPercentage;
    });
  
  const commentsPercentages = posts
    .filter(post => post.postInfo.visibility === 'public') // Filter only public posts
    .map(post => {
      const totalLikes = post?.likes?.length ?? 0;
      const totalComments = post?.comments?.length ?? 0;
      let commentsPercentage = Math.round((totalComments / (totalLikes + totalComments)) * 100);
  
      // Check if commentsPercentage is NaN and set it to 0
      if (isNaN(commentsPercentage)) {
        commentsPercentage = 0;
      }
  
      return commentsPercentage;
    });
  
  // Calculate the average percentages separately
  const avgLikesPercentage = Math.round(likesPercentages.reduce((acc, val) => acc + val, 0) / likesPercentages.length);
  const avgCommentsPercentage = Math.round(commentsPercentages.reduce((acc, val) => acc + val, 0) / commentsPercentages.length);

  const adminChart1 = {
    options: {
        colors: ["#50b5ff", "#d592ff"],
        plotOptions: {
            radialBar: {
                inverseOrder: false,
                endAngle: 360,
                hollow: {
                    margin: 5,
                    size: '50%',
                    background: 'transparent',
                    imageWidth: 150,
                    imageHeight: 150,
                    imageClipped: true,
                    position: 'front',
                    dropShadow: {
                      enabled: false,
                      blur: 3,
                      opacity: 0.5
                    }
                },
                track: {
                    show: true,
                    background: '#f2f2f2',
                    strokeWidth: '70%',
                    opacity: 1,
                    margin: 6,
                    dropShadow: {
                        enabled: false,
                        blur: 3,
                        opacity: 0.5
                    }
                },
                dataLabels: {
                    show: true,
                    name: {
                        show: true,
                        fontSize: '16px',
                        fontWeight: 600,
                        offsetY: -10
                      },
                      value: {
                        show: true,
                        fontSize: '14px',
                        fontWeight: 400,
                        offsetY: 16,
                        formatter: function (val) {
                          return val + '%'
                        }
                    },
                }
            }
        },
        labels: ['Percantage of likes', 'Percantage of comments']
    },
    series: [avgLikesPercentage, avgCommentsPercentage],
}  




    const adminChart2 = {
        options: {
          legend: {
            show: true,
            position: 'bottom',
            horizontalAlign: 'center'
          },
          labels: ['Males', 'Females'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                show: false
              }
            }
          }]
          },
          series: [malePercentage, femalePercentage],
    }
    return(
        <>
        <div id='content-page' className='content-page'>
            <Container>
                <Row as="ul" className="list-unstyled mb-0">
                    <Col md="6" lg="3" as="li">
                        <Card>
                            <Card.Body>
                                <div className="points">
                                    <span>Total Posts</span>
                                    <div className="d-flex align-items-center">
                                        <h3>{posts?.length}</h3>
                                        {/* <small className="text-success ms-3">+ 57</small> */}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" lg="3" as="li">
                        <Card>
                            <Card.Body>
                                <div className="points">
                                    <span>Total Groups</span>
                                    <div className="d-flex align-items-center">
                                    <h3>{groups?.length}</h3>
                                        {/* <small className="text-danger ms-3">- 12,562</small> */}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6" lg="3" as="li">
                        <Card>
                            <Card.Body>
                                <div className="points">
                                    <span>Total Events</span>
                                    <div className="d-flex align-items-center">
                                    <h3>{events?.length}</h3>
                                        {/* <small className="text-success ms-3">+ 1,056</small> */}
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>




                  






<Modal show={showMembers} onHide={handleCloseMembers} size="lg" style={{ marginTop: "9rem"}}>

<Modal.Body>
{filteredProfiles.length > 0  ? (
filteredProfiles.map(
(profile) =>
profile?.user && (
   
<AdminProfileItem key={profile?._id} profile={profile} proofPicture={profile?.proofPicture}/>
)

)
) : (
<h4>No Requests found...</h4>
)}
</Modal.Body>
</Modal>
                    <Col md="6" lg="3" as="li" onClick={handleShowMembers} style={{ cursor: "pointer"}}>
                        <Card>
                            <Card.Body>
                                <div className="points">
                                    <span>Total Users</span>
                                    <div className="d-flex align-items-center">
                                    <h3>{profileData?.length}</h3>
                                        <small className="text-success ms-3">{unverifiedCount} Accounts needs review</small>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="8">
                        <Card className="card-block card-stretch card-height">
                            <Card.Header>
                                <h4 className="card-title">New Accounts</h4>
                                {/* <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} variant="text-secondary">
                                    This year
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-end">
                                        <li><Dropdown.Item href="#">Year</Dropdown.Item></li>
                                        <li><Dropdown.Item href="#">Month</Dropdown.Item></li>
                                        <li><Dropdown.Item href="#">Week</Dropdown.Item></li>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                            </Card.Header>
                            <Card.Body>
                                <Chart options={adminChart.options} series={adminChart.series} type="line" height="198"  />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Row>
                        {countryElements}
                  
                        </Row>
                    </Col>
                    <Col lg="12">
                        <Card className="card-block card-stretch card-height">
                            <Card.Header>
                                <h4 className="card-title">Cases</h4>
                                {/* <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} variant="text-secondary">
                                    This year
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-end">
                                        <li><Dropdown.Item href="#">Year</Dropdown.Item></li>
                                        <li><Dropdown.Item href="#">Month</Dropdown.Item></li>
                                        <li><Dropdown.Item href="#">Week</Dropdown.Item></li>
                                    </Dropdown.Menu>
                                </Dropdown> */}
                            </Card.Header>
                            <Card.Body>
                                <Chart options={adminChartPostsDates.options} series={adminChartPostsDates.series} type="line" height="198"  />
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card>
                            <Card.Header>
                                <div className="header-title">
                                    <h4 className="card-title">Cases Interactions</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="flex-wrap d-flex align-items-center justify-content-between">
                                    <Col md="4" lg="4" className="d-grid gap gap-3">
                                        <div className="d-flex align-items-start">
                                            <i className="icon material-symbols-outlined filled text-primary mt-1">
                                                fiber_manual_record
                                            </i>
                                            <div className="ms-2" style={{lineHeight: "1.5"}}>
                                                <span className="mb-3">Likes</span>
                                                <h6 className="mb-0">{avgLikesPercentage}%</h6>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-start">
                                            <i className="icon material-symbols-outlined filled text-info mt-1">
                                                fiber_manual_record
                                            </i>
                                            <div className="ms-2" style={{lineHeight: "1.5"}}>
                                                <span className="mb-3">Comments</span>
                                                <h6 className="mb-0">{avgCommentsPercentage}%</h6>
                                            </div>
                                        </div>
                                    </Col>
                                    <Chart options={adminChart1.options} className="col-md-8 col-lg-8" series={adminChart1.series}   height="200" type="radialBar"/>
                                    {/* <div id="admin-chart-03" className="col-md-8 col-lg-8 admin-chart-03"></div> */}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                   
                    {/* <Col lg="8">
                        <Card className="card-block card-stretch card-height">
                            <Card.Header>
                                <h4 className="card-title">Worldwide Users</h4>
                                <Dropdown>
                                    <Dropdown.Toggle as={CustomToggle} variant="text-secondary">
                                        This year
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu-end">
                                        <li><Dropdown.Item href="#">Year</Dropdown.Item></li>
                                        <li><Dropdown.Item href="#">Month</Dropdown.Item></li>
                                        <li><Dropdown.Item href="#">Week</Dropdown.Item></li>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Card.Header>
                            <Card.Body>
                                <iframe title="googlemap" className="w-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3902543.2003194243!2d-118.04220880485131!3d36.56083290513502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80be29b9f4abb783%3A0x4757dc6be1305318!2sInyo%20National%20Forest!5e0!3m2!1sen!2sin!4v1576668158879!5m2!1sen!2sin" height="280" allowFullScreen=""></iframe>
                            </Card.Body>
                        </Card>
                    </Col> */}
                    {/* <Col lg="4" md="6">
                        <Card>
                            <Card.Header>
                                <div className="header-title">
                                    <h4 className="card-title">Calendar</h4>
                                </div>
                            </Card.Header>
                            <Card.Body className="text-center">
                                <div className="input-group d-block">
                                    <Datepicker className="vanila-datepicker" />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col> */}
                    {/* <Col lg="4" md="6">
                        <Card>
                            <Card.Header>
                                <div className="header-title">
                                    <h4 className="card-title">Categories</h4>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <div className="mb-3">
                                    <div className="d-flex justify-content-between mt-2 text-dark">
                                        <h6>Video hosting</h6>
                                        <small>62%</small>
                                    </div>
                                    <ProgressBar variant="danger" className="mt-2" now={62} style={{height: "6px"}}/>
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex justify-content-between mt-2 text-dark">
                                        <h6>Image sharing</h6>
                                        <small>46%</small>
                                    </div>
                                    <ProgressBar variant="info" className="mt-2" now={46} style={{height: "6px"}}/>
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex justify-content-between mt-2 text-dark">
                                        <h6>Community blogs</h6>
                                        <small>79%</small>
                                    </div>
                                    <ProgressBar variant="primary" className="mt-2" now={79} style={{height: "6px"}}/>
                                </div>
                                <div className="mb-3">
                                    <div className="d-flex justify-content-between mt-2 text-dark">
                                        <h6>Stories</h6>
                                        <small>34%</small>
                                    </div>
                                    <ProgressBar variant="success" className="mt-2" now={34} style={{height: "6px"}}/>
                                </div>
                                <div className="">
                                    <div className="d-flex justify-content-between mt-2 text-dark">
                                        <h6>Bookmarking</h6>
                                        <small>95%</small>
                                    </div>
                                    <ProgressBar variant="warning" className="mt-2" now={95} style={{height: "6px"}}/>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col> */}
                    <Col lg="6">
                        <Card>
                            <Card.Header>
                                <div className="header-title">
                                    <h4 className="card-title">Cases Genders</h4>
                                </div>
                            </Card.Header>
                            <Card.Body className="text-center">
                            <Chart options={adminChart2.options} className="col-md-8 col-lg-8" series={adminChart2.series}   width="290" type="pie"/>
                                {/* <p className="mb-0 mt-3">58% of friends that visit your profile comment on your posts.</p> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </div>
        </>
    )
} 



Admin.propTypes = {
    getGroups: PropTypes.func.isRequired,
    getEvents: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = (state) => ({
    // group: state?.post,
  });
  
  export default connect(mapStateToProps, { getGroups,getEvents })(Admin);