import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, Dropdown, Button, OverlayTrigger,Modal, Tooltip,Form } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import ProfileHeader from '../../../components/profile-header'
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { connect } from "react-redux";
//image
import img1 from '../../../assets/images/page-img/gi-1.jpg'
import small1 from '../../../assets/images/small/07.png'
import header from '../../../assets/images/page-img/profile-bg7.jpg'
import img7 from "../../../assets/images/page-img/profile-bg7.jpg";
import img3 from "../../../assets/images/page-img/profile-bg3.jpg";
import eventimg from "../../../assets/images/page-img/group-img.jpg";

import PostItem from "../posts/PostItem";
import { getEvent, deleteEvent, acceptRequest,rejectRequest } from "../../../actions/event";
import { getPosts } from "../../../actions/post";
import FormWizard from "../from/form-wizard";
import EventProfileItem from "./profileItem/GroupProfileItem";
import ProfileItem from "./profileItem/ProfileItem";
import CreateEvent from "./createEvent";
const EventDetail = ({getEvent, deleteEvent, event, auth, getPosts, profile: { profiles, loading }}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showRequests, setShowRequests] = useState(false);
    const handleCloseRequests = () => setShowRequests(false);
    const handleShowRequests = () => setShowRequests(true);

    const [showMembers, setShowMembers] = useState(false);
    const handleCloseMembers = () => setShowMembers(false);
    const handleShowMembers = () => setShowMembers(true);

    const [showEdit, setShowEdit] = useState(false);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = () => setShowEdit(true);


    const { pathname } = window.location;
    const id = pathname.split("/").pop();
    useEffect(() => {
        getEvent(id);
      },[getEvent,id]);

      
      const posts = useSelector((state) => state.posts.posts);
      useEffect(() => {
        getPosts();
      }, [getPosts]); 
      


      const requestProfiles = profiles?.filter(
        (profile) =>
          profile?.user &&
          profile?.user?._id?.includes(event?.pendingRequests)
      );

      const memberProfiles = profiles?.filter(
        (profile) =>
          profile?.user &&
          profile?.user?._id?.includes(event?.members)
      );


    return (
        <>
             <ProfileHeader  img={
                    event?.eventInfo?.eventImages?.eventbackgroundimage
                      ? `${process.env.REACT_APP_BASE_URL + "/" + event?.eventInfo?.eventImages?.eventbackgroundimage}`
                      : img3
                  } title="Events" />
                <div id="content-page" className="content-page">
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="d-flex align-items-center justify-content-between mb-3 flex-wrap">
                                    <div className="event-info d-flex align-items-center">
                                        <div className="me-3">
                                            <img className="rounded-circle img-fluid avatar-100" src={
                    event?.eventInfo?.eventImages?.eventimage
                      ? `${process.env.REACT_APP_BASE_URL + "/" + event?.eventInfo?.eventImages?.eventimage}`
                      : eventimg
                  } alt=""/>
                                        </div>
                                        <div className="info">
                                            <h4>{event?.eventInfo?.title}</h4>
                                            <p className="mb-0"><i className="ri-lock-fill pe-2"></i>{event?.eventInfo?.privacy} Event . {event?.members?.length} attendees</p>
                                        </div>
                                    </div>
                                    <div mt-md="0" mt="2" className="event-member d-flex align-items-center">
                                        <div className="iq-media-event me-3">
                                        {requestProfiles.length > 0 && event?.members?.length > 0 ? (
              memberProfiles.map(
                (profile) =>
                  profile.user && (
                    <Link to="#" className="iq-media">
                    <img className="img-fluid avatar-40 rounded-circle" src={
                          profile?.profileImage
                            ? `${
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                profile?.profileImage
                              }`
                            : profile?.user?.avatar
                        } alt=""/>
                </Link>
                  )
              )
            ) : (
                <div></div>
            
            )}
                                          
                                     
                                        </div>
                                        <Button onClick={handleShowMembers} variant="primary" className="mb-2">View Attendees</Button>



                                        <Modal show={showEdit} onHide={handleCloseEdit} size="lg" style={{ marginTop: "9rem"}}>
                                       
                                       <Modal.Body>
                                      <CreateEvent editEventMode={true} eventToEdit={event?.eventInfo} eventToEditId={event?._id}/>
                                       </Modal.Body>
                                   </Modal>


                                        <Modal show={showMembers} onHide={handleCloseMembers} size="lg" style={{ marginTop: "9rem"}}>
                                       
                                       <Modal.Body>
                                       {memberProfiles.length > 0 && event?.members?.length > 0 ? (
              memberProfiles.map(
                (profile) =>
                  profile.user && (
                    <ProfileItem key={profile._id} profile={profile} eventId={event._id}/>
                  )
              )
            ) : (
                <h4>No Members found...</h4>
            )}
                                       </Modal.Body>
                                   </Modal>


                                        <Modal show={showRequests} onHide={handleCloseRequests} size="lg" style={{ marginTop: "9rem"}}>
                                       
                                       <Modal.Body>
                                       {requestProfiles.length > 0 && event?.pendingRequests?.length > 0 ? (
              requestProfiles.map(
                (profile) =>
                  profile.user && (
                    <EventProfileItem key={profile._id} profile={profile} eventId={event._id} getEvent={getEvent}/>
                  )
              )
            ) : (
                <h4>No Requests found...</h4>
            )}
                                       </Modal.Body>
                                   </Modal>
                                    </div>
                                </div>
                            </Col>
                            <Col lg="8">
                                <Card id="post-modal-data">
                                    <Card.Header className="d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Event Description</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <div className="d-flex align-items-center">
                                        
                                        </div>
                                        
                                   
                                    </Card.Body>
                             
                                </Card>
                                <Card>
                                    <Card.Body>
                                

                                    <p className="mb-0">{event?.eventInfo?.description}</p>


                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg="4">
                                <Card>
                                    <Card.Header className="card-header d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">Events</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <ul className="list-inline p-0 m-0">
                                            {/* <li className="mb-3 pb-3 border-bottom">
                                                <div className="iq-search-bar members-search p-0">
                                                    <form action="#" className="searchbox w-auto">
                                                        <input type="text" className="text search-input bg-grey" placeholder="Type here to search..."/>
                                                        <Link className="search-link" to="#"><i className="ri-search-line"></i></Link>
                                                    </form>
                                                </div>
                                            </li> */}
                                            <li className="mb-3 d-flex align-items-center">
                                                <div className="avatar-40 rounded-circle bg-gray d-flex align-items-center justify-content-center me-3">
                                                    <i className="material-symbols-outlined">credit_card</i>
                                                </div>
                                                <h6 className="mb-0">Your Feed</h6>
                                            </li>
                                            <li className="mb-3 d-flex align-items-center">
                                                <div className="avatar-40 rounded-circle bg-gray d-flex align-items-center justify-content-center me-3">
                                                    <i className="material-symbols-outlined">explore</i>
                                                </div>
                                                <h6 className="mb-0">Discover</h6>
                                            </li>
                                            { event?.user === auth?.user?._id  && 
                         <li>
                            <button  onClick={handleShowRequests} className="btn btn-primary d-block w-100">View Requests</button>
                            
                            <button style={{margin: "1em 0"}} onClick={handleShowEdit} className="btn btn-success d-block w-100">Edit Event</button>
                           
                         <button type="submit" onClick={(e) => {
                            e.preventDefault();
                          deleteEvent(id);
    
                        }} className="btn btn-danger d-block w-100">
                            Delete Event
                         </button>
                         
                     </li> }
                                          
                                        </ul>
                                    </Card.Body>
                                </Card>
                                <Card>
                                    <Card.Header className="card-header d-flex justify-content-between">
                                        <div className="header-title">
                                            <h4 className="card-title">About</h4>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <ul className="list-inline p-0 m-0">
                                      
                                            <li className="mb-3">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <i className="material-symbols-outlined">lock</i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6>Private</h6>
                                                        <p className="mb-0">Success in slowing economic activity.</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li className="mb-3">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <i className="material-symbols-outlined">visibility</i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6>Visible</h6>
                                                        <p className="mb-0">Various versions have evolved over the years</p>
                                                    </div>
                                                </div>
                                            </li>
                                            <li >
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <i className="material-symbols-outlined">event</i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h6>General event</h6>
                                                        <p className="mb-0">There are many variations of passages</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                    </div> 
        </>
    )
}

EventDetail.propTypes = {
    getEvent: PropTypes.func.isRequired,
    deleteEvent: PropTypes.func.isRequired,
    event: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    post: PropTypes?.object?.isRequired,
    getPosts: PropTypes.func.isRequired,
    profile: PropTypes.object.isRequired,
  };
  const mapStateToProps = (state) => ({
    event: state.event.event,
    auth: state.auth,
    post: state?.post,
    profile: state.profile,
  });
  export default connect(mapStateToProps, {
    getEvent,
    deleteEvent,
    getPosts,
  })(EventDetail);
