import React, { Fragment, useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import Card from "../../../components/Card";
import { Link } from "react-router-dom";
import ProfileHeader from "../../../components/profile-header";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CreateEvent from "./createEvent";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// images
import img7 from "../../../assets/images/page-img/profile-bg7.jpg";
import img3 from "../../../assets/images/page-img/profile-bg3.jpg";
import eventimg from "../../../assets/images/page-img/group-img.jpg";
import { getEvents } from "../../../actions/event";
import { requestToJoinEvent } from "../../../actions/event";
import { leaveEvent } from "../../../actions/event";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50vw",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};
const Events = ({getEvents,events,requestToJoinEvent,leaveEvent, auth}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getEvents();
  },[getEvents]);
  console.log("events 0", events[0]);
  console.log("pending",events[0]?.pendingRequests?.filter(
    (member) => member)=== auth?.user?._id===false);
    console.log("members",events[0]?.members?.filter(
      (member) => member)=== auth?.user?._id===false);
console.log("wtf bruh", events[0]?.pendingRequests?.filter(
  (member) => member === auth?.user?._id
)===false && events[0]?.members?.filter(
  (member) => member === auth?.user?._id
)===false)
  return (
    <>
      <ProfileHeader img={img7} title="Events" />
      <Button onClick={handleOpen} style={{float:"right", margin: "2em"}}>
Create Event
          </Button>
      <div id="content-page" className="content-page">
        <Container>

          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         <CreateEvent/>
        </Box>
      </Modal>
          <div className="d-grid gap-3 d-grid-template-1fr-19">
          {events.map((event) => (
  <Card className="mb-0" key={event._id}>
    <div className="top-bg-image">
      <img src={event?.eventInfo?.eventImages?.eventbackgroundimage ? `${
                                process.env.REACT_APP_BASE_URL +
                                "/" +
                                event?.eventInfo?.eventImages?.eventbackgroundimage
                              }` : img3} className="img-fluid w-100" alt="event-bg" />
    </div>
    <Card.Body className="text-center">
      <div className="event-icon">
        <img
          src={event?.eventInfo?.eventImages?.eventimage ? `${
            process.env.REACT_APP_BASE_URL +
            "/" +
            event?.eventInfo?.eventImages?.eventimage
          }` : eventimg}
          alt="profile-img"
          className="rounded-circle img-fluid avatar-120"
        />
      </div>
      <div className="event-info pt-3 pb-3">
        <h4>
          {/* <Link to={`/dashboards/app/event-details/${event._id}`}>
           
          </Link> */}
          {event?.eventInfo?.title}
        </h4>
        <p>{event?.eventInfo?.description}</p>
      </div>
      <div className="event-details d-inline-block pb-3">
        <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0">
          <li className="pe-3 ps-3">
            <p className="mb-0">Posts</p>
            <h6>{event?.posts?.length}</h6>
          </li>
          <li className="pe-3 ps-3">
            <p className="mb-0">Members</p>
            <h6>{event?.members?.length}</h6>
          </li>
          {/* <li className="pe-3 ps-3">
            <p className="mb-0">Visits</p>
            <h6>{event?.visits}</h6>
          </li> */}
        </ul>
      </div>
      {/* <div className="event-member mb-3">
        <div className="iq-media-event">
          {event?.members?.slice(0, 6).map((member) => (
            <Link to="#" className="iq-media" key={member?._id}>
              <img
                className="img-fluid avatar-40 rounded-circle"
                src={member?.avatar}
                alt=""
              />
            </Link>
          ))}
        </div>
      </div> */}

{(() => {
  if (event._id && (event?.user === auth?.user?._id || event?.members?.includes(auth?.user?._id))) {
    return (
      <Link to={`/dashboards/app/event-details/${event._id}`}>
        <button className="btn btn-primary d-block w-100">Visit Event</button>
      </Link>
    );
  } else if (event._id && event?.pendingRequests?.includes(auth?.user?._id)) {
    return (
      <button className="btn btn-secondary d-block w-100" disabled>
        Request sent
      </button>
    );
  } else  {
    return (
      <button
        className="btn btn-success d-block w-100"
        onClick={(e) => {
          e.preventDefault();
          requestToJoinEvent(event._id);
          getEvents();
          getEvents();
        }}
      >
        Join
      </button>
    );
  }
  
})()}

      {/* {event._id && 
                            (event?.user === auth?.user?._id || event?.members?.filter(
                              (member) => member === auth?.user?._id
                            ).length !== 0) && (
                              <Link to={`/dashboards/app/event-details/${event._id}`}>
                                <button className="btn btn-primary d-block w-100" >
                              Visit Event
                            </button>
                            </Link>
                            
                            )}
    {event._id  &&
                            event?.pendingRequests?.filter(
                              (member) => member === auth?.user?._id
                            ).length !== 0 && (
                              <button className="btn btn-secondary d-block w-100" disabled>
                              Request sent
                            </button>
                            )}

{event._id  &&
                            (event?.pendingRequests?.some((member) => member !== auth?.user?._id) &&
                            event?.members?.some((member) => member !== auth?.user?._id)) &&(
                              <button className="btn btn-success d-block w-100" onClick={(e)=>{ e.preventDefault(); requestToJoinEvent(event._id);getEvents();getEvents() }}>
                              Join
                            </button>
                            )} */}
                                {/* <button className="btn btn-secondary d-block w-100" onClick={(e)=>{ e.preventDefault(); requestToJoinEvent(event._id) }}>
                              Join
                            </button> */}
    
    </Card.Body>
  </Card>
))}

          </div>
        </Container>
      </div>
    </>
  );
};
Events.propTypes = {
  getEvents: PropTypes.func.isRequired,
  leaveEvent: PropTypes.func.isRequired,
  requestToJoinEvent: PropTypes.func.isRequired,
  events: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  events: state.event.events,
  auth: state.auth
});
export default connect(mapStateToProps, {
  getEvents,
  leaveEvent,
  requestToJoinEvent,
})(Events);
