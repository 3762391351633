import React, { useState, Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addComment } from "../../../actions/post";

// import { Editor } from "react-draft-wysiwyg";
// import { EditorState } from "draft-js";
// import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const CommentForm = ({ postId, addComment, getPost }) => {
  // const [text, setText] = useState("");
  const [formData, setFormData] = useState({
    diagnostic: "",
    treatment: "",
  });

  const handleFormData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const handleReset = () => {
    setFormData({
      diagnostic: "",
      treatment: "",
    });
  };
  console.log("postId??", postId);
  return (
    <form
    className="post-text ms-3 w-100 "
      onSubmit={(e) => {
        e.preventDefault();

        addComment(postId, { formData });
        handleReset();
        getPost(postId);
        getPost(postId);
      }}
    >
      <h4>Leave a Comment should you feel like it!</h4>
      <div>
        <textarea
       
         className="form-control rounded"
          name="diagnostic"
          cols="20"
          rows="3"
          placeholder="Diagnostic"
          value={formData.diagnostic}
          onChange={handleFormData("diagnostic")}
          style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
          required
        />
      </div>

      {/* <textarea
            name="treatment"
            cols="30"
            rows="5"
            placeholder="Treatment"
            value={formData.treatment}
            onChange={handleFormData("treatment")}
            required
          ></textarea> */}
      {/* <Editor /> */}
      <div>
        <input
          type="submit"
          className="next action-button btn btn-primary"
          value="Submit"
         
        />
      </div>
    </form>
  );
};

CommentForm.propTypes = {
  addComment: PropTypes.func.isRequired,
};

export default connect(null, { addComment })(CommentForm);
