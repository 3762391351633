// import { createStore, applyMiddleware } from "redux";
// import { composeWithDevTools } from "redux-devtools-extension";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers";

// const initialState = {};

// const middleware = [thunk];

// const store = createStore(
//   rootReducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleware))
// );

// export default store;

import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import settingReducer from "./setting/reducers";
import postReducer from "./reducers/post";
import authReducer from "./reducers/auth";
import profileReducer from "./reducers/profile";
import groupReducer from "./reducers/group";
import eventReducer from "./reducers/event";
import generateReducer from "./reducers/generate";
import alertReducer from "./reducers/alert";
const rootReducer = combineReducers({
  setting: settingReducer,
  posts: postReducer,
  auth: authReducer,
  profile: profileReducer,
  group: groupReducer,
  event: eventReducer,
  generate: generateReducer,
alert: alertReducer
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
