//router
// import IndexRouters from "./router/index"
import React, { Fragment, useEffect, useState } from "react";
//scss
import "./assets/scss/socialv.scss";
import "./assets/scss/customizer.scss";
import "./assets/scss/App.css";
// Redux Selector / Action
import { useDispatch } from "react-redux";
import {Link, useNavigate} from 'react-router-dom'
// import state selectors
import { setSetting } from "./store/setting/actions";

//MOVED
import store from "./store/store";
import { loadUser } from "./actions/auth";
import { getPosts } from "./actions/post";
import setAuthToken from "./utils/setAuthToken";
import { Navigate } from "react-router-dom";
import { Alert } from "react-bootstrap";
import { useSelector } from "react-redux";

import { setAlert } from "./actions/alert";
// const mainAuth = localStorage.getItem("auth");
// if (mainAuth.length > 2 && mainAuth !=="undefined" && mainAuth){
//   localStorage.setItem("secondAuth", mainAuth)
// }

if (localStorage.token) {
  setAuthToken(localStorage.token);
} else {
  <Navigate to="/auth/sign-in" />;
}

//MOVED

function App(props) {
  //MOVED
  const [updatedToken, setUpdatedToken] = useState(true);
  useEffect(() => {
    store.dispatch(loadUser());
    store.dispatch(getPosts());
    console.log("store?", store.getState());
  }, [store]);
  //MOVED

  const dispatch = useDispatch();
  dispatch(setSetting());

  const alert = useSelector((state) => state?.alert);


  const currentUser = useSelector((state) => state?.auth?.user?._id);
  const currentUserVerified = useSelector((state) => state?.auth?.user?.verified);
// console.log("whats my user??", currentUserr);

const allUsers = useSelector((state) => state?.profile?.profiles);
// console.log("all users??", allUsers);
// console.log("is my profile filled?", allUsers.some(e => e?.user?._id === currentUser));

function isCurrentPageMatching() {
  const currentPathname = window.location.pathname;
  const desiredPaths = [
    '/dashboard/app/user-privacy-setting',
    '/dashboard/app/user-cookies-setting',
    '/dashboard/app/user-account-setting'
  ];
 
  return desiredPaths.some(e => e.toLowerCase() === currentPathname.toLowerCase());
}
  return (
    <div className="App">
      {/* <IndexRouters /> */}
         <div className="alertContainer" > 


         {/* <Alert variant={`alert alert-solid alert-success d-flex align-items-center gap-2 alert-dismissible fade show mb-3`} className="mb-3" show={true} role="alert">
                              <span><i className="material-symbols-outlined">notifications</i></span>
                              <span> Please fill in your profile information in your <a href="/dashboard/app/user-account-setting">Profile Settings</a></span>
                           </Alert> */}

{!isCurrentPageMatching() && currentUser &&  !allUsers.some(e => e?.user?._id === currentUser) &&     <Alert variant={`alert alert-solid alert-success d-flex align-items-center gap-2 alert-dismissible fade show mb-3`} className="mb-3" show={true} role="alert">
                              <span><i className="material-symbols-outlined">notifications</i></span>
                              <span> Please fill in your profile information in your <a href="/dashboard/app/user-account-setting">Profile Settings</a></span>
                           </Alert>}
 
                           {!isCurrentPageMatching() && currentUser && allUsers.some(e => e?.user?._id === currentUser) && (currentUserVerified !== "verified")  && <Alert variant={`alert alert-solid alert-success d-flex align-items-center gap-2 alert-dismissible fade show mb-3`} className="mb-3" show={true} role="alert">
                              <span><i className="material-symbols-outlined">notifications</i></span>
                              <span> Once we verify your identity, you will be notified by Email and you will be able to interact with the community!</span>
                           </Alert>}


        <Alert variant={`alert alert-solid alert-${alert[0]?.alertType} d-flex align-items-center gap-2 alert-dismissible fade show mb-3`} className="mb-3" show={alert[0]?.msg?.length > 2 ? true : false} role="alert">
                              <span><i className="material-symbols-outlined">notifications</i></span>
                              <span> {alert[0]?.msg}</span>
                           </Alert>

                      
        </div>
    
      {props.children}
    </div>
  );
}

export default App;
