import React, { useEffect } from "react";
import { Card, Image } from "react-bootstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
//image
import user1 from "../../../../assets/images/user/01.jpg";
import user2 from "../../../../assets/images/user/02.jpg";
import user3 from "../../../../assets/images/user/03.jpg";
import user4 from "../../../../assets/images/user/04.jpg";
import user5 from "../../../../assets/images/user/11.jpg";
import user6 from "../../../../assets/images/user/12.jpg";

import { getAllProfiles } from "../../../../actions/profile";
const RightSidebar = ({ getAllProfiles, profile: { profiles, loading } }) => {
  useEffect(() => {
    getAllProfiles();
  }, [getAllProfiles]);
  var auth = useSelector((state) => state.auth.user);
  const minirightsidebar = () => {
    document.getElementById("rightSidebar").classList.toggle("right-sidebar");
    document.body.classList.toggle("right-sidebar-close");
  };
  // console.log("profiles right side?", profiles);
  return (
    <>
      <div className="right-sidebar-mini" id="rightSidebar">
        <div className="right-sidebar-panel p-0">
          <Card className="shadow-none">
            <Card.Body className="p-0">
              <div className="media-height p-3" data-scrollbar="init">
                {profiles.length > 0 ?(
                  profiles.filter((profile) => profile?.user?._id !== auth?._id).map(
                    (profile, index) =>
                      profile.user && (
                        <div
                          className="d-flex align-items-center mb-4"
                          key={index}
                        >
                          <div className="iq-profile-avatar status-online">
                            <Image
                              className="rounded-circle avatar-50"
                              src={
                                profile?.profileImage
                                  ? `${
                                      process.env.REACT_APP_BASE_URL +
                                      "/" +
                                      profile?.profileImage
                                    }`
                                  : profile?.user?.avatar
                              }
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className="ms-3">
                            <h6 className="mb-0">{profile?.user?.name}</h6>
                            <p className="mb-0">Just Now</p>
                          </div>
                        </div>
                      )
                  )
                ) : (
                  <h4>No Online Users</h4>
                )}

                {/* <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user2} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Paul Molive</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user3} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Anna Mull</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user4} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Paige Turner</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user5} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Bob Frapples</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user2} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Barb Ackue</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user3} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Greta Life</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-away">
                                        <Image className="rounded-circle avatar-50" src={user6} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Ira Membrit</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-away">
                                        <Image className="rounded-circle avatar-50" src={user1} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Pete Sariya</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-4">
                                    <div className="iq-profile-avatar status-online">
                                        <Image className="rounded-circle avatar-50" src={user2} alt="" loading="lazy"/>
                                    </div>
                                    <div className="ms-3">
                                        <h6 className="mb-0">Monty Carlo</h6>
                                        <p className="mb-0">Admin</p>
                                    </div>
                                </div> */}
              </div>
              <div
                className="right-sidebar-toggle bg-primary text-white mt-3 d-flex"
                onClick={minirightsidebar}
              >
                <span className="material-symbols-outlined">chat</span>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

RightSidebar.propTypes = {
  getAllProfiles: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps, { getAllProfiles })(RightSidebar);
